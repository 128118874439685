import Head from 'next/head';
import {useEffect, useState} from 'react';
import {
  GenerateOpenGraphPageMetaData,
  GenerateStaticPageMetaData
} from '@components/seo/MetaDataDefaults';
import {
  VehicleSpecificCollectionPageComponent
} from '@components/vehicle-specific/v2/vehicle-specific-collection-page-component-main';
import {Layout} from '@components/layout/index';
import {fixPropsForJson} from '@helpers/common/json.mjs';
import {arrayUnique} from '@helpers/common/array.mjs';
import {
  getMinimalDataVehicleSpecificProductsForCollectionPage
} from '@helpers/collections/vehicle-specific/get-minimal-data-vehicle-specific-products-for-collection-page.mjs';
import {useRouter} from 'next/router';
import {useDeviceTypeWithLoading} from '@helpers/hooks/useWindow';
import {getServerSession} from 'next-auth/next';
import {authOptions} from '../api/auth/[...nextauth]';
import {normalizeReqSessionData} from '@components/ip-address/session-data.mjs';
import {
  generateCollectionPageItemListJsonLdData
} from '@helpers/products/json-ld/json-ld-collection-page.mjs';


export const getServerSideProps = async ({req, res}) => {
  const query = req?.query;
  // console.log({query})

  let session = await getServerSession(req, res, authOptions(req, res));
  session = normalizeReqSessionData(req, res, session);

  let products = await getMinimalDataVehicleSpecificProductsForCollectionPage();
  products = arrayUnique(products, (x) => x?.sku);

  // const testSku1 = products?.find(x => x?.sku.includes('VS-2X12GM-1999-2006-EXTCAB'));
  // console.log("[testSku1]:", testSku1);

  const vehicleYear = query?.vehicle_year || '';
  const vehicleMake = query?.vehicle_make || '';
  const vehicleModel = query?.vehicle_model || '';
  const enclosureType = query?.type || '';

  const output = {
    props: {
      disableMobileNav: true,
      showCountdownTimerMobile: false,
      session: session,
      products: fixPropsForJson(products),
      query: {
        year: vehicleYear,
        make: vehicleMake,
        model: vehicleModel,
        type: enclosureType
      }
    }
  };
  // console.log('\n >> Vehicle Specific Collection Page Total File Size: ', JSON.stringify(products).length / 1024, 'Kb');
  return output;
};

export default function VehicleSpecificCollectionPage({products, query, session}) {
  // console.log("[products]:", products)
  const {isLoading, isMobile, isDesktop} = useDeviceTypeWithLoading();
  const router = useRouter();

  const queryParams = router?.query;
  const enclosureType = queryParams?.type || query?.type;
  const vehicleYear = queryParams?.vehicle_year || query?.year;
  const vehicleMake = queryParams?.vehicle_make || query?.make;
  const vehicleModel = queryParams?.vehicle_model || query?.model;
  const [prevQueryParams, setPrevQueryParams] = useState(queryParams);

  // console.log("[query]:", queryParams);
  // console.log('[products.filter]:', products.filter(x => x?.sku.includes('VS-1X10FORD-2015-UP-SUPERCREWCAB-V')));

  const selections = {
    year: vehicleYear,
    make: vehicleMake,
    model: vehicleModel
  };

  const [checkedFilters, setCheckedFilters] = useState({
    filterTypes: [
      {
        name: 'Enclosure Type',
        options: [
          {
            name: 'Empty',
            label: 'Empty',
            value: enclosureType === 'Empty'
          },
          {
            name: 'Loaded',
            label: 'Loaded',
            value: enclosureType === 'Loaded'
          }
        ]
      },
      {
        name: 'Sizes',
        options: [
          {
            name: '8"',
            label: `8"`,
            value: false
          },
          {
            name: '10"',
            label: `10"`,
            value: false
          },
          {
            name: '12"',
            label: `12"`,
            value: false
          }
        ]
      }
    ]
  });

  const handleFilterChange = (e, typeIndex, optionIndex) => {
    const {checked} = e.target;

    setCheckedFilters((prevState) => {
      const newState = {
        ...prevState
      };
      newState.filterTypes[typeIndex].options[optionIndex].value = checked;

      // Determine the new URL query parameters based on the updated filters
      const newQueryParams = {
        ...router.query
      }; // Copy existing query params

      if (newState.filterTypes[typeIndex].name === 'Enclosure Type') {
        if (checked) {
          newQueryParams.type = newState?.filterTypes?.[typeIndex]?.options?.[optionIndex]?.name;
        } else {
          delete newQueryParams.type; // Remove the query param key if checkbox is unchecked
        }
      }

      if (newState?.filterTypes?.[typeIndex]?.name === 'Sizes') {
        const selectedSizes = newState?.filterTypes?.[typeIndex]?.options
          ?.filter((option) => option?.value)
          ?.map((option) => option.name);
        if (selectedSizes?.length > 0) {
          newQueryParams.sizes = selectedSizes?.join(',');
        } else {
          delete newQueryParams?.sizes;
        }
      }

      router.push(
        {
          pathname: router?.pathname,
          query: newQueryParams // Update URL with the new query parameters
        },
        undefined,
        {
          shallow: true
        }
      );

      return newState;
    });
  };

  useEffect(() => {
    if (JSON.stringify(prevQueryParams) !== JSON.stringify(queryParams)) {
      setPrevQueryParams(queryParams);
      // Update the checkedFilters state based on the URL query parameters
      setCheckedFilters((prevCheckedFilters) => {
        // console.log("Query parameters have changed:", queryParams);
        const updatedFilterTypes = prevCheckedFilters.filterTypes?.map((filterType) => {
          if (filterType.name === 'Enclosure Type') {
            return {
              ...filterType,
              options: filterType.options.map((option) => ({
                ...option,
                value: option.name === (queryParams?.type || query?.type || '')
              }))
            };
          }
          return filterType;
        });

        return {
          ...prevCheckedFilters,
          filterTypes: updatedFilterTypes
        };
      });
    }
  }, [queryParams, query]);

  return (
    <>
      <VehicleSpecificCollectionPageComponent
        isLoading={isLoading}
        session={session}
        isMobile={isMobile}
        isDesktop={isDesktop}
        products={products}
        selections={selections}
        checkedFilters={checkedFilters}
        setCheckedFilters={setCheckedFilters}
        handleFilterChange={handleFilterChange}
      />

      <VehicleSpecificLandingPageProductsJsonLd products={products} />
    </>
  );
}

export const VehicleSpecificLandingPageProductsJsonLd = ({products}) => {
  const itemListElementsJsonLd = generateCollectionPageItemListJsonLdData(products);

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: itemListElementsJsonLd
  };
  // console.dir({jsonLd}, {depth: null});
  // console.log('[VehicleSpecificLandingPageProductsJsonLd] jsonLd:', jsonLd);
  return <script type="application/ld+json"
                 dangerouslySetInnerHTML={{__html: JSON.stringify(jsonLd)}}
  />;
};

export const VehicleSpecificCollectionPageSEO = () => {
  const title = 'Vehicle Specific Subwoofer Enclosures | Skar Audio';
  const description =
    'Skar Audio\'s vehicle specific subwoofer enclosures are the go-to solution when looking to add aftermarket subwoofers in a truck or other vehicle that requires a more custom or space-preserving solution.';
  const canonicalUrl = `https://www.skaraudio.com/collections/vehicle-specific-subwoofer-enclosures`;
  const image = `https://www.skaraudio.com/image/collections/loaded-enclosures/vehicle-specific/L-VD-VS-2X10GM-2019-UP-CREWCAB-V-1_400px.webp`;

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet="UTF-8" />
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />

        <GenerateStaticPageMetaData />
        <GenerateOpenGraphPageMetaData
          title={title}
          url={canonicalUrl}
          description={description}
          image={image}
        />
      </Head>
    </>
  );
};

VehicleSpecificCollectionPage.Layout = Layout;
