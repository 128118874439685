import styles from './HorizontalSlider.module.css';
import React, {useEffect, useRef, useState} from 'react';
import cn from 'clsx';

export default function HorizontalSlider({
                                           children,
                                           mobileOnly,
                                           showArrows = true,
                                           gap = 2,
                                           desktopGap = 2,
                                           showDots = false,
                                           showDotsMobileOnly = false,
                                           showScrollbar = true
                                         }) {
  const [reachedBeginning, setReachedBeginning] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [isPaginationNeeded, setIsPaginationNeeded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = React.Children.count(children);
  const sliderRef = useRef(null);

  const handleScroll = () => {
    const slider = sliderRef.current;
    if (!slider || slider.children.length === 0) return;

    const firstSlideWidth = slider.children[0].offsetWidth;
    const newCurrentSlide = Math.round(slider.scrollLeft / firstSlideWidth);

    const isBeginning = slider.scrollLeft <= 0;
    const isEnd = slider.scrollWidth <= slider.clientWidth + 10 + slider.scrollLeft;

    setCurrentSlide(newCurrentSlide);
    setReachedBeginning(isBeginning);
    setReachedEnd(isEnd);
  };

  // Add this inside your useEffect hook
  useEffect(() => {
    sliderRef.current.addEventListener('scroll', handleScroll);
    const checkIfAllChildrenVisible = () => {
      const slider = sliderRef?.current;
      if (!slider) return;
      const totalWidthOfChildren = Array.from(slider?.children).reduce((total, child) => {
        const style = window.getComputedStyle(child);
        const margin = parseFloat(style.marginLeft) + parseFloat(style?.marginRight);
        return total + child.offsetWidth + margin;
      }, 0);
      const isAllVisible = totalWidthOfChildren <= slider.clientWidth;
      setIsPaginationNeeded(!isAllVisible);
    };
    checkIfAllChildrenVisible();
    return () => {
      sliderRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [sliderRef, totalSlides]);

  const handleLeft = () => {
    scrollToSlide(Math.max(0, currentSlide - 1), false);
  };

  const handleRight = () => {
    scrollToSlide(Math.min(totalSlides - 1, currentSlide + 1), false);
  };

  const scrollToSlide = (slideIndex, setFocus = true) => {
    if (!sliderRef.current || sliderRef.current.children.length === 0) return;

    const firstSlideWidth = sliderRef.current.children[0].offsetWidth;
    const secondSlideLeft = sliderRef.current.children[1].offsetLeft;
    const slideGap = secondSlideLeft - firstSlideWidth;

    const newScrollPosition = (firstSlideWidth + slideGap) * slideIndex;

    sliderRef.current.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth'
    });

    if (setFocus) {
      const newActiveDot = document.getElementById(`dot-${slideIndex}`);
      newActiveDot?.focus();
    }
  };

  const handleDotKeydown = (e) => {
    if (e.key === 'ArrowRight') {
      scrollToSlide(Math.min(totalSlides - 1, currentSlide + 1));
    } else if (e.key === 'ArrowLeft') {
      scrollToSlide(Math.max(0, currentSlide - 1));
    }
  };

  return (
    <div className="relative">
      <div className="w-full overflow-y-hidden pb-2 rounded">
        <div
          ref={sliderRef}
          tabIndex="0"
          className={cn(
            `w-full relative flex gap-${gap} lg:gap-${desktopGap} overflow-x-auto translate-z-[0px] snap-x snap-mandatory`,
            {
              'flex-nowrap': mobileOnly,
              'no-scrollbar': !showScrollbar
            }
          )}>
          {children}
        </div>

        {showArrows && isPaginationNeeded ? (
          <div
            className={cn({
              [styles['has-no-scrollbar']]: !showScrollbar,
              'md:hidden': mobileOnly
            })}>
            <Arrow onClick={handleLeft} left disabled={reachedBeginning} ariaLabel="Scroll left" />
            <Arrow onClick={handleRight} disabled={reachedEnd} ariaLabel="Scroll right" />
          </div>
        ) : null}

        {showDots && isPaginationNeeded ? (
          <div
            className={cn(styles['dots-container'], {
              [styles['has-no-scrollbar']]: !showScrollbar,
              [styles['hide-dots-mobile']]: showDotsMobileOnly
            })}
            role="tablist">
            {Array.from({length: totalSlides}).map((_, index) => {
              const isCurrentSlide = index === currentSlide;
              return (
                <span
                  key={index}
                  id={`dot-${index}`}
                  role="tab"
                  tabIndex={isCurrentSlide ? '0' : '-1'}
                  aria-selected={isCurrentSlide ? 'true' : 'false'}
                  className={cn({
                    [styles.dot]: true,
                    [styles['dot-active']]: isCurrentSlide
                  })}
                  onClick={() => scrollToSlide(index)}
                  onKeyDown={(e) => isCurrentSlide && handleDotKeydown(e)}
                />
              );
            })}
          </div>
        ) : null}
      </div>

      <div className="visually-hidden" aria-live="polite" aria-atomic="true">
        {`Image ${currentSlide + 1} of ${totalSlides}`}
      </div>
    </div>
  );
}

const Arrow = ({left, onClick, disabled, ariaLabel}) => {
  const classes = cn({
    [styles.arrow]: true,
    [styles['arrow-left']]: left,
    [styles['arrow-right']]: !left,
    [styles['arrow-disabled']]: disabled
  });

  return (
    <div className={classes}>
      <svg
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        aria-label={ariaLabel}
        role="button"
        tabIndex={disabled ? -1 : 0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onClick();
          }
        }}>
        {left ? (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        ) : (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    </div>
  );
};
