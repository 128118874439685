export class ErrorWithDetails extends Error {
	constructor(msg, details) {
    // console.log('Details in ErrorWithDetails => ', details)
		super(msg)
    // console.error('ERROR MSG => ', msg)
		this.details = details
	}
}

export class ErrorWithCode extends Error {
	constructor(msg, code) {
    console.warn('new ERROR MSG => ', msg)
		console.log('ErrorWithCode => ', code)
		super(msg)
		this.code = code
	}
}

export class ErrorWithStatusCode extends Error {
  constructor(message, status) {
    super(message);
    this.status = status;
  }
}

export class ErrorWithStatus extends Error {
  constructor(msg, code) {
    super(msg);
    this.statusCode = code;
  }
}

export class NotFoundError extends Error {}
