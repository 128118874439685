import Link from 'next/link';
import Image from 'next/image';
import Star from '@image/icons/svg-icons/GoldStar.svg';

export const ReviewSection = ({item, showReviewButton, reviewRating, reviewTitle, reviewBody}) => {
  return (
    <>
      {!item?.hasBeenReviewed && showReviewButton && <WriteReviewButton sku={item?.sku} />}
      {item?.hasBeenReviewed && (
        <ReviewDetails
          reviewRating={reviewRating}
          reviewTitle={reviewTitle}
          reviewBody={reviewBody}
        />
      )}
    </>
  );
};

export const WriteReviewButton = ({sku}) => (
  <div className="flex mt-2">
    <Link
      href={`/reviews/sku-${sku}`}
      className="border rounded-md shadow font-medium uppercase px-2 py-1 text-xs
       bg-white hover:bg-neutral-50 transition-all delay-100 font-oswald tracking-wide">
      <div className="flex items-center gap-1 justify-center">
        <div className="w-3">
          <Image src={Star} className="object-contain" width={125} height={125} alt="Rating Star" />
        </div>
        Write a review
      </div>
    </Link>
  </div>
);

const ReviewRating = ({reviewRating}) => (
  <div className="flex items-center mt-0 gap-2">
    <div className="flex">
      {Array(Number(reviewRating))
        .fill(0)
        .map((_, i) => (
          <div className="w-3" key={i}>
            <Image
              src={Star}
              className="object-contain"
              width={125}
              height={125}
              alt="Rating Star"
            />
          </div>
        ))}
    </div>
  </div>
);

const ReviewDetails = ({reviewTitle, reviewBody, reviewRating}) => {
  return (
    <div className="border border-neutral-300 bg-neutral-100 rounded-md py-2 text-black mt-4">
      <div className="uppercase border-b border-b-neutral-300 px-2 pb-1 mb-1">Your review</div>
      <div className="flex flex-wrap gap-2 items-start px-2">
        {reviewRating && (
          <div className="mt-1">
            <ReviewRating reviewRating={reviewRating} />
          </div>
        )}
        <div className="font-bold text-ellipsis line-clamp-2">{reviewTitle}</div>
      </div>
      <div className="text-ellipsis line-clamp-3 md:line-clamp-2 px-2">{reviewBody}</div>
    </div>
  );
};
