import styles from './review-photos.module.css';
import {Dialog} from '@headlessui/react';
import ReviewIndividualV2 from '@components/product/reviews/review-photos/review-individual-v2';
import {useEffect} from 'react';
import {useRouter} from 'next/router';

export const ReviewPopupIndividualV2 = ({currentReview, show, close, initialImageIndex = 0}) => {
  const router = useRouter();

  useEffect(() => {
    const isDialogHashPresent = () => window.location.hash === '#reviewPopup';

    const closeDialog = () => {
      if (isDialogHashPresent()) {
        // console.log('dialog onClosed v2');
        const newPath = router.asPath.split('#')[0];
        router.push(newPath, undefined, {shallow: true});
      }
      close();
    };

    if (show && !isDialogHashPresent()) {
      // console.log('dialog onOpened v2');
      window.history.pushState(null, null, '#reviewPopup');
    }

    const handlePopState = () => {
      if (!isDialogHashPresent()) {
        closeDialog();
      }
    };

    // Listen for popstate events (back button or history navigation)
    window.addEventListener('popstate', handlePopState);

    // Cleanup function to revert URL and remove event listener
    return () => {
      window.removeEventListener('popstate', handlePopState);
      // Ensure the dialog is closed and the URL is clean when the component unmounts or the dialog is closed
      closeDialog();
    };
  }, [show]);

  const shouldShowModal = show && currentReview ? true : false;
  // console.log({show, currentReview, shouldShowModal});

  return (
    <Dialog
      open={shouldShowModal}
      onClose={() => {
      }}>
      <div className="fixed flex h-full items-center justify-center z-10 inset-0 text-black">
        <div
          className={`fixed inset-0 fade-in-white-to-black-mobile md:bg-black md:bg-opacity-25`}
          onClick={() => close()}
        />
        <div
          className={`${styles.popup} ${styles['popup-delay-mobile']} ${styles['popup-individual']} fade-in relative z-50`}>
          <Dialog.Panel
            className={`flex flex-col w-full h-full md:py-6 md:rounded-md md:bg-white align-middle
              shadow-xl overflow-y-auto lg:overflow-hidden`}>
            <ReviewIndividualV2
              review={currentReview}
              close={close}
              currentReviewImageIndex={initialImageIndex}
              isIndividual={true}
            />
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};
