import cn from 'clsx';

export const InStockIndicatorNoUseHook = ({isInStock, className, style}) => {
  if (isInStock === null || isInStock === false) {
    return (
      <div
        className=""
        style={{
          color: 'rgb(250,28,5)',
          fontSize: '18px',
          fontFamily: 'cursive',
          ...style
        }}>
        Sold Out
      </div>
    );
  }

  return (
    <div
      className={cn(className)}
      style={{
        color: 'rgb(0, 100, 0)',
        ...style
      }}>
      In Stock
    </div>
  );
};

export const InStockIndicator = ({product, isDiscontinued, className, style}) => {
  // console.log('[InStockIndicator][product]: ', product);
  try {
    const isInStock = product?.currentlyInStock || product?.isInStock;
    const totalQtyWithoutFba = product?.inventory?.total_qty_without_fba ?? product?.total_qty_without_fba;
    // console.log('[InStockIndicator] totalQtyWithoutFba:', totalQtyWithoutFba);

    const tpaAndLvQtyCombinedRealOhTotal = (product?.tpa_wh ?? 0) + (product?.lv_wh ?? 0);
    const overRideStockWithRealQty = isDiscontinued && tpaAndLvQtyCombinedRealOhTotal > 0;

    const preorderInfo = product?.preorderInfo; /*useProductStock?.pre_order_info;*/
    const isPreOrderEnabled = preorderInfo?.is_pre_order_enabled;
    const isPreOrderEligible = preorderInfo?.is_pre_order_eligible;
    const isPreorder = !isInStock && isPreOrderEnabled && isPreOrderEligible;

    if (isInStock && totalQtyWithoutFba > 0 || overRideStockWithRealQty) {
      return (
        <div
          className={cn('p-0 pt-1 w-48 font-bold -mb-1', className)}
          style={{
            color: 'rgb(0, 100, 0)',
            ...style
          }}>
          In Stock
        </div>
      );
    }

    if (isDiscontinued && (isInStock === false || totalQtyWithoutFba <= 0)) {
      return (
        <div
          style={{
            color: 'rgb(250,28,5)',
            fontSize: '18px',
            fontFamily: 'sans-serif',
            backgroundColor: 'rgba(255, 255, 204)',
            textAlign: 'center',
            border: '2px solid rgb(250,28,5)',
            borderRadius: '5px',
            padding: '10px',
            margin: '10px auto',
            ...style
          }}>
          Discontinued
        </div>
      );
    }

    if (isPreorder) {
      return (
        <div
          style={{
            color: 'rgb(3 105 161)',
            fontSize: '18px',
            fontFamily: 'sans-serif',
            whiteSpace: 'pre',
            ...style
          }}>
          Available for Pre-order
        </div>
      );
    }

    if (!isInStock || totalQtyWithoutFba <= 0) {
      return <div className="p-0 text-lg text-rose-600">Sold Out</div>;
    }

    if (isInStock && totalQtyWithoutFba > 0 && totalQtyWithoutFba < 20) {
      return <div className="p-0">In Stock (Only {totalQtyWithoutFba} Remaining)</div>;
    }

    return null;
  } catch (e) {
    console.error('[Error][InStockIndicator]', e?.message);
    console.error('[Error][InStockIndicator]', e?.stack);
  }
};
