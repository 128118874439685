import Link from 'next/link';
import {memo} from 'react';
import {useRouter} from 'next/router';
import {ChevronDown, ChevronRight} from '@components/widgets/Icons';

export const RenderDropDownMenus = memo(({categories}) => {
  const router = useRouter();
  const numberOfCategories = categories.length;

  const categories_without_mobile_only = categories.filter((category) => !category.mobileOnly);

  return (
    <>
      {router?.pathname &&
        categories_without_mobile_only.map((category, index) => {
          // console.log({category})
          return (
            <RenderCssMainMenu
              key={`desktop-${category.id}`}
              index={index}
              category={category}
              url={router}
              numberOfCategories={numberOfCategories}
            />
          );
        })}
    </>
  );
});

export const RenderCssMainMenu = memo(({category, url}) => {
  return (
    <li id={`dp-${category?.id}`} className='desktop-menu-item' role='menuitem'>
      <Link
        href={`/collections/${category?.handle}`}
        prefetch={false}
        id={`desktop-${category?.id}`}
        className='desktop-menu-item-link'
        aria-controls={`desktop-${category?.id}`}>
        {category?.category}
        <ChevronDown aria-hidden='true' />
      </Link>
      <RenderCssMenuItems category={category} url={url} />
    </li>
  );
});

export const RenderCssMenuItems = memo(({category, url}) => {
  const menuItemsLevel2 = (category?.collections || []).concat(category?.products || []);
  // console.log({category});

  const categoryId = category.id;

  if (categoryId === '100600101') {
    const dampeningMenuItem = {
      id: '100800001',
      slug: '/products/SK2MM-car-sound-deadening',
      title: 'Sound Deadening'
    };
    menuItemsLevel2.push(dampeningMenuItem);
  }

  return (
    <ul id={`dp-${categoryId}-sub`} className='desktop-submenu' role='menu'>
      {menuItemsLevel2.map((collection, index) => {
        const collectionId = collection.id;
        const collectionTitle = collection.title;

        const currentPage = collection?.slug === url?.pathname;
        const menuItemsLevel3 =
          collection?.subMenu || collection['sub-collections'] || collection['products'];

        if (collection.isHidden) {
          return null;
        }

        return (
          <li key={`${categoryId}-${index}`} className='main-menu-item' role='menuitem'>
            {menuItemsLevel3 && (
              <RenderCssDropRightMenus collectionId={collectionId} products={menuItemsLevel3}>
                <Link href={collection?.slug} className={'desktop-submenu-link'} prefetch={false}>
                  {collectionTitle}
                  <ChevronRight aria-hidden='true' />
                </Link>
              </RenderCssDropRightMenus>
            )}
            {!menuItemsLevel3 && (
              <Link
                href={collection.slug}
                className={'desktop-submenu-link'}
                title={`${collectionTitle} collection of products`}>
                {collectionTitle}
              </Link>
            )}
          </li>
        );
      })}
    </ul>
  );
});

export const RenderCssDropRightMenus = memo(({collectionId, products, children}) => {
  // console.log("[RenderCssDropRightMenus]:", collectionId);
  return (
    <>
      {children}
      <ul
        className={'desktop-sub-submenu'}
        style={{marginLeft: '224px', marginTop: '-34px'}}
        id={`ds-${collectionId}`}
        role='menu'>
        {products &&
          products?.length > 0 &&
          products?.map((product, index) => {
            const prodId = product?.id;
            const sku = product?.sku;

            return (
              <li key={`${prodId || sku}-${index}`} role='menuitem'>
                <Link
                  prefetch={false}
                  href={product.slug}
                  className='desktop-submenu-link'
                  aria-label={sku}>
                  {sku}
                </Link>
              </li>
            );
          })}
      </ul>
    </>
  );
});
