export const SpinnerV3 = ({size = '60px'}) => {
  const spinnerStyle = {
    position: 'relative',
    width: size,
    height: size,
    animation: 'rotate 0.60s infinite linear'
  };

  const circleStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: '3px solid rgba(0, 0, 0, 0.1)',
    borderTopColor: 'black',
    borderRadius: '50%'
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '20px',
      paddingBottom: '20px'
    }}>
      <style>
        {`
          @keyframes rotate {
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
      <div style={spinnerStyle}>
        <div style={circleStyle}></div>
      </div>
    </div>
  );
};
