import {useEffect, useState} from 'react';

export const splitTitleFe = (title, keyword) => {
  if (!title) {
    return;
  }
  const index = title.indexOf(keyword);
  if (index !== -1) {
    const firstHalf = title.slice(0, index).trim();
    return firstHalf;
  }
  return title;
}

export const AutoResizeText = ({children}) => {
  const [isSmall, setIsSmall] = useState(false);

  const handleResize = () => {
    const screenWidth = window.innerWidth;
    console.log(`Current screen width: ${screenWidth}px`);

    if (screenWidth < 380) {
      console.log('Screen width less than 380px. Applying small text class.');
      setIsSmall(true);
    } else {
      console.log('Screen width greater than or equal to 380px. Removing small text class.');
      setIsSmall(false);
    }
  };

  useEffect(() => {
    handleResize(); // Call once to set initial size
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const className = isSmall ? 'mobile-shrink-text-size-to-80-percent' : '';

  return <div className={className}>{children}</div>;
};

export const filterForOnlyProductsEnabledOnSiteTrueFe = (products) => {
  return products?.filter(product => product?.enabled_on_site === 1);
};


