export const MdChevronLeft = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        viewBox='0 0 24 24'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path fill='none' d='M0 0h24v24H0z'></path>
        <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'></path>
      </svg>
    </div>
  );
};

export const MdPhotoCamera = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        viewBox='0 0 24 24'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path fill='none' d='M0 0h24v24H0z'></path>
        <circle cx='12' cy='12' r='3.2'></circle>
        <path d='M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z'></path>
      </svg>
    </div>
  );
};

export const MdPictureAsPdf = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        viewBox='0 0 24 24'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path fill='none' d='M0 0h24v24H0z'></path>
        <path d='M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z'></path>
      </svg>
    </div>
  );
};

export const MdCloudUpload = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        viewBox='0 0 24 24'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path fill='none' d='M0 0h24v24H0z'></path>
        <path d='M19.35 10.04A7.49 7.49 0 0 0 12 4C9.11 4 6.6 5.64 5.35 8.04A5.994 5.994 0 0 0 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z'></path>
      </svg>
    </div>
  );
};

export const MdOutlineEmail = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        viewBox='0 0 24 24'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path fill='none' d='M0 0h24v24H0V0z'></path>
        <path d='M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z'></path>
      </svg>
    </div>
  );
};

export const MdPhone = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        viewBox='0 0 24 24'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path fill='none' d='M0 0h24v24H0z'></path>
        <path d='M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z'></path>
      </svg>
    </div>
  );
};

export const MdOutlineRotateLeft = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        stroke-width='0'
        viewBox='0 0 24 24'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path fill='none' d='M0 0h24v24H0V0z'></path>
        <path d='M7.34 6.41.86 12.9l6.49 6.48 6.49-6.48-6.5-6.49zM3.69 12.9l3.66-3.66L11 12.9l-3.66 3.66-3.65-3.66zm15.67-6.26A8.95 8.95 0 0 0 13 4V.76L8.76 5 13 9.24V6c1.79 0 3.58.68 4.95 2.05a7.007 7.007 0 0 1 0 9.9 6.973 6.973 0 0 1-7.79 1.44l-1.49 1.49C10.02 21.62 11.51 22 13 22c2.3 0 4.61-.88 6.36-2.64a8.98 8.98 0 0 0 0-12.72z'></path>
      </svg>
    </div>
  );
};

export const MdOutlineRotateRight = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        stroke-width='0'
        viewBox='0 0 24 24'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path fill='none' d='M0 0h24v24H0z'></path>
        <path d='M2 13a9 9 0 0 0 13.79 7.62l-1.46-1.46c-.99.53-2.13.84-3.33.84-3.86 0-7-3.14-7-7s3.14-7 7-7h.17L9.59 7.59 11 9l4-4-4-4-1.42 1.41L11.17 4H11a9 9 0 0 0-9 9zm9 0 6 6 6-6-6-6-6 6zm6 3.17L13.83 13 17 9.83 20.17 13 17 16.17z'></path>
      </svg>
    </div>
  );
};

export const MdChat = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth="0"
        viewBox="0 0 24 24"
        height={size}
        width={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path
          d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"></path>
      </svg>
    </div>
  );
};
