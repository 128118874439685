import {consoleLogGoogleAds} from '@components/datalayer/loggers';
import {
  cleanAndReformatToE164WIthPlusCharacterAdded
} from '@helpers/common/format/format-phone.mjs';

export const ADWORDS_event_purchaseComplete = async (orderInfo) => {
  const order = orderInfo?.order;
  if (!order) {
    console.error('Order information is missing.');
    return;
  }

  if (!order?.final_total || !order?.order_number) {
    console.error('Order total or order number is missing.');
    return;
  }

  const childLineItems = orderInfo?.lineItems?.flatMap(x => x?.child_items);
  const hasChildItems = orderInfo?.lineItems?.every(x => x?.child_items?.length > 0);

  let lineItems = hasChildItems ? childLineItems : orderInfo?.lineItems;

  const adsPayload = {
    allow_enhanced_conversions: true,
    send_to: 'AW-976071580/B5efCMbOyMEDEJzXttED',
    value: order?.final_total,
    currency: 'USD',
    transaction_id: order?.order_number,
    items:
      lineItems?.map(function (item) {
        item = {
          id: item?.sku,
          name: item?.sku,
          category: 'Skar Audio',
          quantity: item?.quantity
        };
        return item;
      }) ?? []
  };
  // console.log(" > [Google Ads][adsPayload]:", {adsPayload});

  const billingAddress = orderInfo?.billingAddress;
  const shippingAddress = orderInfo?.shippingAddress;

  let phoneNumber = shippingAddress?.phone_number ?? orderInfo?.customer?.primary_phone;
  phoneNumber = cleanAndReformatToE164WIthPlusCharacterAdded(phoneNumber);

  const adwordsUserData = {
    email: order.email,
    phone_number: phoneNumber,
    address: {
      first_name: orderInfo?.customer?.first_name ?? billingAddress?.first_name,
      last_name: orderInfo?.customer?.last_name ?? billingAddress?.last_name,
      street: billingAddress?.address_line_1,
      city: billingAddress?.city,
      region: billingAddress?.state_code,
      postal_code: billingAddress?.zip_code,
      country: billingAddress?.country_code
    }
  };

  if (window.gtag && typeof window !== 'undefined') {
    window.gtag('set', 'user_data', adwordsUserData);
    window.gtag('event', 'conversion', adsPayload);
    consoleLogGoogleAds('  > Converted (purchase) ✅');
  }
};

export const AddToCart_ADWORDS = (data) => {
  const prodPrice = data?.item?.price ?? data.totalPrice;
  const adwordsAddToCartData = {
    allow_enhanced_conversions: true,
    send_to: 'AW-976071580/TxBYCO7Gl4UDEJzXttED',
    value: prodPrice,
    currency: 'USD'
  };
  // console.log('[ADWORDS] adwordsAddToCartData', adwordsAddToCartData);

  if (typeof window !== 'undefined') {
    window.gtag('event', 'conversion', adwordsAddToCartData);
    consoleLogGoogleAds('add_to_cart ✅');
  }
};
