import ComingSoonIcon from '@image/icons/png-icons/ComingSoonIcon.png';
import Image from 'next/image';
import cn from 'clsx';
import {DesktopPreOrderProductImageOverlay} from '@components/product/global/ProductImageOverlays';
import {HStack, VStack} from '@components/widgets/Common';
import {formatPriceNumeric} from '@components/product/global/formatPriceNumeric';
import {niceMoney} from '@helpers/common/number.mjs';
import {useState} from 'react';
import {OutOfStockRouter} from '@components/preorders/out-of-stock-router';
import {ProductQuantitySelector} from '@components/product/desktop/QuantitySelector';
import {AddToCartButton} from '@components/product/global/AddToCart';
import {CART_BUTTON_PRIMARY_CLASSES} from '@components/cart/CartButtonClasses';

export const DealerProductCard = ({product, cartItems}) => {
  // console.log('[DealerProductCard] product', product);
  const mainImageSrcUrl = product?.imageUrl ?? product?.images?.[0]?.url ?? ComingSoonIcon;

  const highPrice = product?.price_map;
  const lowPrice = product?.price_dealer ?? 0;

  const isInStock = product?.isInStock === true || product?.currentlyInStock === true;
  const isPreorderEligible = product?.preorderInfo?.is_pre_order_eligible === true;

  const preOrderInfo = product?.pre_order_info ?? product?.preorderInfo;

  product.selectedVariant = product?.variants?.[0];

  return (
    <VStack className='justify-between items-stretch'>
      <div>
        <div className='border border-gray-300 rounded-lg'>
          {mainImageSrcUrl && (
            <div className='relative'>
              <a
                href={`products/${product?.site_handle}`}
                target='_blank'
                rel='noopener noreferrer'>
                <Image
                  alt={product?.name || 'Product Image'}
                  className='rounded-2xl'
                  src={mainImageSrcUrl}
                  height={301}
                  width={301}
                  quality='85'
                  loading='eager'
                />
              </a>

              <DesktopPreOrderProductImageOverlay
                isInStock={isInStock}
                preOrderInfo={preOrderInfo}
                isSourceCollectionPage={true}
              />

              <InTheCartOverlay product={product} cartItems={cartItems} />
            </div>
          )}
        </div>

        <DealerProductTag
          product={product}
          price={lowPrice}
          compareAtPrice={highPrice}
          isInStock={isInStock}
          isPreorder={!isInStock && isPreorderEligible}
        />
      </div>

      <div style={{zoom: 0.7}}>
        <DealerAddToCartDesktop
          product={{
            sku: product?.sku,
            id: product?.id,
            price: product?.price_dealer,
            price_map: product?.price_map,
            price_msrp: product?.price_msrp,
            price_dealer: product?.price_dealer,
            currentlyInStock: product?.currentlyInStock
          }}
          className='flex-row gap-1 items-stretch'
          buttonText='🛒 Add to Cart'
        />
      </div>
    </VStack>
  );
};

export const DealerProductTag = ({
  product,
  price,
  compareAtPrice,
  isInStock,
  className = '',
  isPreorder
}) => {
  price = formatPriceNumeric(price);
  compareAtPrice = formatPriceNumeric(compareAtPrice);

  return (
    <div className={cn(className)}>
      <HStack className='items-start mt-2'>
        <div>
          <div className='pl-0 text-gray-700'>
            <strong className='font-bold'>SKU: </strong>
            <span
              style={{
                fontSize:
                  product?.sku?.length <= 10 ? '12pt' : 12 - product?.sku?.length / 10 + 'pt'
              }}>
              {product?.sku}
            </span>
          </div>
        </div>

        <div className='min-w-[6em] text-right'>
          <InStockText inStockTogether={isInStock} />
        </div>
      </HStack>

      <HStack className='items-start mt-1'>
        <div>
          <div>
            <span
              style={{
                paddingTop: '3px',
                marginLeft: '1px',
                fontWeight: 'bolder',
                fontSize: '14px'
              }}>
              TPA: &nbsp;
            </span>
            <span
              style={{
                paddingTop: '3px',
                fontSize: '14px'
              }}>
              {Math.max(0, product?.tpa_wh)}
            </span>
          </div>

          <div>
            <span
              style={{
                marginLeft: '1px',
                fontWeight: 'bolder',
                paddingTop: '3px',
                fontSize: '14px'
              }}>
              LV: &nbsp;
            </span>
            <span
              style={{
                paddingTop: '3px',
                fontSize: '14px'
              }}>
              {Math.max(0, product?.lv_wh)}
            </span>
          </div>
        </div>

        <div className='text-right min-w-[8em]'>
          <PricingInList compareAtPrice={compareAtPrice} price={price} isPreorder={isPreorder} />
        </div>
      </HStack>
    </div>
  );
};

export const DealerAddToCartDesktop = ({product, className, buttonText = 'Add to Cart'}) => {
  const [quantity, setQuantity] = useState(1);

  const currentlyInStock = product?.currentlyInStock;
  if (!currentlyInStock) {
    return <OutOfStockRouter product={product} withQuantity={true} className={className} />;
  }

  return (
    <>
      <div className='mt-3 space-y-2 mb-3'>
        <div className={cn('flex', className)}>
          <ProductQuantitySelector quantity={quantity} setQuantity={setQuantity} />

          <AddToCartButton
            selectedVariant={product}
            className={CART_BUTTON_PRIMARY_CLASSES}
            quantity={quantity}
            addToCartButtonSource={'dealer-product-page-button'}>
            {buttonText}
          </AddToCartButton>
        </div>
      </div>
    </>
  );
};

export const InStockText = ({inStockTogether, className = 'font-bold', isPreorder, style = {}}) => {
  if (isPreorder) {
    return (
      <div className={className}>
        <span className='text-sky-700 text-xs font-medium'>Available for Pre-order</span>
      </div>
    );
  }

  return (
    <div className={className}>
      {inStockTogether > 0 && (
        <span
          style={{
            color: 'rgb(0, 100, 0)',
            lineHeight: '1.25',
            ...style
          }}>
          In Stock
        </span>
      )}
      {inStockTogether <= 0 && (
        <span className='text-skar-red-dark text-xs md:text-sm'>Out of Stock</span>
      )}
    </div>
  );
};

export const PricingInList = ({compareAtPrice, price, isPreorder}) => {
  return (
    <VStack className='items-end mt-0'>
      <div className={cn('text-accent-9 tracking-wide text-right text-sm')}>
        MAP: {compareAtPrice}
      </div>
      <div
        className={cn('text-accent-9 font-semibold text-right text-xl font-oswald-lightest', {
          'text-sky-800': !isPreorder,
          'text-black': isPreorder
        })}>
        Dealer: {niceMoney(price)}
      </div>
    </VStack>
  );
};

export const InTheCartOverlay = ({product, cartItems}) => {
  let inTheCart = cartItems?.find((x) => x?.sku === product?.sku);
  const inCartQty = inTheCart?.quantity ?? 0;
  if (inTheCart) {
    return <div className='absolute inset-0 pointer-events-none p-3'>✔ 🛒 x {inCartQty}</div>;
  }
  return null;
};
