import cn from 'clsx';

export const TextInput = ({
  id,
  className,
  placeholder,
  prefix,
  register,
  name,
  validations,
  autoComplete,
  disabled,
  type = 'text',
  ...inputprops
}) => {
  const style =
    type === 'number'
      ? {
          MozAppearance: 'textfield',
          WebkitAppearance: 'none'
        }
      : {};

  return (
    <WrapField prefix={prefix}>
      <input
        type={type}
        placeholder={placeholder}
        id={id}
        className={cn(
          'peer px-2 py-2 md:px-4 md:py-2 w-full h-[40px] lg:h-[50px] max-w-[500px]',
          'border border-neutral-600 bg-white dark:bg-white',
          'focus:outline-0 focus:outline-transparent',
          'focus:border-neutral-800 focus:border-2',
          'text-[16px] md:text-base',
          'disabled:bg-neutral-200 disabled:text-neutral-800 disabled:border-neutral-400',
          {'pl-8': prefix},
          className
        )}
        style={style}
        {...inputprops}
        {...register(name, validations)}
        disabled={disabled}
      />
    </WrapField>
  );
};

export const WrapField = ({children, prefix}) => {
  return (
    <div className='flex relative'>
      {prefix && <div className='text-neutral-400 absolute top-[10px] left-[16px]'>{prefix}</div>}
      {children}
    </div>
  );
};
