import {BiChevronLeft, BiChevronRight} from '@assets/react-icons/bi';
import {Fragment} from 'react';

export const PaginationV2 = ({count, page, loadPage, isMobile}) => {
  const numPages = Math.ceil(count / 20);

  const hasPrevious = page > 0;
  const hasNext = page < numPages - 1;

  if (numPages <= 1) {
    return null;
  }

  const onPrevious = () => {
    if (hasPrevious) {
      loadPage(page - 1);
    }
  };

  const onNext = () => {
    if (hasNext) {
      loadPage(page + 1);
    }
  };

  const onClickPageNumber = (pageNumber) => {
    loadPage(pageNumber);
  };

  const pageNumbersShown = isMobile ? 1 : 3;
  const pageSequence = getPageSequence(page, numPages, pageNumbersShown);
  // console.log({page, numPages, pageNumbersShown, pageSequence});

  return (
    <>
      <div className='flex justify-center'>
        {hasPrevious && (
          <div className='mt-2 ml-2 whitespace-pre'>
            <PaginationV2Button
              id='prevURL'
              type='button'
              onClick={onPrevious}
              aria-label='Previous Page'>
              <div className={'flex items-center'}>
                <BiChevronLeft size={16} />
                <div className={'hidden md:block'}>Previous</div>
              </div>
            </PaginationV2Button>
          </div>
        )}

        {pageSequence.map((pageNumber, index) => {
          return (
            <Fragment key={`ellipsis-${index}`}>
              {pageNumber === '...' && (
                <div className='mt-2 ml-2' style={{cursor: 'default'}}>
                  <button className='px-0' disabled>
                    ...
                  </button>
                </div>
              )}

              {pageNumber !== '...' && (
                <div key={`${pageNumber}-${index}`} className='mt-2 ml-2 whitespace-pre'>
                  <PaginationV2Button
                    isActive={page === pageNumber - 1}
                    id={`page${pageNumber}`}
                    onClick={() => {
                      onClickPageNumber(pageNumber - 1); // Page numbers are 1-based, array is 0-based
                    }}>
                    {pageNumber}
                  </PaginationV2Button>
                </div>
              )}
            </Fragment>
          );
        })}

        {hasNext && (
          <div className='mt-2 ml-2 whitespace-pre'>
            <PaginationV2Button id='nextURL' type='button' onClick={onNext} aria-label='Next Page'>
              <div className={'flex items-center'}>
                <div className={'hidden md:block'}>Next</div>
                <BiChevronRight size={16} />
              </div>
            </PaginationV2Button>
          </div>
        )}
      </div>
    </>
  );
};

const PaginationV2Button = ({isActive = false, children, ...props}) => {
  return (
    <button
      className={`inline-flex relative border border-neutral-300 text-center justify-center items-center min-h-[2.25rem] min-w-[2.25rem] px-2 md:px-4 rounded text-xs md:text-sm ${
        !isActive ? 'bg-white text-black shadow-none' : 'bg-[#007a5c] text-white shadow-sm'
      }`}
      {...props}>
      {children}
    </button>
  );
};

const getPageSequence = (currentPage, totalPages, maxPageNumbersShown) => {
  currentPage += 1;

  if ((currentPage === 0 || currentPage === 1) && totalPages > 3) {
    // Force the first three pages to show on page 1
    return [1, 2, 3, '...', totalPages];
  }

  const pages = [];

  if (totalPages <= maxPageNumbersShown) {
    // If total pages is less than or equal to max pages to show, display all pages
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages;
  }

  let startIndex = currentPage - 1;
  let endIndex = currentPage + 1;

  // Ensure the start index is at least 1
  startIndex = Math.max(startIndex, 1);
  // Ensure the end index does not exceed total pages
  endIndex = Math.min(endIndex, totalPages);

  // Adjust startIndex backwards if we are close to the end
  if (currentPage > 1 && totalPages - currentPage < maxPageNumbersShown) {
    startIndex = Math.max(currentPage - (maxPageNumbersShown - (totalPages - currentPage)), 1);
  }

  // Adjust endIndex forward if we are close to the start
  if (currentPage < totalPages && currentPage <= maxPageNumbersShown) {
    endIndex = Math.min(currentPage + (maxPageNumbersShown - currentPage), totalPages);
  }

  if (startIndex > 2) {
    pages.push(1, '...');
  } else if (startIndex === 2) {
    pages.push(1);
  }

  for (let i = startIndex; i <= endIndex; i++) {
    pages.push(i);
  }

  if (endIndex < totalPages - 1) {
    pages.push('...', totalPages);
  } else if (endIndex === totalPages - 1) {
    pages.push(totalPages);
  }

  return pages;
};
