import Image from 'next/image';

export const DisplayChildProductsForBundle = ({childProducts}) => {
  if (!childProducts || childProducts?.length === 0) return;

  if (childProducts && childProducts?.length > 0) {
    return (
      <div className='w-full mt-1'>
        <div className={'space-y-1 bg-neutral-200 rounded p-2 border border-gray-800'}>
          <h3 className='text-sm font-bold mb-1'>Bundle Contents</h3>
          {childProducts?.map((childProduct, index) => {
            return (
              <div key={`bundle_child_sku_${index + 1}`} className='flex items-center gap-4'>
                <Image
                  className='object-contain border rounded-md'
                  width={50}
                  height={50}
                  src={childProduct?.image}
                  alt={`${childProduct?.sku} Product Image`}
                />
                <div className='flex-1'>
                  <div className='text-sm font-medium'>{`SKU: ${childProduct.sku}`}</div>
                  <div className='text-xs font-medium text-gray-500'>{`Quantity: ${childProduct?.quantity}`}</div>
                  <div className='text-xs font-medium text-gray-500 line-through'>{`MAP: $${childProduct?.price_map}`}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};
