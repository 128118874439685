import {useState} from 'react';
import {useMobileOrTablet} from '@helpers/hooks/useWindow';
import {SuccessCheckMarkGreenSVG_xs} from '@components/widgets/SVGs/GreenCheckMarkSuccess';
import {useRouter} from 'next/router';

export const VehicleSelectorComponent = ({years, selections, makeOptions, modelOptions}) => {
  const router = useRouter();
  const currentPath = router.asPath;

  const isMobile = useMobileOrTablet();
  const isDesktop = isMobile === false;

  const year = selections?.year;
  const make = selections?.make;
  const model = selections?.model;
  // console.log("[VehicleSelectorComponent][selections]:", selections?.make);

  const [isHoveringYear, setIsHoveringYear] = useState(false);
  const [isHoveringMake, setIsHoveringMake] = useState(false);
  const [isHoveringModel, setIsHoveringModel] = useState(false);

  const handleYearChange = (e) => {
    const yearValue = e.target.value;
    const currentUrlParams = new URLSearchParams(currentPath.split('?')[1]);
    currentUrlParams.set('vehicle_year', yearValue);
    currentUrlParams.delete('vehicle_model');

    router.push(`?${currentUrlParams.toString()}`, undefined, {shallow: true});
  };

  const handleVehicleMakeChange = (e) => {
    const makeValue = e.target.value;
    const currentUrlParams = new URLSearchParams(currentPath.split('?')[1]);
    currentUrlParams.set('vehicle_make', makeValue);
    currentUrlParams.delete('vehicle_model');

    router.push(`?${currentUrlParams.toString()}`, undefined, {shallow: true});
  };

  const handleModelChange = (e) => {
    const modelValue = e.target.value;
    const currentUrlParams = new URLSearchParams(currentPath.split('?')[1]);
    if (!modelValue) {
      currentUrlParams.delete('vehicle_model');
    } else {
      currentUrlParams.set('vehicle_model', modelValue);
    }

    router.push(`?${currentUrlParams.toString()}`, undefined, {shallow: true});
  };

  const commonStyles = {
    fontSize: '16px',
    fontFamily: 'Arial, sans-serif',
    border: 'solid 1px',
    borderRadius: '0px',
    height: '40px',
    padding: '8px',
    margin: '2px',
    backgroundColor: '#FFFFFF',
    cursor: 'pointer'
  };

  const mobileStyles = isMobile
    ? {
        ...commonStyles,
        maxWidth: '350px'
      }
    : {};

  const desktopStyles = (isHovering) =>
    isDesktop
      ? {
          ...commonStyles,
          maxWidth: '300px',
          backgroundColor: isHovering ? 'rgb(242, 242, 242)' : '#FFFFFF'
        }
      : {};

  const selectStyle = (selected, value, isHovering, {type}) => {
    // console.log("[selectStyle][type]:", type)
    // console.log("[selectStyle][selected]:", selected, "[value]:", value, "[isHovering]:", isHovering)
    let borderColor = 'rgb(124, 124, 124)';
    let borderThickness = '2px';
    if (!year && type === 'year') {
      borderColor = 'rgb(255,172,7)';
      borderThickness = '3.25px';
    }
    if (year && make && !model && type === 'model') {
      borderColor = 'rgb(255,172,7)';
      borderThickness = '2.5px';
    }
    if (value) {
      borderColor = 'rgb(68, 167, 5)';
    }
    return {
      ...desktopStyles(isHovering),
      ...mobileStyles,
      backgroundImage: value ?? null,
      border: `${borderThickness} solid ${borderColor}`
    };
  };

  const handleMouseEnterYear = () => {
    setIsHoveringYear(true);
  };

  const handleMouseLeaveYear = () => {
    setIsHoveringYear(false);
  };

  const handleMouseEnterMake = () => {
    setIsHoveringMake(true);
  };

  const handleMouseLeaveMake = () => {
    setIsHoveringMake(false);
  };

  const handleMouseEnterModel = () => {
    setIsHoveringModel(true);
  };

  const handleMouseLeaveModel = () => {
    setIsHoveringModel(false);
  };

  const showMakeDropdown = (year && makeOptions?.length > 0) || make;

  return (
    <div id='vehicle-selector-wrapper' className='mb-2'>
      <style jsx>{`
        select:focus {
          border-color: #000;
          outline: none;
          box-shadow: 0 0 1px #2f2d2d;
        }

        select {
          caret-color: transparent;
        }
      `}</style>

      <div className='flex items-center font-bebas mt-3 mb-2 xl:text-[28px] lg:text-2xl'>
        <span className='text-center'>Find what Fits &nbsp;</span>
        <div className={'w-8 lg:w-4 xl:w-8'}>
          <SuccessCheckMarkGreenSVG_xs />
        </div>
        <span>&nbsp; your vehicle</span>
      </div>

      <div className='flex flex-col gap-1 mb-3'>
        <label htmlFor='year-dropdown' className='visually-hidden'>
          Select A Vehicle Year
        </label>
        <select
          id='year-dropdown'
          value={year || ''}
          onChange={handleYearChange}
          onMouseEnter={handleMouseEnterYear}
          onMouseLeave={handleMouseLeaveYear}
          className='bg-transparent dark:bg-white outline-none ring-0 w-full mb-3'
          style={selectStyle('', year, isHoveringYear, {type: 'year'})}>
          <option value=''>Select Year</option>
          {years.map((year, i) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>

        {showMakeDropdown && (
          <>
            <label htmlFor='make-dropdown' className='visually-hidden'>
              Select A Vehicle Make
            </label>
            <select
              id='make-dropdown'
              value={make || ''}
              onChange={handleVehicleMakeChange}
              onMouseEnter={handleMouseEnterMake}
              onMouseLeave={handleMouseLeaveMake}
              className='dark:bg-white bg-transparent outline-none ring-0 w-full'
              style={selectStyle('', make, isHoveringMake, {type: 'make'})}>
              <option value=''>Select Make</option>
              {makeOptions.map((make, i) => (
                <option key={i} value={make}>
                  {make}
                </option>
              ))}
            </select>
          </>
        )}

        {make && modelOptions?.length > 0 && (
          <>
            <label htmlFor='model-dropdown' className='visually-hidden'>
              Select A Vehicle Model
            </label>
            <select
              id='model-dropdown'
              value={model || ''}
              onChange={handleModelChange}
              onMouseEnter={handleMouseEnterModel}
              onMouseLeave={handleMouseLeaveModel}
              className='dark:bg-white bg-transparent outline-none ring-0 w-full'
              style={selectStyle('', model, isHoveringModel, {type: 'model'})}>
              <option value=''>Select Model</option>
              {modelOptions.map((model, i) => {
                return (
                  <option key={i} value={model}>
                    {model}
                  </option>
                );
              })}
            </select>
          </>
        )}
      </div>
    </div>
  );
};
