import {capitalizeEveryWord} from '@helpers/common/string.mjs';
import {longDate} from '@helpers/common/date.mjs';
import {ShowStarRating} from '@components/product/reviews/reviews-with-images';
import cn from 'clsx';

export const ReviewHeader = ({review, showTitleWithReviewOnMobile}) => {
  let reviewTitle = capitalizeEveryWord(String(review?.title ?? ''));
  reviewTitle = ['Null', 'Undefined', 'undefined'].includes(reviewTitle) ? '' : reviewTitle;

  const dateOfReview = review?.review_date ? longDate(review?.review_date) : '';
  const nameOfReviewer = review?.reviewer?.name
    ? `By ${review.reviewer.name.split(' ')[0]} on ${dateOfReview}`
    : '';

  return (
    <>
      {nameOfReviewer && <div className="text-md">{nameOfReviewer}</div>}

      <div className="flex items-center gap-2 pb-0.5">
        <ShowStarRating stars={Math.round(review?.rating)} size={18} />

        {String(review?.verified).includes('buyer') && (
          <div className="text-[12px] text-white md:text-normal md:text-skar-green">
            Verified Buyer
          </div>
        )}
      </div>

      <div
        className={cn('text-[18px] text-white md:text-black font-bold', {
          'block md:block': !showTitleWithReviewOnMobile
        })}>
        {reviewTitle}
      </div>
    </>
  );
};

export const ReviewTextWithShowMoreButton = ({
  reviewText,
  showMoreReviewText,
  setShowMoreReviewText
}) => {
  const TEXT_LIMIT = 175;
  const visibleReviewText = showMoreReviewText
    ? reviewText
    : String(reviewText).substring(0, TEXT_LIMIT);

  const hasMoreTextThanLimit = reviewText?.length > TEXT_LIMIT;

  const handleExpandReviewText = (e) => {
    e.stopPropagation();
    if (hasMoreTextThanLimit) {
      setShowMoreReviewText(!showMoreReviewText);
    }
  };

  return (
    <div className="text-white md:text-black" onClick={handleExpandReviewText}>
      <div className="md:hidden">
        {visibleReviewText}
        {hasMoreTextThanLimit && (
          <>
            {!showMoreReviewText && <span>...</span>}
            <button className="block mb-1 mt-0.5 text-white">
              {!showMoreReviewText ? 'More' : 'Hide'}
            </button>
          </>
        )}
      </div>
      <div className="hidden md:block md:text-black">{reviewText}</div>
    </div>
  );
};

export const ReviewPagination = ({currentSlide, totalSlides}) => {
  return (
    <div className="flex gap-3 w-full h-1">
      {totalSlides > 1 &&
        Array.from({length: totalSlides}).map((_, index) => (
          <div
            key={index}
            className={`h-1 w-full ${currentSlide >= index ? 'bg-sky-500' : 'bg-gray-200 md:bg-neutral-300'}`}
          />
        ))}
    </div>
  );
};
