import {useState} from 'react';
import {ReviewImages, ShowStarRating} from '@components/product/reviews/reviews-with-images';
import {sentenceCase} from 'sentence-case';
import {longDate} from '@helpers/common/date.mjs';
import {HStack} from '@components/widgets/Common';
import {ReviewThumbsUp} from '../reviews/thumbs-up';
import {capitalizeEveryWord} from '@helpers/common/string.mjs';
import dynamic from 'next/dynamic';

const ReviewAdminBar = dynamic(
  () =>
    import('@components/product/reviews/review-photos/review-admin-bar').then(
      (mod) => mod.ReviewAdminBar
    ),
  {
    ssr: false
  }
);

export function RatingSummaryMobile({avgScore, count}) {
  if (!avgScore) {
    return null;
  }
  const score = avgScore.toFixed(1);
  const stars = Math.round(avgScore);
  return (
    <div className='font-oswald uppercase'>
      <HStack className='justify-start items-center' gap={3}>
        <div className='font-bold text-1xl'>{score}</div>
        <ShowStarRating stars={stars} size={20} />
      </HStack>
      <p className='text-sm'>Based on {count} reviews</p>
    </div>
  );
}

export function OneReviewMobile({review, reviewIndex, isAdminUser}) {
  const [open, setOpen] = useState(false);
  const stars = Math.round(review.rating);
  const TEXT_LIMIT = 220;

  let reviewText = review.body;
  let reviewTitle = capitalizeEveryWord(String(review?.title ?? ''));
  // console.log({reviewTitle, title: review?.title})
  reviewTitle = reviewTitle === 'Null' ? '' : reviewTitle;
  reviewTitle = reviewTitle === 'Undefined' ? '' : reviewTitle;
  reviewTitle = reviewTitle === 'undefined' ? '' : reviewTitle;
  const visibleReviewText = open ? reviewText : String(reviewText).substring(0, TEXT_LIMIT);

  reviewText = reviewText
    ?.split('.')
    ?.map((x) => sentenceCase(x))
    ?.join('. ');

  let dateOfReview;
  if (review?.review_date) {
    dateOfReview = longDate(review?.review_date);
  }

  let nameOfReviewer;
  if (review?.reviewer?.name) {
    nameOfReviewer = review?.reviewer?.name.split(' ')?.[0];
  } else {
    nameOfReviewer = '';
  }

  const reviewThumbsUp = review?.thumbs_up || 0;

  return (
    <div className='my-2 pb-4 px-2 md:px-0'>
      <div>
        <HStack className='justify-start items-center' style={{lineHeight: '20px'}} gap={3}>
          <ShowStarRating stars={stars} size={18} />
        </HStack>

        <HStack className='justify-start items-center mt-1' gap={1}>
          <div className='pt-0.5 text-sm'>
            By {nameOfReviewer} on {dateOfReview}
          </div>

          {String(review?.verified).includes('buyer') && (
            <div className='px-1 whitespace-nowrap text-xs text-skar-green font-bold'>
              Verified Buyer
            </div>
          )}

          {isAdminUser && <ReviewAdminBar review={review} />}
        </HStack>
      </div>
      <div className='overflow-hidden pt-2'>
        <h6 className='text-md font-bold text-ellipsis'>{reviewTitle}</h6>
      </div>

      <div className='text-sm'>
        {visibleReviewText}

        {reviewText?.length > TEXT_LIMIT && !open && (
          <button
            className='border border-gray-300 rounded text-gray-500 text-right my-1 mx-2 px-2 bg-gray-100 text-xs'
            style={{float: 'right'}}
            onClick={() => setOpen(true)}>
            more ...
          </button>
        )}
      </div>
      <ReviewImages images={review?.images} review={review} reviewIndex={reviewIndex} />

      <ReviewThumbsUp reviewId={review?.id} thumbsUp={reviewThumbsUp} />
    </div>
  );
}
