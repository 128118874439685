import {getAnyCoreLinuxDB} from "../../mysql/mysql.mjs";

export let dealerShippingCost = 37.12;

export const getShippingRateEstimate = async (checkoutInfo) => {
  const cartToken = checkoutInfo?.cart_token;
  const customer = checkoutInfo?.customer;

  if (!cartToken) {
    return {
      rate_estimate: null,
    }
  }

  if (!customer) {
    return {
      rate_estimate: null
    }
  }

  const isDealer = checkoutInfo?.customer?.role === 2
  if (isDealer) {
    try {
      // let response = await getShipmentWeightAndAddress(cartToken, emailAddress)
      // let shipmentWeight = response?.shipmentWeight;
      // let shippingAddress = response?.shippingAddress;
      // try {
      // rate_estimate = await getFedExRateEstimateForOneCartToken(shipmentWeight, shippingAddress)
      // ^^ @goes in try catch below, moved out for time being.
      return {
        rate_estimate: dealerShippingCost,
      }
    } catch (e) {
      console.error(e)
      return {
        code: 'NO_ESTIMATE',
        message: e?.message,
        rate_estimate: 200, // $200
      }
    }
  }
  return {
    rate_estimate: null,
  }
}


export async function updateCheckoutRowWithDealerShippingEstimatedCost(checkoutInfo, shippingEstimate = 0) {
  if (!shippingEstimate && shippingEstimate !== 0) {
    shippingEstimate = checkoutInfo?.final_shipping_total ?? null;
  }
  shippingEstimate = shippingEstimate > 0 ? Number(shippingEstimate) : null;
  const data = {
    final_shipping_total: shippingEstimate,
    updated_at: new Date().toISOString(),
  };
  const dbOrders = getAnyCoreLinuxDB('user_orders')
  const tCheckout = dbOrders.getTable('checkouts')
  await tCheckout.update(data, {
    checkout_token: checkoutInfo.checkout_token,
  });
  return {
    ...checkoutInfo,
    ...data,
  };
}
