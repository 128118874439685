import cn from 'clsx';
import {useState} from 'react';
import {consoleLogFrontEndFormData} from '@helpers/logging/provider-logging.mjs';
import {useIsMobile} from '@helpers/hooks/useWindow';
import {useSession} from 'next-auth/react';
import {formatPhoneNumber} from '@helpers/common/format/format-phone.mjs';
import {isofetch} from '@helpers/common/fetch.mjs';

const getInitialStates = () => {
  const letStates = {
    emailConsent: '',
    smsConsent: '',
    email: '',
    phone: ''
  };
  return letStates;
};

export default function SignUp() {
  const {data: session, status} = useSession();
  const sessionIsLoading = status === 'loading';
  const isLoggedIn = session && !!session?.user?.email ? true : false;

  const [value, setValue] = useState(getInitialStates());
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const isMobile = useIsMobile();
  const [isEmailError, setEmailError] = useState(false);
  const [isPhoneError, setPhoneError] = useState(false);

  if (sessionIsLoading) {
    return null;
  }

  if (isLoggedIn) {
    return null;
  }

  const emailConsent = session?.user?.email_consent;
  const smsConsent = session?.user?.sms_consent;

  const hasEmailAndSmsConsented = emailConsent === 1 && smsConsent === 1;

  const handleChange = (e) => {
    const target = e.target;
    setValue((v) => {
      const changedValues = {
        ...v,
        [target.name]: target.value
      };
      return changedValues;
    });
    setEmailError(false);
    setPhoneError(false);
  };

  const handleChangeCheckbox = (e) => {
    let target = e.target;
    setValue((v) => {
      const changedValuesCheckbox = {
        ...v,
        [target.name]: target.checked
      };
      return changedValuesCheckbox;
    });
    setEmailError(false);
    setPhoneError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);
    setLoading(true);
    setEmailError(false);
    setPhoneError(false);

    // console.log(value)
    try {
      consoleLogFrontEndFormData(value);
      const isOK = await validateSubmissionFieldsBeforeSaving(
        value,
        setError,
        setEmailError,
        setPhoneError
      );
      if (!isOK) {
        setLoading(false);
        return;
      }

      console.log('[SignUp] - value: ', value);
      await saveNewFooterSubscriberToAccountsDB(value, isMobile);

      const email = formData?.email;
      if (!email) {
        if (checkAccountsForExisitingEmail === true) {
          await updateExisitingSubscriberConsents(value, isMobile);
          setSuccess(true);
        }
      }

      if (hasEmailAndSmsConsented) {
        return null;
      }
      setSuccess(true);
    } catch (e) {
      console.error(e);
      setSuccess(false);
    }
    setLoading(false);
  };

  const isDisabled = !value?.emailConsent && !value?.smsConsent;

  return (
    <div className='relative overflow-hidden font-oswald p-5 bg-gray-200'>
      <div className='max-w-[900px] m-auto'>
        <div className='text-left mb-[2px] font-oswald font-medium text-xl md:text-xl pl-3'>
          Get on our list!
          <span className='hidden md:inline font-normal text-md md:text-md ml-2'>
            Be the first to know everything about Skar Audio.
          </span>
        </div>
        <form onSubmit={handleSubmit} className='px-2'>
          <div className='md:flex mt-3'>
            <div className='mb-3 w-full md:mr-2'>
              <label htmlFor='email' className='visually-hidden'>
                Email
              </label>
              <input
                className={cn('home-input', {
                  'bg-orange-100': isEmailError
                })}
                id='email'
                name='email'
                type='email'
                placeholder='Enter your email address'
                onChange={handleChange}
              />
            </div>
            <div className='w-full md:ml-2'>
              <label htmlFor='phone' className='visually-hidden'>
                Phone
              </label>
              <input
                className={cn('home-input', {
                  'bg-orange-100': isPhoneError
                })}
                id='phone'
                name='phone'
                placeholder='Enter your phone number'
                onChange={handleChange}
              />
            </div>
            <div className='hidden md:block ml-4'>
              <button
                type='submit'
                className={cn(
                  'duration-200 h-[40px] py-1 pb-1 px-16 flex',
                  'm-auto rounded-xl md:rounded-md items-center justify-center',
                  'whitespace-nowrap text-xl font-medium font-oswald disabled:bg-gray-300',
                  {
                    'bg-gray-200 text-neutral-600': isDisabled,
                    'bg-orange-500 text-white': !isDisabled
                  }
                )}
                disabled={isLoading || isDisabled}>
                {isLoading ? (
                  <div
                    style={{borderTopColor: 'transparent'}}
                    className='w-[20px] h-[20px] border-2 border-blue-400 border-solid rounded-full animate-spin'
                  />
                ) : (
                  'SIGN UP'
                )}
              </button>
            </div>
          </div>

          <div className=''>
            <div className='flex items-center'>
              <div className='mr-2 md'>
                <input
                  className={cn('home-checkbox md:text-base')}
                  id='email_checkbox'
                  type='checkbox'
                  onChange={handleChangeCheckbox}
                  name='emailConsent'
                />
              </div>
              <div>
                <label
                  className='mt-3 md:text-base'
                  htmlFor='email_checkbox'
                  style={{fontSize: '13px'}}>
                  I consent to receive emails regarding marketing and special offers.
                </label>
              </div>
            </div>
            <div className='flex items-center'>
              <div className='mr-2 mt-1'>
                <input
                  className={cn('home-checkbox md:text-base', {
                    'border-rose-500': isPhoneError
                  })}
                  id='phone_checkbox'
                  type='checkbox'
                  onChange={handleChangeCheckbox}
                  name='smsConsent'
                />
              </div>
              <div>
                <label
                  style={{
                    lineHeight: '20px',
                    marginTop: '3px',
                    fontSize: '13px'
                  }}
                  className='block text-sm md:text-base'
                  htmlFor='phone_checkbox'>
                  I consent to receive SMS messages regarding special offers, order updates, and
                  more and that message frequency may vary.
                </label>
              </div>
            </div>
          </div>
          {error && <p className='text-red-400 text-center mt-2'>{error}</p>}
          <div className='text-center mt-6 md:hidden'>
            <button
              type='submit'
              className='duration-200 h-[40px] py-1 px-16 flex text-white m-auto rounded-xl md:rounded-md items-center justify-center whitespace-nowrap text-xl font-medium font-oswald'
              style={{background: '#313539', paddingBottom: 4}}
              disabled={isLoading}>
              {isLoading ? (
                <div
                  style={{borderTopColor: 'transparent'}}
                  className='w-[20px] h-[20px] border-2 border-blue-400 border-solid rounded-full animate-spin'
                />
              ) : (
                'SIGN UP'
              )}
            </button>
          </div>
          <p className='md:text-[14px] mt-4' style={{fontSize: '9px'}}>
            * By clicking Sign Up, you agree to receive recurring automated marketing messages, at
            the phone number provided. Consent is not a condition of purchase. Reply STOP to
            unsubscribe. Reply HELP to help. Message frequency varies. Msg & data rates may apply.
            View our Privacy Policy and Term of Service.
          </p>
        </form>
        {isSuccess && (
          <div
            className={`absolute h-full w-full flex justify-center items-center top-0 left-0 z-10
             bg-gray-200 fadeInSuccess`}>
            <div>
              <h2 className='font-oswald font-medium text-center text-3xl'>THANK YOU</h2>
              <h2 className='font-oswald font-medium text-center text-3xl'>FOR SIGNING UP!</h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export const updateExisitingSubscriberConsents = async (formData, isMobile) => {
  // console.log('Data inside [updateExisitingSubscriberConsents] => ', formData)
  // const phoneNumber = validateFormPhoneNumberForUpdateAccountRow(account_row, formData);
  let phoneNumber = formatPhoneNumber(formData.phone);

  let payload = {
    email: formData?.email,
    email_consent: true,
    form_type: isMobile ? 'mobile' : 'desktop'
  };

  if (phoneNumber?.length > 1 && formData?.smsConsent === 'on') {
    payload = {
      ...payload,
      phone: phoneNumber,
      sms_consent: true
    };
  }
  // consoleLogFrontEndFormData("Payload for DB Account Row Update Sent From FrontEnd -> Backend: ", payload);

  const data = await isofetch.post(`/api/marketing/update-subscriber`, payload);

  // console.log('Response from [updateExisitingSubscriberConsents] => ', data)
  if (data.status !== 'success') {
    throw new Error('Error: Failed to update exisiting profile');
  }
  return data;
};

const saveNewFooterSubscriberToAccountsDB = async (formData, isMobile) => {
  const payload = {
    email: formData.email,
    email_consent: formData?.emailConsent,
    phone: formData.phone,
    sms_consent: formData?.smsConsent,
    form_type: isMobile ? 'mobile' : 'desktop'
  };
  console.log('FormData [saveNewFooterSubscriberToAccountsDB] => ', formData);
  const data = await isofetch.post(
    `/api/marketing/subscribers-footer/save-footer-subscriber`,
    payload
  );

  // console.log('Save New Footer Subscriber Response: ', data)
  if (data?.status !== 'success') {
    throw new Error('Error: Failed - Please try again later');
  }
  return data;
};

const validateSubmissionFieldsBeforeSaving = async (
  value,
  setError,
  setEmailError,
  setPhoneError
) => {
  let isOK = true;
  if (value?.email || value?.phone || value?.smsConsent || value?.emailConsent) {
    if (value?.smsConsent && (!value?.phone || value?.phone === '')) {
      setError('Phone is required when opting for marketing SMS');
      setPhoneError(true);
      isOK = false;
    }

    if (value?.phone && (!value?.smsConsent || value?.smsConsent === '')) {
      setError('Please check consent the checkbox for marketing SMS');
      setPhoneError(true);
      isOK = false;
    }

    if (value?.emailConsent && (!value?.email || value?.email === '')) {
      setError('Email is required when opting for marketing emails');
      setEmailError(true);
      isOK = false;
    }

    if (value?.email && (!value?.emailConsent || value?.emailConsent === '')) {
      setError('Please check consent the checkbox for marketing emails.');
      setEmailError(true);
      isOK = false;
    }
  }
  return isOK;
};
