import cn from 'clsx';
import Link from 'next/link';
import React, {useState} from 'react';
import {Center, WhiteCard} from '@components/checkout-v2/desktop/checkout-helper';
import {useMobileOrTablet} from '@helpers/hooks/useWindow';
import {ReviewStarsFromDb} from '@components/product/reviews/review-stars-from-db';
import {generateYearsForCCExp} from '@helpers/common/common.mjs';

export const remapFord09SkuTo15Sku = (selectedProduct) => {
  let sku = selectedProduct?.sku;
  if (sku?.includes('FORD-2009-UP')) {
    sku = sku.replace('2009-UP', '2015-UP');
  }
  return sku;
};

export const CustomerReviewsForProductWithLink = ({
  parentVariant,
  reviewsAnchor,
  reviewsFromDb,
  handleScrollToReviews
}) => {
  return (
    <a
      href={`/products/${parentVariant?.productPath}#product-reviews`}
      onClick={(e) => {
        e.preventDefault();
        handleScrollToReviews(e, reviewsAnchor);
      }}
      tabIndex='0'
      aria-label={`Review rating ${reviewsFromDb?.avgScore} out of 5 stars from ${reviewsFromDb?.count} reviews. Scroll down to read reviews.`}
      style={{cursor: 'pointer', textDecoration: 'none'}}>
      <ReviewStarsFromDb avgScore={reviewsFromDb?.avgScore} count={reviewsFromDb?.count} />
    </a>
  );
};

export const sanitizeUrl = (url) => {
  if (url === undefined || url === null) {
    // console.log("Received undefined or null URL");
    return '';
  }
  return url.replace(/\/{2,}/g, '/').replace(/\\+/g, '/');
};

export const handleScrollToReviews = (e, reviewsAnchor) => {
  e.preventDefault();
  reviewsAnchor.current.scrollIntoView({block: 'start', behavior: 'smooth'});
};

export function OnlyDesktop({id, children, className, style}) {
  let isMobile = useMobileOrTablet();
  if (isMobile) {
    return;
  }
  return (
    <div id={id} className={cn('hidden md:block', className)} style={style}>
      {children}
    </div>
  );
}

export function OnlyMobile({id, children, className, style}) {
  let isMobile = useMobileOrTablet();
  if (!isMobile) {
    return;
  }
  return (
    <div id={id} className={cn('block md:hidden', className)} style={style}>
      {children}
    </div>
  );
}

export function DesktopMaxWidth({id, children, className, maxW = 'max-w-[1300px]'}) {
  return (
    <div id={id} className={cn(maxW, 'm-auto', className)}>
      {children}
    </div>
  );
}

export function MobileMaxWidth({id, children, className, maxW = 'max-w-[768px]'}) {
  return (
    <div id={id} className={cn(maxW, 'm-auto', className)}>
      {children}
    </div>
  );
}

export function DesktopSpinner({children, showSpinner = true}) {
  return (
    <DesktopMaxWidth>
      <WhiteCard>
        <Center className='my-32'>
          {showSpinner ? <Spinner /> : null}
          {children}
        </Center>
      </WhiteCard>
    </DesktopMaxWidth>
  );
}

export const ReviewStarsLinkWrapper = ({children}) => {
  const handleClick = (e) => {
    e.preventDefault();
    const reviewElement = document.querySelector('a[name="product-reviews"]');
    // console.log('[ReviewStarsLinkWrapper] reviewElement:', reviewElement);
    if (reviewElement) {
      reviewElement.scrollIntoView();
    }
  };

  return <a onClick={handleClick}>{children}</a>;
};

export const cleanHandleOfMultipleSlashes = (handle) => {
  if (!handle) return;
  return handle ? handle.replaceAll('//', '/') : null;
};

export const removeNullsFromObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeNullsFromObject(obj[key]);
    } else if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
};

export function NextLink({href, className, children, style}) {
  const type = React.Children.map(children, (x) => x?.type?.displayName);
  if (typeof type?.[0] !== 'undefined') {
    return (
      <Link href={href} legacyBehavior passHref className={className}>
        {children}
      </Link>
    );
  }

  return (
    <Link href={href} legacyBehavior passHref>
      <a className={className} style={style}>
        {children}
      </a>
    </Link>
  );
}

export function ErrorAlert({error, className, children}) {
  let errorMessage = typeof error === 'object' ? error?.message : children;
  errorMessage = String(errorMessage ?? '').replace('Invariant failed:', '');
  return errorMessage ? (
    <div className={cn('p-3 bg-yellow-300 text-black m-3', className)}>
      <div className='fw-bold'>{errorMessage}</div>
    </div>
  ) : null;
}

export function useOnHover() {
  const [isHover, setHover] = useState(false);
  const onMouseEnter = (e) => {
    setHover(true);
  };
  const onMouseLeave = (e) => {
    setHover(false);
  };
  return {
    isHover,
    setHover,
    hoverEvents: {
      onMouseEnter,
      onMouseLeave
    }
  };
}

export function useHasFocus() {
  const [hasFocus, setFocus] = useState(false);
  const onFocus = (e) => {
    setFocus(true);
  };
  const onBlur = (e) => {
    setFocus(false);
  };
  return {
    hasFocus,
    setFocus,
    focusEvents: {
      onFocus,
      onBlur
    }
  };
}

export function useStateVar(defaultValue) {
  const [state, setState] = useState(defaultValue);
  return {
    value: state,
    set: setState,
    setTrue: () => setState(true),
    setFalse: () => setState(false)
  };
}

export const CheckBoxSpinner = ({size = 4}) => {
  const sizeClass = `h-${size} w-${size}`;
  return (
    <div className={sizeClass}>
      <svg
        className='animate-spin align-middle'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        style={{width: `${size * 4}px`, height: `${size * 4}px`}}>
        <circle
          className='opacity-25'
          cx='12'
          cy='12'
          r='10'
          stroke='currentColor'
          strokeWidth='4'
        />
        <path
          className='opacity-75'
          fill='currentColor'
          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
        />
      </svg>
    </div>
  );
};

export function Spinner({color = 'black', size = 24, className}) {
  return (
    <div className='mx-auto' style={{width: size, height: size}}>
      <svg
        className={className + ' animate-spin align-bottom ' + color}
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        style={{width: size, height: size}}>
        <circle
          className='opacity-25'
          cx='12'
          cy='12'
          r='10'
          stroke='currentColor'
          strokeWidth='4'
        />
        <path
          className='opacity-75'
          fill='currentColor'
          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
        />
      </svg>
    </div>
  );
}

export function HStack({className, children, gap, style, start, top}) {
  className = className ?? '';
  if (!className || !String(className)?.includes('justify-')) {
    if (start) {
      className += ' justify-start';
    } else {
      className += ' justify-between';
    }
  }
  if (!className || !String(className)?.includes('items-')) {
    if (top) {
      className += ' items-start';
    } else {
      className += ' items-center';
    }
  }
  return (
    <div className={cn('flex flex-row w-full', className, gap ? 'gap-' + gap : null)} style={style}>
      {children}
    </div>
  );
}

export function HStackMobileVStack({className, children, gap, style}) {
  if (!className) {
    className = 'justify-between items-spread md:items-center';
  }
  return (
    <div
      className={cn('flex flex-col md:flex-row w-full', className, gap ? 'gap-' + gap : null)}
      style={style}>
      {children}
    </div>
  );
}

export function VStack({children, className, gap, style, start, end, itemStart}) {
  if (!className) {
    className = 'items-center justify-between';
  }

  if (start) {
    className += ' justify-content-start';
  }
  if (end) {
    className += ' justify-content-end';
  }
  if (itemStart) {
    className += ' align-items-start';
  }

  return (
    <div
      className={cn('flex flex-col w-full h-full', className, gap ? 'gap-' + gap : null)}
      style={style}>
      {children}
    </div>
  );
}

export const monthOptions = [
  {
    key: '',
    val: '',
    text: 'Select Month'
  },
  ...Array.from({length: 12}, (_, index) => ({
    key: (index + 1).toString().padStart(2, '0'),
    val: (index + 1).toString().padStart(2, '0'),
    text: (index + 1).toString().padStart(2, '0'),
    disabled: false
  }))
];

export const yearOptions = [
  {
    key: '',
    val: '',
    text: 'Select Year'
  },
  ...Object.entries(generateYearsForCCExp())?.map(([key, val]) => ({
    key,
    val: key,
    text: val,
    disabled: false
  }))
];

const Svg = ({size, ...props}) => (
  <svg
    role='presentation'
    viewBox='0 0 24 24'
    style={{
      display: 'inline-block',
      fill: 'currentColor',
      height: size,
      stroke: 'currentColor',
      strokeWidth: 0,
      width: size
    }}
    {...props}
  />
);

export const ChevronLeft = ({size = 32, ...props}) => (
  <Svg size={size} {...props}>
    <path d='M15.422 16.078l-1.406 1.406-6-6 6-6 1.406 1.406-4.594 4.594z' />
  </Svg>
);

export const ChevronRight = ({size = 32, ...props}) => (
  <Svg size={size} {...props}>
    <path d='M9.984 6l6 6-6 6-1.406-1.406 4.594-4.594-4.594-4.594z' />
  </Svg>
);

export function Money({data: {amount}}) {
  return amount;
}

export const RawHtml = ({string, className}) => (
  <div dangerouslySetInnerHTML={{__html: string}} className={className} />
);

export function CenterLargeTextHeader({text, children}) {
  return (
    <div className='max-w-[1120px] m-auto mb-10'>
      <div className='flex justify-center mb-6' style={{paddingTop: '10px'}}>
        <div
          className='flex-1 text-black fw-bold text-md text-2xl md:text-3xl font-oswald uppercase text-center pt-5'
          style={{
            paddingTop: '30px'
          }}>
          {text}
        </div>

        <div className='max-w-[800px] flex justify-end mx-auto border-b mb-4 pb-4'></div>
      </div>
      {children}
    </div>
  );
}
