import LogoBlack from '@image/logos/skar-audio-logo-black.svg';
import Image from 'next/image';
import Link from 'next/link';
import {ErrorBox, MessageBox} from '@components/form-fields/MessageBoxes.jsx';
import {Field} from '@components/form-fields/Field.jsx';
import {TextInput} from '@components/form-fields/Inputs/TextInput.jsx';
import {SubmitButton} from '@components/form-fields/Inputs/Button.jsx';

export const DesktopPopup = ({
                               handleSubmit,
                               submitForm,
                               success,
                               existsMessage,
                               errors,
                               register,
                               isSubmitting,
                               formError,
                               isDisabled,
                               email,
                             }) => {
  const showNewSuccessMessage = !existsMessage && success;
  const hideInputForEmail = showNewSuccessMessage;

  if (isSubmitting || success) {
    isDisabled = true;
  }

  return (
    <div className="p-8 text-center text-black">
      <div className="flex justify-center my-3 mb-4">
        <Image
          src={LogoBlack}
          alt="Skar Audio Logo Black"
          width={256}
          height={80}
          layout="fixed"
        />
      </div>

      <div className="uppercase font-bold text-4xl">Join Now</div>
      <div className="text-4xl my-3 uppercase font-oswald text-orange-700 font-bold">V.I.P</div>
      <div className="uppercase text-xl">Exclusive Invite</div>
      <div className="pt-4 pb-5">Join our VIP list to unlock special discounts and more!</div>

      {existsMessage &&
        <MessageBox>{existsMessage}</MessageBox>
      }

      {showNewSuccessMessage && (
        <MessageBox>
          <span className="text-2xl">{"Thanks for subscribing, you're now a Skar Audio VIP!"}</span>
        </MessageBox>
      )}

      {formError &&
        <ErrorBox>{formError}</ErrorBox>
      }

      {!hideInputForEmail && (
        <form onSubmit={handleSubmit(submitForm)}>
          <Field error={errors?.email}>
            <TextInput
              id='email'
              name="email"
              className=""
              register={register}
              validations={{required: 'Email address is required'}}
              placeholder="Email Address"
              required
            />
          </Field>

          <div className="flex justify-center mb-3">
            <SubmitButton
              onClick={submitForm}
              submitting={isSubmitting}
              disabled={!email || isDisabled}
              fullWidth
              largeText>
              Submit
            </SubmitButton>
          </div>

          <div className="text-xs mb-1">
            By clicking submit you are agreeing to sign up to receive emails from Skar Audio about
            special
            offers, products, and services. See our <Link href="/pages/privacy-policy">privacy
            policy</Link> for more
            details.
          </div>
        </form>
      )}
    </div>
  );
};
