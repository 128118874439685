export const cleanAnySKU = (sku) => {
  sku = sku?.replaceAll('  ', ' ');
  sku = skuCleaner(sku);
  return sku;
};


export function skuCleaner(sku) {
  let finalSku = sku;
  const skuSegments = [
    '3CH-BNDL-(2)_',
    '3CH-BNDL-(2) ',
    'DLR-',
    '-Good',
    '-FBA2',
    '-FBA',
    '-FBM3',
    '-FBM2',
    '-FBM',
    'FBA',
    'FBAMAIN',
    'MAIN',
    'x2',
    '3CH-BNDL-',
    'USED-'
  ];

  try {
    for (const segment of skuSegments) {
      finalSku = finalSku?.replace(segment, '');
    }
  } catch (e) {
    console.log('[Warning]: Unable to Properly Clean SKU (Probably OK): ', sku);
    console.log('[Err Msg]: ', e?.message);
    console.log('---- Lets proceed with the original SKU ----');
    return sku;
  }
  return finalSku?.trim();
}

export const cleanSkuExceptForUsedRefurbished = (sku) => {
  if (!sku) return;
  let finalSku = sku;
  const skuSegments = [
    // longest -> shortest 💣
    '3CH-BNDL-(2)_',
    '3CH-BNDL-(2) ',
    '-TEST',
    'DLR-',
    '-Good',
    '-FBAMAIN',
    '-FBA2',
    '-FBA',
    '-FBM3',
    '-FBM2',
    '-FBM',
    'FBA',
    'FBAMAIN',
    'MAIN',
    'x2',
    '3CH-BNDL-',
  ];
  try {
    for (const segment of skuSegments) {
      finalSku = finalSku?.replace(segment, '');
    }
  } catch (e) {
    console.log('[Warning]: Unable to Properly Clean SKU (Probably OK): ', sku);
    console.log('---- Lets proceed with the original SKU ----');
    return sku;
  }
  return finalSku?.trim();
};

export const getFirstPartOfHyphenatedSkuInSkuList = (skuList) => {
  if (!skuList?.length) {
    return [];
  }
  const skuPrefixMap = {};  // To hold SKU prefixes and their count
  const outputList = [];    // Final output list

  // Loop through SKU list
  for (let i = 0; i < skuList.length; i++) {
    const sku = skuList[i];
    const parts = sku.split('-');

    if (parts.length > 1) { // If SKU contains hyphen
      const prefix = parts[0];

      // Check if this prefix is already in the map
      if (skuPrefixMap[prefix]) {
        // If yes, increment its count
        skuPrefixMap[prefix]++;
      } else {
        // If no, add it to the map with count = 1
        skuPrefixMap[prefix] = 1;
      }
    } else {
      // SKU doesn't contain hyphen, include it as is in the output list
      outputList.push(sku);
    }
  }

  // Loop through SKU prefix map
  for (const [prefix, count] of Object.entries(skuPrefixMap)) {
    // If a prefix has count > 1, include just the prefix in the output list
    // If a prefix has count = 1, include the full SKU in the output list
    outputList.push(count > 1 ? prefix : `${prefix}-${skuList.find(sku => sku.startsWith(prefix)).split('-')[1]}`);
  }
  return outputList;
};

export const remapFord2015To2009StaticSkuList = (sku) => {
  sku = remapFord2015To2009StaticSkuMap?.[sku] ?? sku;
  return sku;
};

export const remapFord2009To2015FakeStaticSkuList = (sku) => {
  sku = remapFord2009To2015FakeStaticSkuMap?.[sku] ?? sku;
  return sku;
};

export const remapFord2015To2009StaticSkuMap = {
  // Never got a 2015-UP Version - Force Sold SKU Remap to 2009 Version
  'VS-2X10FORD-2015-UP-SUPERCREWCAB-AR': 'VS-2X10FORD-2009-UP-SUPERCREWCAB-AR',
  'VS-1X10FORD-2015-UP-SUPERCREWCAB-V-AR': 'VS-1X10FORD-2009-UP-SUPERCREWCAB-V-AR',
  'VS-1X12FORD-2015-UP-SUPERCREWCAB-V-AR': 'VS-1X12FORD-2009-UP-SUPERCREWCAB-V-AR',
  'VS-2X8FORD-2015-UP-SUPERCREWCAB-V-AR': 'VS-2X8FORD-2009-UP-SUPERCREWCAB-V-AR',
  'VS-2X10FORD-2015-UP-SUPERCREWCAB-V-AR': 'VS-2X10FORD-2009-UP-SUPERCREWCAB-V-AR',
  'VS-2X12FORD-2015-UP-SUPERCREWCAB-V-AR': 'VS-2X12FORD-2009-UP-SUPERCREWCAB-V-AR'
};

export const remapFord2009To2015FakeStaticSkuMap = {
  // Never got a 2015-UP Version - Force Sold SKU Remap to 2009 Version
  'VS-2X10FORD-2009-UP-SUPERCREWCAB-AR': 'VS-2X10FORD-2015-UP-SUPERCREWCAB-AR',
  'VS-1X10FORD-2009-UP-SUPERCREWCAB-V-AR': 'VS-1X10FORD-2015-UP-SUPERCREWCAB-V-AR',
  'VS-1X12FORD-2009-UP-SUPERCREWCAB-V-AR': 'VS-1X12FORD-2015-UP-SUPERCREWCAB-V-AR',
  'VS-2X8FORD-2009-UP-SUPERCREWCAB-V-AR': 'VS-2X8FORD-2015-UP-SUPERCREWCAB-V-AR',
  'VS-2X10FORD-2009-UP-SUPERCREWCAB-V-AR': 'VS-2X10FORD-2015-UP-SUPERCREWCAB-V-AR',
  'VS-2X12FORD-2009-UP-SUPERCREWCAB-V-AR': 'VS-2X12FORD-2015-UP-SUPERCREWCAB-V-AR'
};
