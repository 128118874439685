const DEFAULT_CLASSES =
  'block m-0 w-full items-center justify-center uppercase font-medium text-center px-6 py-2 rounded disabled:border-gray-300 disabled:bg-gray-300 disabled:cursor-not-allowed';

const VARIANT_CLASSES = {
  primary: 'text-white bg-gray-900 hover:bg-gray-800 active:bg-gray-700',
  secondary: 'bg-white hover:bg-gray-50 active:bg-gray-100 border border-black',
  preorder:
    'bg-orange-500 hover:bg-orange-600 active:bg-orange-400 ' +
    'border border-orange-600 text-white tracking-widest text-xl',
  preorder_tester: 'bg-sky-700 text-white tracking-widest text-xl',
  singleFocus: 'text-white bg-[#262a2b] hover:bg-[#454747] active:bg-[#454747] focus:bg-[#454747]'
};

export const CART_BUTTON_PRIMARY_CLASSES = `${DEFAULT_CLASSES} ${VARIANT_CLASSES?.primary}`;
export const BUTTON_SECONDARY_CLASSES = `${DEFAULT_CLASSES} ${VARIANT_CLASSES?.secondary}`;
export const CART_BUTTON_SINGLE_FOCUS_CLASSES = `${DEFAULT_CLASSES} ${VARIANT_CLASSES?.singleFocus}`;
export const BUTTON_PREORDER_CLASS = `${DEFAULT_CLASSES} ${VARIANT_CLASSES?.preorder}`;

export const BUTTON_PREORDER_TESTER = `${DEFAULT_CLASSES} ${VARIANT_CLASSES?.preorder_tester}`;
