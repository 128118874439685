import ReviewPhotoGrid from './review-photo-grid';
import ReviewPopupV2 from '@components/product/reviews/review-photos/review-popup-v2';
import {useState} from 'react';
import {useReviewPhotos} from './use-review-photos.jsx';
import {
  ReviewsLightboxCarouselMobile
} from '@components/product/reviews/review-photos/reviews-lightbox-carousel-mobile';

export const CustomerPhotosFromReviewsGrid = ({skuList, isMobile}) => {
  // console.log("[CustomerPhotosFromReviewsGrid] skuList:", skuList);
  const [currentReview, setCurrentReview] = useState();
  const [currentReviewIndex, setCurrentReviewIndex] = useState();
  const [currentReviewImageIndex, setCurrentReviewImageIndex] = useState();
  const [showAll, setShowAll] = useState();
  const [displayModal, setDisplayModal] = useState();
  const [displayModalMobile, setDisplayModalMobile] = useState();

  const {reviews, size, setSize, isReachingEnd, isLoadingMore} = useReviewPhotos({skuList});
  const first20Images = reviews?.flatMap((review) => {
    return review?.images?.slice(0, 2).map((image, index) => {
      return {
        image,
        image_index: index,
        review_id: review?.id,
        image_alt: `Review image #${index + 1} ${review?.reviewer?.name ? `from ${review?.reviewer?.name}` : ''}: ${review?.title}`
      };
    });
  })?.slice(0, 20);

  if (!first20Images?.length) return null;

  const showModal = isMobile ? displayModalMobile : displayModal;

  const prevReview = () => {
    const index = reviews?.findIndex((review) => review?.id === currentReview?.id);
    if (index > 0) {
      const newCurrentIndex = index - 1;
      setCurrentReviewImageIndex(0);
      setCurrentReview(reviews[newCurrentIndex]);
      setCurrentReviewIndex(newCurrentIndex);
    }
  };

  const nextReview = () => {
    const index = reviews?.findIndex((review) => review?.id === currentReview?.id);
    if (index < reviews?.length - 1) {
      const newCurrentIndex = index + 1;
      setCurrentReviewImageIndex(0);
      setCurrentReview(reviews[newCurrentIndex]);
      setCurrentReviewIndex(newCurrentIndex);
      if (!isReachingEnd && newCurrentIndex >= reviews?.length - 5) {
        nextPage();
      }
    }
  };

  const handleClickOnReviewPhotoOpenPopUpModal = (id, index) => {
    setCurrentReviewImageIndex(index || 0);
    const newCurrentIndex = reviews?.findIndex((review) => review?.id === id);
    setCurrentReview(reviews[newCurrentIndex]);
    setCurrentReviewIndex(newCurrentIndex);

    if (isMobile) {
      setDisplayModalMobile(true);
    } else {
      setDisplayModal(true);
    }
  };

  const handleViewAll = () => {
    setCurrentReviewImageIndex(0);
    setCurrentReview();
    setShowAll(true);

    if (isMobile) {
      setDisplayModalMobile(true);
    } else {
      setDisplayModal(true);
    }
  };

  const nextPage = () => {
    setSize(size + 1);
  };

  const close = () => {
    setCurrentReviewImageIndex(0);

    if (isMobile) {
      setDisplayModalMobile(false);
    } else {
      setDisplayModal(false);
    }
  };

  return (
    <div>
      <div className="flex items-center gap-4 mb-2">
        <h3 className="font-oswald text-lg md:text-2xl uppercase text-left">Customer Photos</h3>
        <div style={{paddingTop: '3px'}}>
          <button
            className="text-sm underline cursor-pointer"
            onClick={handleViewAll}
            aria-label="View All Photos">
            View All Photos
          </button>
        </div>
      </div>

      <div className="bg-white rounded-md border shadow-sm px-3 pt-3 pb-2 lg:px-4 lg:pt-4 lg:pb-0">
        {first20Images && (
          <ReviewPhotoGrid
            images={first20Images}
            skuList={skuList}
            onClickReviewPhoto={(val) => {
              handleClickOnReviewPhotoOpenPopUpModal(val);
            }}
            handleViewAll={handleViewAll}
          />
        )}
      </div>

      {isMobile && showModal && (
        <ReviewsLightboxCarouselMobile
          show={true}
          initialImageIndex={currentReviewImageIndex}
          reviewId={currentReview?.id}
          close={close}
          allReviews={reviews}
          handleLoadMore={nextPage}
          showAll={showAll}
          setShowAll={setShowAll}
          withPhotoGrid={true}
          isReachingEnd={isReachingEnd}
        />
      )}

      {!isMobile && showModal && (showAll || currentReview) && (
        <ReviewPopupV2
          currentReview={currentReview}
          showAll={showAll}
          prevReview={prevReview}
          nextReview={nextReview}
          currentReviewImageIndex={currentReviewImageIndex}
          show={showModal}
          close={close}
          handleViewAll={handleViewAll}
          onClickReviewPhoto={handleClickOnReviewPhotoOpenPopUpModal}
          nextPage={nextPage}
          isLoadingMore={isLoadingMore}
          reviews={reviews}
          isReachingEnd={isReachingEnd}
          isFirst={currentReviewIndex === 0}
          isLast={currentReviewIndex === reviews?.length - 1 && isReachingEnd}
        />
      )}
    </div>
  );
};
