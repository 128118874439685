import s from './SidebarLayout.module.css';
import {ChevronLeft, Cross} from '@components/widgets/Icons';
import cn from 'clsx';


export const SidebarLayout = ({children, className, handleBack, handleClose, hideHeader, ...otherProps}) => {
    return (
        <div className={cn(s.root, className)} {...otherProps}>
            {!hideHeader && (
                <header className={s.header}>
                    {handleClose && (
                        <button onClick={handleClose} aria-label='CloseXButton'
                                className='hover:text-accent-5 transition ease-in-out duration-150 flex items-center focus:outline-none mr-6'>
                            <Cross className='h-6 w-6 hover:text-accent-3'/>
                            <span className='ml-2 text-accent-7 text-sm '>CloseXButton</span>
                        </button>
                    )}
                    {handleBack && (
                        <button onClick={handleBack} aria-label='Go back'
                                className='hover:text-accent-5 transition ease-in-out duration-150 flex items-center focus:outline-none'>
                            <ChevronLeft className='h-6 w-6 hover:text-accent-3'/>
                            <span className='ml-2 text-accent-7 text-xs'>Back</span>
                        </button>
                    )}
                </header>
            )}
            <div className={s.container}>{children}</div>
        </div>
    );
};
