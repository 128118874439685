import Head from 'next/head';
import {
  GenerateOpenGraphPageMetaData,
  GenerateStaticPageMetaData
} from '@components/seo/MetaDataDefaults';

export const ContactUsSeo = () => {
  const title = 'Contact Us';
  const canonicalUrl = 'https://www.skaraudio.com/pages/contact-us'
  const description = 'Need help? Let us know how we can help! Contact us by email at support@skaraudio.com, or by phone at (888) 501-7527.';
  const keywords = 'contact us';

  return (
    <>
      <Head>
        <title>{title}</title>

        <meta charSet="UTF-8"/>
        <meta name="keywords" content={keywords}/>
        <meta name="description" content={description}/>
        <link rel="canonical" href={canonicalUrl}/>

        <GenerateStaticPageMetaData/>
        <GenerateOpenGraphPageMetaData
          title={title}
          description={description}
          url={canonicalUrl}
        />
      </Head>
    </>
  )
}
