export const addCartItemInLineItems = (existingLineItems = [], addedItem) => {
  const isCartEmpty = !(existingLineItems?.length > 0);
  if (isCartEmpty) {
    return [addedItem];
  }

  const matchingExistingLineItem = existingLineItems?.find((existingItem) => existingItem.sku === addedItem.sku);

  if (matchingExistingLineItem) {
    matchingExistingLineItem.quantity += addedItem.quantity;
    return [...existingLineItems];
  }

  return [...existingLineItems, addedItem];
};

export const addMultipleCartItemsInLineItems = (existingLineItems = [], newItems = []) => {
  const isCartEmpty = !(existingLineItems?.length > 0);
  if (isCartEmpty) {
    return [...newItems];
  }

  const updatedCart = existingLineItems?.map((existingItem) => {
    const matchingNewItem = newItems?.find((newItem) => newItem.sku === existingItem.sku);
    if (matchingNewItem) {
      return {
        ...existingItem, quantity: existingItem.quantity + matchingNewItem.quantity
      };
    }
    return existingItem;
  });

  const newItemsToAdd = newItems?.filter((newItem) => !existingLineItems?.some((existingItem) => existingItem.sku === newItem.sku));

  return [...updatedCart, ...newItemsToAdd];
};

export const updateCartItemInLineItems = (existingLineItems = [], updatedItem) => {
  // console.log('[updateCartItemInLineItems][existingLineItems]:', { existingLineItems });
  // console.log('[updateCartItemInLineItems][updatedItem]:', { updatedItem });
  let newCart;
  let isCartEmpty = !(existingLineItems?.length > 0);
  let isCartNotEmpty = !isCartEmpty;

  if (isCartNotEmpty) {
    // Find if the updated item exists in the existing items by SKU
    const matchingExistingLineItem = existingLineItems?.find(
      (existingItem) => existingItem.sku === updatedItem.sku
    );

    if (matchingExistingLineItem) {
      // If the item already exists, update the quantity
      matchingExistingLineItem.quantity = Number(updatedItem.quantity);

      // Remove the 'metafields' property if it exists under 'variant'
      if (matchingExistingLineItem.variant) {
        delete matchingExistingLineItem.variant.metafields;
      }

      // Create a new array with updated item
      newCart = existingLineItems.map((existingItem) => {
        return existingItem.sku === updatedItem.sku ? matchingExistingLineItem : existingItem;
      });
    } else {
      // If the item doesn't exist, add it to the new array
      newCart = [...existingLineItems, updatedItem];
    }
  } else {
    // If the cart was empty, initialize it with the updated item
    newCart = [updatedItem];
  }
  // console.log('[updateCartItemInLineItems][newCart]:', {newCart});
  return newCart;
};

export const deleteItemFromLineItems = (cartItems, deletedSku) => {
  // console.log("[deleteItemFromLineItems][lineItems]:", {cartItems});
  const remainingCartItems = cartItems?.filter((cartItem) => cartItem.sku !== deletedSku);
  return remainingCartItems;
};
