import styles from './Sidebar.module.css';
import {Dialog} from '@headlessui/react';
import {useEffect, useRef, useState} from 'react';
import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';

export const Sidebar = ({children, handleClose, isOpen}) => {
  const [sidebarHovered, setSidebarHovered] = useState(false);
  const sidebarRef = useRef(null);
  const contentRef = useRef(null);

  const onKeyDownSidebar = ({event}) => {
    // console.log({event: event.code})
    if (event?.code === 'Escape') {
      handleClose();
    }
  };

  useEffect(() => {
    if (sidebarRef?.current) {
      sidebarRef?.current.focus();
    }

    const contentElement = contentRef?.current;

    if (contentElement) {
      disableBodyScroll(contentElement, {reserveScrollBarGap: true});
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const handleBackdropMouseEnter = () => {
    if (sidebarHovered) {
      handleClose();
      setSidebarHovered(false);
    }
  };

  const handleCartMouseEnter = () => {
    setSidebarHovered(true);
  };

  const handleOverlayCartButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return true;
  };

  return (
    <Dialog open={isOpen} onClose={() => null}>
      <Dialog.Panel>
        <Dialog.Title>Your Cart</Dialog.Title>
        <div className={styles.root} ref={sidebarRef} onKeyDown={onKeyDownSidebar}>
          <div
            className='absolute h-12 w-14 top-0 right-2 z-20 hidden md:block'
            onClick={handleOverlayCartButtonClick}></div>
          <div className='absolute inset-0 overflow-hidden'>
            <div
              className={styles.backdrop}
              onClick={handleClose}
              onMouseEnter={handleBackdropMouseEnter}
            />
            <section className={styles.cart} onMouseEnter={handleCartMouseEnter}>
              <div className='h-full w-full md:w-screen md:max-w-md lg:max-w-lg'>
                <div className={styles.sidebar} ref={contentRef}>
                  {children}
                </div>
              </div>
            </section>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};
