import Head from 'next/head';
import {
  defaultSeoDescription,
  defaultSeoImage,
  defaultSeoTitle,
  GenerateOpenGraphPageMetaData,
  GenerateStaticPageMetaData
} from '@components/seo/MetaDataDefaults';

export const DynamicPageSEO = ({title, description, canonicalUrl, image}) => {
  title = title ?? defaultSeoTitle;
  description = description ?? defaultSeoDescription;
  image = image ?? defaultSeoImage;

  return <>
    <Head>
      <title>{title}</title>
      <meta charSet="UTF-8"/>
      <meta name="description" content={description}/>
      <link rel="canonical" href={canonicalUrl}/>

      <GenerateStaticPageMetaData/>
      <GenerateOpenGraphPageMetaData title={title} url={canonicalUrl} description={description} image={image}/>
    </Head>
  </>
}
