import cn from 'clsx';
import {useMobileOrTablet} from '@helpers/hooks/useWindow';
import {HStack} from '@components/widgets/Common';
import {ChevronDown, ChevronUp} from '@components/widgets/Icons';

export const UiContainer = ({children, className, el = 'div', clean = false}) => {
  const rootClassName = cn(className, {
    'mx-auto max-w-7xl px-2 lg:px-4 w-full': !clean
  });
  let Component = el;
  return <Component className={rootClassName}>{children}</Component>;
};

export const DesktopGradientLine = () => {
  return (
    <div
      style={{
        background: 'linear-gradient(90deg, #26292B 52.96%, rgba(50, 54, 58, 0) 90.63%)',
        height: 3,
        width: '100%'
      }}
    />
  );
};

export const MobileGradientLine = () => {
  return (
    <div
      style={{
        background: 'linear-gradient(90deg, #26292B 52.96%, rgba(50, 54, 58, 0) 90.63%)',
        height: 1,
        width: '100%'
      }}
    />
  );
};

export const Hamburger = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z'
        fill='currentColor'
        fillRule='evenodd'
        clipRule='evenodd'></path>
    </svg>
  );
};

export const OpenCloseIcon = ({isOpen}) => {
  const isMobileOrTablet = useMobileOrTablet();
  const isDesktop = isMobileOrTablet === false;

  if (isOpen) {
    return (
      <HStack>
        <ChevronUp />
        {isDesktop && <span>Close</span>}
      </HStack>
    );
  }

  return (
    <HStack>
      <ChevronDown />
      {isDesktop && <span>Open</span>}
    </HStack>
  );
};

export const CloseIcon = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z'
        fill='currentColor'
        fillRule='evenodd'
        clipRule='evenodd'></path>
    </svg>
  );
};
export const SearchIcon = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z'
        fill='currentColor'
        fillRule='evenodd'
        clipRule='evenodd'></path>
    </svg>
  );
};

export const CartIcon = () => {
  return (
    <svg viewBox='0 0 29.239 27.007'>
      <g fill={'currentColor'}>
        <path d='M9.556 25.32a1.688 1.688 0 1 1-1.687-1.688 1.688 1.688 0 0 1 1.688 1.688Z' />
        <path d='M25.341 25.32a1.687 1.687 0 1 1-1.687-1.688 1.687 1.687 0 0 1 1.687 1.688Z' />
        <path d='M29.229 4.807a.413.413 0 0 0-.366-.3l-22.9-2.215a.7.7 0 0 1-.527-.33A7.16 7.16 0 0 0 4.578.654C4.037-.007 3.017.014 1.147 0A1.024 1.024 0 0 0 .001.991a1 1 0 0 0 1.1.991 9.342 9.342 0 0 1 1.828.134c.33.1.6.64.7 1.111a.026.026 0 0 0 .007.021c.014.084.141.717.141.724l2.804 14.879a5.468 5.468 0 0 0 1.02 2.51A2.806 2.806 0 0 0 9.918 22.5H26.55a1 1 0 0 0 1.013-.942.98.98 0 0 0-.984-1.027H9.9a.817.817 0 0 1-.584-.2 3.155 3.155 0 0 1-.808-1.824l-.3-1.666a.039.039 0 0 1 .028-.042l19.526-3.3a.411.411 0 0 0 .345-.366l1.125-8.142a.4.4 0 0 0-.003-.184Z' />
      </g>
    </svg>
  );
};
export const GradientHorizontalLine = () => {
  return (
    <div
      style={{
        background: 'linear-gradient(90deg, #26292B 52.96%, rgba(50, 54, 58, 0) 90.63%)',
        height: 1,
        width: '100%'
      }}
    />
  );
};

export const Arrow = ({children, disabled, onClick}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      aria-label='Navigation'
      style={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        right: '1%',
        opacity: disabled ? '0' : '1',
        userSelect: 'none'
      }}>
      {children}
    </button>
  );
};

export const NiceBorderAround = ({className, header, children, style, disableBorder}) => {
  if (disableBorder) {
    return <>{children}</>;
  }

  return (
    <div
      className={cn(className, 'w-auto')}
      style={{
        border: '1px solid rgba(0,0,0,0.15)',
        borderRadius: '0.4em',
        boxShadow: '0 .125rem .25rem rgba(0,0,0,.15)',
        ...style
      }}>
      {header && <h3>{header}</h3>}
      <div className="p-2 p-md-3">{children}</div>
    </div>
  );
};
