import {
  BUTTON_PREORDER_TESTER,
  CART_BUTTON_PRIMARY_CLASSES
} from '@components/cart/CartButtonClasses';
import cn from 'clsx';
import {AddToCartButton} from '@components/product/global/AddToCart';
import {useState} from 'react';
import {usDate} from '@helpers/common/date.mjs';
import {useIsMobile} from '@helpers/hooks/useWindow';
import {
  SingleProductFocusProductQuantity
} from '@components/product/ProductPageTemplates/Components/SingleProductFocusWithAssets/SingleProductFocusQuantity';

export const OutOfStockRouter = ({product, withQuantity, className}) => {
  // console.log('[OutOfStockRouter] product', product);
  let preorderInfo = product?.pre_order_info ?? product?.preorderInfo;
  let isInStock = product?.isInStock === true ?? product?.currentlyInStock === true;

  let isPreOrderEnabled = preorderInfo?.is_pre_order_enabled;
  let isPreOrderEligible = preorderInfo?.is_pre_order_eligible;
  let isPreorder = !isInStock && isPreOrderEnabled && isPreOrderEligible;

  return isPreorder ? (
    <OutOfStockButton
      product={product}
      withQuantity={withQuantity}
      className={className}
      preorderInfo={preorderInfo}
    />
  ) : (
    <Sorry product={product} preorderInfo={preorderInfo} />
  );
};

export function OutOfStockButton({product, withQuantity, className, preorderInfo}) {
  const [quantity, setQuantity] = useState(1);
  const selectedVariant = product?.selectedVariant ?? product;
  // console.log({selectedVariant, preorderInfo});
  // console.log({OutOfStockButton_preorderInfo: preorderInfo})

  const isPreOrderEnabled = !!preorderInfo?.is_pre_order_enabled ?? false;

  return (
    <div className='mt-3 space-y-2 mb-3'>
      <div className={cn('flex gap-2', className)}>
        {withQuantity && (
          <SingleProductFocusProductQuantity quantity={quantity} setQuantity={setQuantity} />
        )}

        {isPreOrderEnabled && (
          <AddToCartButton
            selectedVariant={selectedVariant}
            className={BUTTON_PREORDER_TESTER}
            quantity={quantity}
            icon={'🛒'}>
            Pre-order
          </AddToCartButton>
        )}

        {!isPreOrderEnabled && (
          <AddToCartButton
            selectedVariant={selectedVariant}
            className={CART_BUTTON_PRIMARY_CLASSES}
            quantity={quantity}
            disabled>
            Sold Out
          </AddToCartButton>
        )}
      </div>

      <PreOrderDisplayEstimatedInStockDate preorderInfo={preorderInfo} />
    </div>
  );
}

export const PreOrderDisplayEstimatedInStockDate = ({preorderInfo}) => {
  // console.log("[PreOrderDisplayEstimatedInStockDate] preorderInfo", preorderInfo);
  const isMobile = useIsMobile();

  const manualOverrideEtaDate = preorderInfo?.manual_override_eta_date;
  const inStockEtaDate = preorderInfo?.in_stock_eta_date;

  let shipOnDate = manualOverrideEtaDate ?? inStockEtaDate;
  shipOnDate = shipOnDate ? usDate(shipOnDate) : null;

  let mainClassName, spanStyling, paragraphStyling;
  if (isMobile) {
    mainClassName = 'border rounded-md yellow-notice-bg font-oswald p-0.5 mx-6';
    spanStyling = {color: 'rgb(3 105 161)'};
    paragraphStyling = {fontSize: '14px'}; // Mobile font size
  } else {
    mainClassName = 'border rounded-md yellow-notice-bg font-oswald p-0.5 mx-12';
    spanStyling = {color: 'rgb(3 105 161)'};
    paragraphStyling = {fontSize: '17px'}; // Desktop font size
  }

  return (
    <div>
      {shipOnDate && (
        <div className={mainClassName}>
          <p className='text-black text-center' style={paragraphStyling}>
            Estimated Pre-order Ship Date: <span style={spanStyling}>{shipOnDate}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export const Sorry = ({product, preorderInfo}) => {
  return (
    <div className='mt-3 space-y-2 mb-3'>
      <div className='flex'>
        <div />
        <AddToCartButton
          product={product}
          className={CART_BUTTON_PRIMARY_CLASSES}
          disabled={true}
          style={{margin: 0}}
          preorderInfo={preorderInfo}>
          Out of Stock
        </AddToCartButton>
      </div>
      <PreOrderDisplayEstimatedInStockDate preorderInfo={preorderInfo} />
    </div>
  );
};
