import useSWRInfinite from 'swr/infinite';
import {fetcher} from '@helpers/common/fetch.mjs';
import {usePathname} from 'next/navigation';

const PAGE_SIZE = 25;

export const useReviewPhotos = ({skuList}) => {
  const pathName = usePathname();
  const skuListString = skuList.join(',');

  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData?.length) return null;
    return `/api/reviews/with-images?skus=${skuListString}&page=${pageIndex}&limit=${PAGE_SIZE}`;
  };

  const {data, error, mutate, size, setSize, isValidating} = useSWRInfinite(getKey, (url) => {
    // console.log('[useReviewPhotos] url:', url, '[pathName]:', pathName);
      return fetcher(url, {}, pathName);
    }, {
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );
  // console.log('[useReviewPhotos] data:', data);

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData || (size > 0 && data && typeof data?.[size - 1] === 'undefined');
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd = isEmpty || (data && data?.[data?.length - 1]?.length < PAGE_SIZE);

  const reviews = data?.[0]?.reviews?.length ? [].concat(...data?.[0]?.reviews) : [];
  // console.log('[useReviewPhotos] reviews:', reviews);

  return {
    reviews,
    error,
    isLoadingMore,
    isEmpty,
    size,
    setSize,
    mutate,
    isReachingEnd
  };
};
