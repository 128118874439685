export const generateJsonLdForCollectionPage = (products) => {
  // console.dir({generateJsonLdForCollectionPage_products: products}, {depth: 2});
  // Generate JSON-LD data
  const itemListElementsJsonLd = generateCollectionPageItemListJsonLdData(products);

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: itemListElementsJsonLd
  };
  // console.dir({jsonLd}, {depth: null});
  return jsonLd;
};

export const generateCollectionPageItemListJsonLdData = (collectionProducts) => {
  // Re: Google Docs: 'If this is a summary page, include only the type, position, and url properties in the ListItem.'
  // 'https://developers.google.com/search/docs/appearance/structured-data/carousel#all-in-one'
  const baseUrl = 'https://www.skaraudio.com';
  const itemListElements = collectionProducts?.map((product, index) => {
    // console.dir({generateCollectionPageItemListJsonLdData_product: product}, {depth: 3})

    const siteHandle = product?.site_handle ?? product?.slug;
    // console.log('[generateCollectionPageItemListJsonLdData] productHandle:', productHandle);
    const position = 1 + index;
    const listItem = {
      '@type': 'ListItem',
      position: position,
      url: `${baseUrl}/products/${siteHandle}`
    };
    const productName = product?.name ?? product?.site_name;
    if (productName) {
      listItem.name = productName;
    }
    const prodImageUrl = product?.image_1 ? product?.image_1 : product?.imageUrl ? `${baseUrl}${product?.imageUrl}` : null;
    if (prodImageUrl) {
      listItem.image = prodImageUrl;
    }
    return listItem;
  });

  return itemListElements;
};
