import {DateTime} from 'luxon';
import util from 'util';
import {
  processStdoutClearLine,
  processStdoutCursorTo,
  processStdoutWrite
} from './console-log-colors.mjs';
import {capitalizeFirstLetter} from '../../helpers/common/format/format-name.mjs';

export const isDaylightSavingsTime = (date) => {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);
  const stdTimezoneOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());

  return date.getTimezoneOffset() < stdTimezoneOffset;
};

export const getDateTimeAsStringForFileNameMmHhSs = () => {
  let timeHHMMSS = DateTime.now().toISOTime();
  timeHHMMSS = timeHHMMSS?.replaceAll(':', '.');
  timeHHMMSS = timeHHMMSS?.substring(0, 8);
  // console.log({timeHHMMSS})

  let date = convertJsDateToShortDate();
  let combinedDateAndTime = date + '_' + timeHHMMSS;
  return combinedDateAndTime;
};

export function getDateTime(iso) {
  const date = typeof iso === 'string' ? DateTime.fromISO(iso) : DateTime.fromJSDate(iso);
  return date;
}

export const getEpochTime = () => {
  return Math.floor(new Date().getTime() / 1000);
};

export const convertJsDateToShortDate = (jsDate = null) => {
  if (!jsDate) {
    jsDate = new Date();
  }
  let date = DateTime.fromJSDate(jsDate).toISODate();
  // console.log({jsDate, date})
  return date;
};

export function usDate(iso) {
  const date = new Date(iso);
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'America/New_York'
  };
  return date.toLocaleDateString('en-US', options);
}

// June 06, 2022
export const longDate = (iso) => {
  const date = new Date(iso);
  const options = {year: 'numeric', month: 'long', day: 'numeric', timeZone: 'America/New_York'};
  return date.toLocaleDateString('en-US', options);
};

// Monday, June 17, 2024
export const longDateWithDay = (iso) => {
  const date = new Date(iso);
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'America/New_York'
  };
  return date.toLocaleDateString('en-US', options);
};

// Apr 22, 2024
export const shortDateWithYear = (iso) => {
  const date = new Date(iso);
  const options = {year: 'numeric', month: 'short', day: 'numeric', timeZone: 'America/New_York'};
  return date.toLocaleDateString('en-US', options);
};

// June 06
export function shortDate(iso) {
  try {
    if (iso.toString().endsWith('T00:00:00.000Z')) {
      const date = new Date(iso);
      const options = {month: 'long', day: 'numeric', timeZone: 'UTC'};
      const shortDate = new Intl.DateTimeFormat('en-US', options).format(date);
      return shortDate;
    }
    const date = new Date(iso);
    const options = {month: 'long', day: 'numeric', timeZone: 'America/New_York'};
    return date.toLocaleDateString('en-US', options);
  } catch (e) {
    console.log('Error in shortDate', e);
    return '';
  }
}

// June 06, 2022
export const longDateWithTime = (iso) => {
  const date = new Date(iso);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZone: 'America/New_York'
  };
  return date.toLocaleDateString('en-US', options);
};

export const niceDate = (dateLike) => {
  if (!dateLike) {
    dateLike = new Date();
  }
  const date = new Date(dateLike);
  return date.toLocaleString('en-US', {
    dateStyle: 'medium'
  });
};


export function niceRelativeDateTimeInNY(iso) {
  // console.log({niceRelativeDateTimeInNY: iso});
  if (!iso) {
    return iso;
  }
  let isoTill19 = iso?.substring(0, 19);
  let date = typeof iso === 'string' ? DateTime.fromISO(isoTill19, {zone: 'America/New_York'}) : DateTime.fromJSDate(iso, {zone: 'America/New_York'});

  const d = resetTime(new Date(date || Date.now()));
  const now = resetTime(new Date());
  let dateTime = DateTime.fromMillis(d.getTime());
  dateTime = dateTime.setZone('America/New_York');

  // show relative date (e.g. today, yesterday, friday, etc) up to 7 days away
  const max_days_written_off = 7;
  const diff = dateTime.diff(DateTime.fromMillis(now.getTime()), 'days');
  if (diff.days >= -max_days_written_off && diff.days <= max_days_written_off) {
    const base = DateTime.fromMillis(1642201200000);
    let relative = base.plus({days: diff.days}).toRelativeCalendar({base});
    const time = date.toLocaleString({hour: 'numeric', minute: '2-digit'});

    // if not today or yesterday, show day of week instead of how many days ago
    if (diff.days < -1) {
      relative = base.plus({days: diff.days}).toLocaleString({weekday: 'long'});
    }

    return `${capitalizeFirstLetter(relative)} at ${time}`;
  }

  // if same year, don't add year
  if (DateTime.now().hasSame(date, 'year')) {
    return date.toLocaleString({
      month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit'
    });
  } else {
    return date.toLocaleString({
      year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit'
    });
  }
}

export const niceRelativeDateInNY = (iso) => {
  if (!iso) {
    return iso;
  }

  // Extract only the date part from the ISO string
  let isoDate = iso.substring(0, 10);
  let date = typeof iso === 'string' ? DateTime.fromISO(isoDate, {zone: 'America/New_York'}) : DateTime.fromJSDate(iso, {zone: 'America/New_York'});

  const now = DateTime.now().setZone('America/New_York').startOf('day');
  date = date.startOf('day');

  const diff = date.diff(now, 'days').days;

  // Show relative date up to 7 days away
  const max_days_written_off = 7;
  if (diff >= -max_days_written_off && diff <= max_days_written_off) {
    let relative = date.toRelativeCalendar({base: now});

    // If not today or yesterday, show day of the week instead of how many days ago
    if (diff < -1) {
      relative = date.toLocaleString({weekday: 'long'});
    }

    return capitalizeFirstLetter(relative);
  }

  return date.toLocaleString({year: 'numeric', month: 'long', day: 'numeric'});
};


export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function sleepMs(waitTime = 1000) {
  return new Promise((res) => setTimeout(res, waitTime));
}

export const resetTime = (date) => {
  const dt = DateTime.fromJSDate(date, {zone: 'America/New_York'});
  return dt.startOf('day').toJSDate();
};

export async function sleepCounter(seconds) {
  return new Promise(async (resolve) => {
    while (seconds > 0) {
      processStdoutClearLine();
      processStdoutCursorTo(0);
      let message = util.inspect(['⌛ Waiting', seconds, 'seconds'].join(' '));
      processStdoutWrite(message);
      await sleep(1);
      seconds--;
    }
    processStdoutClearLine();
    processStdoutWrite('\n'); // end the line
    resolve();
  });
}

export function sleep(seconds) {
  if (isNaN(seconds)) {
    console.log('Provided value for seconds to sleep, should be a valid number.');
    return;
  }
  return new Promise((r) => setTimeout(() => r(), (Number(seconds) * 1000) | 0));
}

export function getNowForSQL() {
  return DateTime.now()
    .setZone('America/New_York')
    .toSQL({includeOffset: false, includeZone: false});
}

export const convertEpochTimeToNormalTime = (epochTimeStamp) => {
  if (!epochTimeStamp) return null;
  const reformattedTimeStamp = new Date(epochTimeStamp * 1000);
  return new Date(reformattedTimeStamp).toISOString().substring(0, 19);
};

export function addDays(date, days) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const getTimeStampInMicroSeconds = () => {
  return Number(process.hrtime.bigint()) / 1000;
};

export const addDaysToDate = (dateInput, daysToAdd) => {
  try {
    const date = new Date(dateInput);
    if (isNaN(date)) {
      throw new Error('Invalid date format');
    }
    date.setDate(date.getDate() + daysToAdd);
    return date.toISOString();
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const formatTimestamp = (date) => {
  if(!date) return null

  if(typeof date === 'string') {
    date = new Date(date)
  }

  return date?.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit'
  });

}
