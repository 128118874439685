import Image from 'next/image';
import Link from 'next/link';
import {useMemo} from 'react';
import {remapFord2009To2015FakeStaticSkuList} from '@helpers/common/sku.mjs';
import {ReviewSection} from '@components/accounts/ui/AccountOrderReview';

export const AccountOrdersBlockOrderItem = ({
                                              item,
                                              showReviewButton = false,
                                              disableLinkProdImage = false,
                                              disableLinkProduct = false
                                            }) => {
  const sku = item?.sku;
  const title = item?.title;
  const quantity = item?.quantity;
  const product = item?.product;

  const imageSrc = item?.imageUrl || item?.image_url || item?.image;

  const productTitle = title || sku;
  const productHandle = product?.handle ?? item?.handle;
  const placeholderImg = '/product-img-placeholder.svg';

  const reviewRating = item?.review_rating;
  const reviewTitle = item?.review_title;
  const reviewBody = item?.review_body;

  const showSku = useMemo(() => remapFord2009To2015FakeStaticSkuList(sku), [sku]);

  return (
    <div className="flex items-start gap-4 mb-4 last:mb-0">
      <ProductImage
        productHandle={productHandle}
        imageSrc={imageSrc}
        placeholderImg={placeholderImg}
        quantity={quantity}
        disableLink={disableLinkProdImage}
      />

      <ProductDetails
        productHandle={productHandle}
        productTitle={productTitle}
        showSku={showSku}
        item={item}
        showReviewButton={showReviewButton}
        reviewRating={reviewRating}
        reviewTitle={reviewTitle}
        reviewBody={reviewBody}
        disableLinkProduct={disableLinkProduct}
      />
    </div>
  );
};

const ProductImage = ({productHandle, imageSrc, placeholderImg, quantity, disableLink = false}) => (
  <div className="relative z-0 aspect-square">
    {productHandle && !disableLink && (
      <Link href={`/products/${productHandle}`} className="text-skar-blue block overflow-hidden">
        <Image src={imageSrc || placeholderImg} alt="Product Image" height={80} width={80} />
      </Link>
    )}

    {productHandle && disableLink && (
      <Image src={imageSrc || placeholderImg} alt="Product Image" height={80} width={80} />
    )}

    {!productHandle && (
      <div className="h-full overflow-hidden">
        <Image src={imageSrc || placeholderImg} alt="Product Image" height={80} width={80} />
      </div>
    )}

    {quantity > 1 && (
      <div
        className="absolute top-0 -right-2 mb-0 flex items-center justify-center w-6 h-6 leading-none
        rounded-full bg-skar-blue text-white text-center text-sm select-none pointer-events-none">
        {quantity}
      </div>
    )}
  </div>
);

const ProductDetails = ({
                          productHandle,
                          productTitle,
                          showSku,
                          item,
                          showReviewButton,
                          reviewRating,
                          reviewTitle,
                          reviewBody,
                          disableLinkProduct = false
                        }) => (
  <div className="w-9/12 lg:w-8/12">
    <div className="lg:pt-3 text-[14px] leading-5">
      {productHandle && !disableLinkProduct ? (
        <Link href={`/products/${productHandle}`} className="text-gray-950 hover:underline">
          {productTitle}
        </Link>
      ) : (
        <div>{productTitle}</div>
      )}
      <div className="text-xs mt-2">
        <strong>SKU:</strong> {showSku}
      </div>

      <ReviewSection
        item={item}
        showReviewButton={showReviewButton}
        reviewRating={reviewRating}
        reviewTitle={reviewTitle}
        reviewBody={reviewBody}
      />
    </div>
  </div>
);

