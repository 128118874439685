import cn from 'clsx';
import Link from 'next/link';
import {ChevronRight, OnlyDesktop, OnlyMobile, Spinner} from '@components/widgets/Common';
import {useState} from 'react';
import {ChevronDown} from '@components/widgets/Icons';
import {MdChevronLeft} from '@assets/react-icons/md';

export const AccountPageTitle = ({title, actionTitle, actionHandle, className}) => {
  return (
    <div className={cn('flex gap-4 items-center', className)}>
      <h1 className="text-xl uppercase font-oswald">{title}</h1>

      <button
        className="underline cursor-pointer"
        {...(actionHandle ? {onClick: actionHandle} : {})}>
        {actionTitle}
      </button>
    </div>
  );
};

export const AccountBadge = ({children}) => {
  return (
    <span
      className="rounded text-skar-green px-0 md:py-1 text-xs"
      style={{fontSize: '12px', whiteSpace: 'nowrap'}}>
      {children}
    </span>
  );
};

export default function AccountButton({
                                        href,
                                        onClick,
                                        bold,
                                        outline,
                                        uppercase,
                                        block,
                                        blank,
                                        red,
                                        yellow,
                                        blue,
                                        danger,
                                        submitting,
                                        children,
                                        disabled,
                                        shallow,
                                        small
                                      }) {
  const classes = cn(
    {
      'font-bold': bold,
      'bg-neutral-50': outline,
      'hover:bg-neutral-100': outline && !disabled,
      'bg-skar-yellow': yellow,
      'bg-skar-red': red,
      'text-white': red || yellow || blue || !outline,
      'bg-skar-blue': blue,
      'hover:bg-skar-blue-dark': blue && !disabled,
      'bg-neutral-700': !outline && !danger,
      'hover:bg-neutral-600': !outline && !danger && !disabled,
      'border border-neutral-300': outline,
      uppercase: uppercase,
      'inline-block': !block,
      block: block,
      'bg-rose-600': danger,
      'bg-gray-400': disabled,
      'cursor-pointer': !disabled,
      'text-xs': small
    },
    'shadow-xs text-black px-4 py-1 md:py-1 ' +
    'rounded-md font-medium text-sm transition-all duration-300'
  );

  if (href) {
    return (
      <Link
        href={href}
        {...(blank ? {target: '_blank'} : {})}
        {...(shallow ? {shallow: true} : {})}>
        <div className={classes}>
          <div className="flex text-center justify-center items-center gap-2">
            {submitting && (
              <div>
                <Spinner size={14} />
              </div>
            )}
            {children}
          </div>
        </div>
      </Link>
    );
  } else {
    return (
      <div
        className={classes}
        {...(onClick
          ? {
            onClick: disabled ? () => {
            } : onClick
          }
          : {})}>
        <div className="flex text-center justify-center items-center gap-2">
          {submitting && (
            <div>
              <Spinner size={14} />
            </div>
          )}
          {children}
        </div>
      </div>
    );
  }
}

export const AccountBox = ({title, defaultOpen, children, className}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <OnlyMobile>
        <button
          onClick={toggleOpen}
          className={cn(
            'border bg-neutral-50 w-full h-8 px-2 flex text-black',
            'justify-between items-center text-md',
            {'rounded-md': !isOpen},
            {'rounded-t': isOpen}
          )}>
          <span className="text-sm">{title}</span>
          <ChevronDown className={`${isOpen ? 'rotate-180 transform' : ''} h-4 w-4`} />
        </button>

        {isOpen && (
          <div
            className="py-2 px-3 border border-1 border-t-0 rounded-b transition-all duration-300 ease-out">
            {children}
          </div>
        )}
      </OnlyMobile>

      <OnlyDesktop className={className}>
        <div className="border w-full">
          {title && <div className="bg-neutral-100 py-1.5 px-3 text-[16px] font-bold">{title}</div>}
          <div className="px-2 py-2">{children}</div>
        </div>
      </OnlyDesktop>
    </>
  );
};

export const AccountBackButton = ({title = 'Return To Account', link = '/account'}) => {
  return (
    <OnlyMobile>
      <div className="mb-4">
        <Link
          href={link}
          shallow={true}
          className="inline-flex underline -ml-1 uppercase font-oswald">
          <div className={'mt-0.5 -mr-0.5'}>
            <MdChevronLeft size={22} />
          </div>
          <span>{title}</span>
        </Link>
      </div>
    </OnlyMobile>
  );
};

export const AccountPageBreadcrumbs = ({breadcrumbs}) => {
  return (
    <nav className="flex mb-1 md:my-2 h-6" aria-label="Breadcrumb">
      <ol className="inline-flex items-center md:space-x-0 rtl:space-x-reverse">
        {breadcrumbs.map((breadcrumb, index) => {
          const isLast = index === breadcrumbs?.length - 1;

          return (
            <li key={index} className="inline-flex items-center">
              {index > 0 && <ChevronRight size={16} />}
              {isLast && (
                <span className="text-[14px] font-bold text-gray-500 md:ms-1 first:ms-0">
                  {breadcrumb.label}
                </span>
              )}
              {!isLast && (
                <Link
                  href={breadcrumb?.href}
                  shallow={true}
                  onClick={breadcrumb?.onClick}
                  className="text-[14px] inline-flex items-center font-medium text-gray-700 hover:text-blue-600">
                  {breadcrumb?.label}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
export const OrderHeaderItem = ({label, value, className}) => (
  <div className={`text-xs md:text-sm ${className ? className : ''}`}>
    <div className="block leading-2">
      <span className="mr-3">
        <strong>{label}</strong>
      </span>
    </div>
    <div>{value}</div>
  </div>
);
