export const disclaimerTextNoSubsIncluded = 'Enclosure sold empty (images with subwoofers for illustration purposes only)';

export const skuIsLoadedOrUnloadedVehicleSpecificEnclosure = (sku) => {
  const isLoadedVs = isLoadedVsEnclosure(sku);
  const isUnloadedVs = isEmptyVsEnclosure(sku);
  return isLoadedVs || isUnloadedVs;
};

export const isLoadedVsEnclosure = (sku) => {
  const skuIsVs10or12 = sku?.startsWith('VS-10') || sku?.startsWith('VS-12');

  const skuIsLoadedVsDotL = sku?.startsWith('VS.L');
  const skuIsLoadedVs1 = sku?.startsWith('VS-L');
  const skuIsLoadedVs2 = sku?.startsWith('L-VS');
  const skuIsLoadedVdVs = sku?.startsWith('L-VD');
  const skuIsLoadedEvlVs = sku?.startsWith('L-EVL');
  const skuIsLoadedSvrVs = sku?.startsWith('L-SVR');
  const skuIsLoadedSdrVs = sku?.startsWith('L-SDR');

  return !skuIsVs10or12 && (
    skuIsLoadedVs1 ||
    skuIsLoadedVs2 ||
    skuIsLoadedVsDotL ||
    skuIsLoadedVdVs ||
    skuIsLoadedSvrVs ||
    skuIsLoadedSdrVs ||
    skuIsLoadedEvlVs
  );
};

export const isEmptyVsEnclosure = (sku) => {
  if (sku?.startsWith('VS-12') || sku?.startsWith('VS-10')) {
    return false;
  }
  return sku?.includes('VS-') && !sku?.startsWith('L-');
};

export const isEnclosure = (category) => {
  return category?.includes('enclosure');
};
