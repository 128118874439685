import {
  skuIsLoadedOrUnloadedVehicleSpecificEnclosure
} from '../../helpers/products/vehicle-specific.mjs';

export const updateSkuListBasedOnProductParams = (sku, skuList, product) => {
  if (!product) {
    if (skuList?.length) {
      return skuList;
    } else {
      return [sku];
    }
  }

  const productType = product?.product_type;
  const category = product?.category;

  let shouldUseFullSkuList = productType === 'variant' || category === 'empty-subwoofer-enclosures' || category === 'marine-subwoofers';

  const isVsEnclosureSku = skuIsLoadedOrUnloadedVehicleSpecificEnclosure(sku);
  if (isVsEnclosureSku) {
    shouldUseFullSkuList = false;
  }

  const isSkuWithCoilConfig = sku?.includes('D1') || sku?.includes('D2') || sku?.includes('D4');
  if (isSkuWithCoilConfig) {
    const combinedSkus = combineSkusWithCombinedCoilConfigs(sku, skuList);
    if (combinedSkus) {
      return combinedSkus;
    }
  }

  if (shouldUseFullSkuList) {
    return skuList;
  } else {
    return [sku];
  }
};

export const combineSkusWithCombinedCoilConfigs = (sku, skuList) => {
    const coilConfigs = ['D1', 'D2', 'D4'];

    const coilConfigPart = coilConfigs.find(config => sku.includes(config));
    const baseSku = sku.replace(coilConfigPart, '').trim();

    const matchingSkus = skuList.filter(item => {
      return coilConfigs.some(config => item === `${baseSku} ${config}`);
    });

    if (matchingSkus.length > 0) {
      return matchingSkus;
  }
}