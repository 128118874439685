export const cleanAndReformatToE164WIthPlusCharacterAdded = (phoneNumber) => {
  if (!phoneNumber) return null;
  let phone = cleanPhoneAndReformatToE164(phoneNumber)
  if (phone?.length < 11) {
    return null;
  }
  phone = `+${phone}`
  if (phone?.length > 12) {
    return null;
  }
  return phone;
}

export const cleanPhoneAndReformatToE164 = (phoneNumber) => {
  if (!phoneNumber) {
    return null;
  }
  let phoneNumberStr;
  try {
    phoneNumberStr = String(phoneNumber)
    phoneNumberStr = formatPhoneNumber(phoneNumber)
    phoneNumberStr = reformatToSimpleTenDigitPhoneNumber(phoneNumberStr)
    if (phoneNumberStr?.length > 12) {
      return null;
    }
    if (phoneNumberStr?.length === 11 && phoneNumberStr?.[0] === '1') {
      phoneNumberStr = phoneNumberStr?.substring(1)
    }
    if (phoneNumberStr?.length > 10) {
      return null
    } else if (phoneNumberStr?.length < 10) {
      return null
    }
    phoneNumberStr = `1${phoneNumberStr}`
    return phoneNumberStr
  } catch (e) {
    return null
  }
}

export const reformatToSimpleTenDigitPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return null;
  }
  if (phoneNumber === '1') {
    return null;
  }
  phoneNumber = phoneNumber?.replace("(", "");
  phoneNumber = phoneNumber?.replace(")", "");
  phoneNumber = phoneNumber?.replace("-", "");
  phoneNumber = phoneNumber?.replace("  ", "");
  phoneNumber = phoneNumber?.replace(" ", "");
  phoneNumber = phoneNumber?.replace("+1", "");
  return phoneNumber
}


export function simpleFormatPhoneNumber(phoneNumberString) {
  if (!phoneNumberString) return null;
  phoneNumberString = String(phoneNumberString)
  if (phoneNumberString.startsWith('+1')) {
    phoneNumberString = phoneNumberString.replace('+1', '')
  }
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return null;
  try {
    let phoneNumberStr = phoneNumber?.replace(/\D+/g, '');
    // Remove country code if it exists (assuming country code for US as +1)
    if (phoneNumberStr?.length === 11 && phoneNumberStr?.[0] === '1') {
      phoneNumberStr = phoneNumberStr?.substring(1);
    }
    if (phoneNumberStr?.length > 10) {
      console.log(`[Error][${phoneNumber}] Phone number length is more than 10 digits`);
      return null;
    } else if (phoneNumberStr?.length < 10) {
      // console.log(`[Error][${phoneNumber}] Phone number length is less than 10 digits`);
      return null;
    }
    return phoneNumberStr;
  } catch (e) {
    console.log(`[Error][${phoneNumber}][formatPhoneNumber] An error occurred:`, e?.message);
    return null;
  }
};

