import {isofetch} from '@helpers/common/fetch.mjs';
import {useState} from 'react';

export const useAccountAddress = (data, mutate) => {
  const addresses = data?.addresses;
  const hasAddresses = addresses?.length > 0 ? true : false;
  const [addressBeingEdited, setAddressBeingEdited] = useState(!hasAddresses);

  const handleNewAddress = () => {
    setAddressBeingEdited({});
    document.querySelector('body').scrollIntoView();
  };

  const handleEditAddress = (address) => {
    setAddressBeingEdited(address);
    document.querySelector('body').scrollIntoView();
  };

  const handleCancelEditAddress = () => {
    setAddressBeingEdited();
    document.querySelector('body').scrollIntoView();
  };

  const handleDeleteAddress = async (addressId) => {
    try {
      await isofetch.post('/api/accounts/address/delete', {
        address_id: addressId
      });
      await mutate();
    } catch (e) {
      console.error(e);
    }
  };

  const handleSaveAddress = async (addressData) => {
    try {
      const data = await isofetch.post('/api/accounts/address/save', {addressData});
      if (data?.success) {
        await mutate();

        if (data?.phoneError) {
          throw new Error(data?.phoneError);
        }
      }
    } catch (e) {
      console.error(e.message);
      throw 'Error saving address, please try again later.';
    }
  };

  return {
    addresses,
    handleDeleteAddress,
    addressBeingEdited,
    setAddressBeingEdited,
    handleNewAddress,
    handleEditAddress,
    handleSaveAddress,
    handleCancelEditAddress
  };
};
