export const consoleLogErrFrontEnd = (errMsg, errObj) => {
  console.error(
      '%c📕[ERROR]📕:',
      'background: #FFFF00; font-weight: bold;',
      `${errMsg}: ${errObj}`
  );
};

export function consoleLogLogout(...vars) {
  console.log('%c [LOGOUT] ', 'background: #000000E5; color: #FFFF00', ...vars)
}

export function consoleLogGlobalDataLayer(...vars) {
  console.log('%c [CommonDL] ', 'background: #222; color: #FFFFFF', ...vars)
}

export function consoleLogUseEffect(...vars) {
  console.log('%c[Log]', 'background: #198038; color: #FFFFFF', ...vars)
}


export function consoleLogFe(fnName, ...vars) {
  console.log(`%c${fnName}`, 'background: #198038; color: #FFFFFF', ...vars)
}

export function consoleLogPopUpSubscriber(...vars) {
  console.log('%c[PopUp Subscriber]', 'background: #55447E; color: #FFFFFF', ...vars)
}

export function consoleLogOrangeFE(...vars) {
  console.log(`%c[LOGGER]`, 'background: #FFBF00; color: #000000', ...vars)
}

export function consoleLogPayPal(...vars) {
  console.log('%c[PayPal Checkout]', 'background: #00008B; color: #FFFFFF', ...vars)
}

export function consoleLogCheckout(...vars) {
  console.log('%c[Checkout Logs]', 'background: #A2CF60; color: #FFFFFF', ...vars)
}

export function consoleLogSubmitPayment(...vars) {
  console.log('%c[Submit Payment Logs]', 'background: #000080; color: #FFFFFF', ...vars)
}

export function consoleLogSearchFE(...vars) {
  console.log('%c[SEARCH]', 'background: #000080; color: #FFFFFF', ...vars)
}

export function consoleLogFrontEndFormData(...vars) {
  console.log('%c[FORM DATA]', 'background: #0066DA; color: #FFFF00', ...vars)
}

// White = #FFFFFF
// Black = #000000
// Dark Gray = #454545
// Light Gray = #999999

// Primary Colors
// Red = #FF0000
// Green = #00FF00
// Blue = #0000FF

// Navy = #000080
// Lime Green = #DFFF00
// Orange = #FFBF00
// Magenta = #DE3163
// Teal = #9FE2BF
// Aqua = #40E0D0
// Baby Blue = #6495ED
// Light Purple = #CCCCFF
