import cn from 'clsx';
import {Spinner} from '@components/widgets/Common';

export const SubmitButton = ({disabled, submitting, fullWidth, largeText, children, onClick}) => {
  // consoleLogOrangeFE('[SubmitButton] submitting... ', {submitting, disabled});
  return (
    <button
      type='submit'
      onClick={onClick}
      className={cn(
        'py-2 px-6 text-sm uppercase font-medium text-white',
        'rounded-md border outline-neutral-800',
        {
          'disabled bg-neutral-400': submitting || disabled,
          'bg-neutral-700 focus:bg-neutral-600 hover:bg-neutral-600': !disabled && !submitting,
          'w-full': fullWidth,
          block: fullWidth,
          'md:letter-spacing-2x md:text-xl': largeText
        }
      )}
      disabled={submitting || disabled}>
      <div className='flex text-center justify-center items-center'>
        {submitting && (
          <div className='pr-2'>
            <Spinner size={16} />
          </div>
        )}
        {children}
      </div>
    </button>
  );
};
