import useSWR from 'swr';
import {createUrlWithSkuListAsQueryParams, fetcher} from '@helpers/common/fetch.mjs';
import {useEffect, useState} from 'react';

export const useReviewRatingsForSkuList = (skuList) => {
  const api = `/api/reviews/products`;
  const apiUrl = createUrlWithSkuListAsQueryParams(api, skuList);
  // console.log("[useReviewRatingsForSkuList]:", {apiUrl});

  const {isLoading, error, data, isValidating} = useSWR(apiUrl, fetcher);

  const isLoaded = typeof data !== 'undefined';

  return {
    error,
    isLoading: isLoading,
    isLoaded: isLoaded,
    isLoadedOnce: !isLoading && isLoaded,
    isValidating,
    reviewRatings: data ?? null
  };
};

export const useReviewedIds = () => {
  const [reviewedIds, setReviewedIds] = useState([]);

  useEffect(() => {
    const localReviewedIds = localStorage.getItem('reviewedIds')
      ? JSON.parse(localStorage.getItem('reviewedIds'))
      : [];
    setReviewedIds(localReviewedIds);
  }, []);

  const addReviewedId = (id, new_count) => {
    const alreadyReviewed = reviewedIds.find((review) => {
      const first_key = Object.keys(review)[0];
      return first_key === id.toString();
    });
    if (alreadyReviewed) return;

    const new_ids = reviewedIds;
    new_ids.push({[id]: new_count});

    setReviewedIds(new_ids);
    localStorage.setItem('reviewedIds', JSON.stringify(new_ids));
  };

  return {reviewedIds, addReviewedId};
};

export const useAccountReviewHistoryForOneSKU = (sku, isLoggedIn) => {
  const apiUrl = isLoggedIn ? `/api/reviews/account/sku?sku=${sku}` : null;
  const {isLoading, error, data, isValidating, mutate} = useSWR(apiUrl, fetcher, {
    revalidateOnFocus: false
  });

  const itemsWithoutReviews = data?.data?.itemsWithoutReviews ?? [];

  const canWriteReview = itemsWithoutReviews?.length > 0 ? true : false;
  const orderForReview = itemsWithoutReviews?.[0];

  return {
    canWriteReview,
    orderForReview,
    itemLoading: isLoading,
    mutateAccountReviewHistory: mutate,
    error,
    isValidating
  };
};
