export const FaCopy = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        viewBox='0 0 448 512'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z'></path>
      </svg>
    </div>
  );
};

export const FaTrash = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        viewBox='0 0 448 512'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z'></path>
      </svg>
    </div>
  );
};

export const FaHourglassHalf = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        viewBox='0 0 384 512'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M360 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24 0 90.965 51.016 167.734 120.842 192C75.016 280.266 24 357.035 24 448c-13.255 0-24 10.745-24 24v16c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24 0-90.965-51.016-167.734-120.842-192C308.984 231.734 360 154.965 360 64c13.255 0 24-10.745 24-24V24c0-13.255-10.745-24-24-24zm-75.078 384H99.08c17.059-46.797 52.096-80 92.92-80 40.821 0 75.862 33.196 92.922 80zm.019-256H99.078C91.988 108.548 88 86.748 88 64h208c0 22.805-3.987 44.587-11.059 64z'></path>
      </svg>
    </div>
  );
};

export const FaWpforms = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        viewBox='0 0 448 512'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M448 75.2v361.7c0 24.3-19 43.2-43.2 43.2H43.2C19.3 480 0 461.4 0 436.8V75.2C0 51.1 18.8 32 43.2 32h361.7c24 0 43.1 18.8 43.1 43.2zm-37.3 361.6V75.2c0-3-2.6-5.8-5.8-5.8h-9.3L285.3 144 224 94.1 162.8 144 52.5 69.3h-9.3c-3.2 0-5.8 2.8-5.8 5.8v361.7c0 3 2.6 5.8 5.8 5.8h361.7c3.2.1 5.8-2.7 5.8-5.8zM150.2 186v37H76.7v-37h73.5zm0 74.4v37.3H76.7v-37.3h73.5zm11.1-147.3l54-43.7H96.8l64.5 43.7zm210 72.9v37h-196v-37h196zm0 74.4v37.3h-196v-37.3h196zm-84.6-147.3l64.5-43.7H232.8l53.9 43.7zM371.3 335v37.3h-99.4V335h99.4z'></path>
      </svg>
    </div>
  );
};

export const FaCheckCircle = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        viewBox='0 0 512 512'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path>
      </svg>
    </div>
  );
};

export const FaChevronLeft = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        viewBox='0 0 320 512'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z'></path>
      </svg>
    </div>
  );
};

export const FaFilter = ({size = '1em', color = 'currentColor', onClick}) => {
  return (
    <div onClick={onClick}>
      <svg
        stroke={color}
        fill={color}
        strokeWidth='0'
        viewBox='0 0 512 512'
        height={size}
        width={size}
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z'></path>
      </svg>
    </div>
  );
};
