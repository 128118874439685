import {isofetch} from '@helpers/common/fetch.mjs';
import {useState} from 'react';

export const useAccountCards = (data, mutate) => {
  const cards = data?.cards;
  const hasCards = cards?.length > 0 ? true : false;
  const [cardBeingEdited, setCardBeingEdited] = useState(!hasCards);

  const handleNewCard = () => {
    setCardBeingEdited({});
    document.querySelector('body').scrollIntoView();
  };

  const handleEditCard = (card) => {
    setCardBeingEdited(card);
    document.querySelector('body').scrollIntoView();
  };

  const handleCancelEditCard = () => {
    setCardBeingEdited();
    document.querySelector('body').scrollIntoView();
  };

  const handleDeleteCard = async (cardId) => {
    try {
      await isofetch.post('/api/accounts/card/delete', {
        cc_info: {
          id: cardId
        }
      });

      await mutate();
    } catch (e) {
      console.error(e);
    }
  };

  const handleSaveCard = async (cardData) => {
    try {
      const data = await isofetch.post('/api/accounts/card/save', {cc_info: cardData});
      if (data) {
        await mutate();

        return data;
      }
    } catch (e) {
      console.error(e.message);
      throw 'Error saving card, please try again later.';
    }
  };

  return {
    cards,
    handleDeleteCard,
    cardBeingEdited,
    setCardBeingEdited,
    handleNewCard,
    handleEditCard,
    handleSaveCard,
    handleCancelEditCard
  };
};
