export const MessageBox = ({children}) => {
  return <div className='bg-lime-100 rounded border border-lime-400 mt-2 mb-4 p-2'>{children}</div>;
};

export const WarningBox = ({children}) => {
  return (
    <div className='bg-amber-100 rounded border border-amber-400 mt-2 mb-4 p-2'>{children}</div>
  );
};

export const ErrorBox = ({children}) => {
  return <div className='bg-rose-100 rounded border border-rose-400 mt-2 mb-4 p-2'>{children}</div>;
};

export const LightYellowBgBox = ({children, className = '', style}) => {
  return (
    <div className={`bg-amber-50 rounded border border-amber-200 ${className}`} style={style}>
      {children}
    </div>
  );
};
