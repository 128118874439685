export const Field = ({error, children, className}) => {
	return (
		<div className={className ?? 'pb-3 w-100 w-full relative'}>
			{children}

			{error && (
				<div className='text-rose-600 pl-2'>
					{error?.type === 'required' && <>{error?.message || 'Required'}</>}

					{error?.type !== 'required' && <>{`${error?.message || 'Field error'}`}</>}
				</div>
			)}
		</div>
	);
};
