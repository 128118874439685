import {dealerConfigOptions, priceHelper, siteSettingsV2} from '@helpers/config-master.mjs';
import {roundMoneyFair} from '@helpers/common/number.mjs';

export const getCartTotals = (lineItems = [], {isDealer} = {isDealer: false}, shippingEstimate = 0) => {
  // Compute totalBeforeDiscounts and discountsTotal in a single reduce operation to avoid unnecessary iterations over lineItems
  const {totalBeforeDiscounts, discountsTotal} = lineItems?.reduce((totals, item) => {
    // console.log({lineItemsReduce_totals: totals});
    // console.log({lineItemsReduce_item: item});
    const variantPrice = isDealer ? item?.variant?.price_dealer : item?.price_map ?? item?.variant?.price;
    const itemSubtotal = variantPrice * item?.quantity;
    const originalSubtotal = priceHelper?.getOriginalSubtotal([item]);

    return {
      totalBeforeDiscounts: totals?.totalBeforeDiscounts + itemSubtotal,
      discountsTotal: totals?.discountsTotal + itemSubtotal - originalSubtotal
    };
  }, {totalBeforeDiscounts: 0, discountsTotal: 0});

  const roundedTotalBeforeDiscounts = roundMoneyFair(totalBeforeDiscounts);

  let roundedDiscountsTotal = roundMoneyFair(discountsTotal);
  if (isDealer || roundedDiscountsTotal <= 0.03) {
    roundedDiscountsTotal = 0;
  }

  const subtotalPrice = roundMoneyFair(roundedTotalBeforeDiscounts - roundedDiscountsTotal);

  let shippingTotal = 0;
  if (isDealer && subtotalPrice < dealerConfigOptions?.freeShippingThreshold) {
    shippingTotal = Number(shippingEstimate);
  } else {
    const shouldChargeShippingToRetailCustomer = subtotalPrice <= siteSettingsV2.shippingSettings?.freeShippingThreshold;
    if (shouldChargeShippingToRetailCustomer) {
      shippingTotal = siteSettingsV2.shippingSettings?.staticShippingCostUnderThreshold;
    }
  }

  const totalPrice = roundMoneyFair(subtotalPrice + shippingTotal);

  if (isNaN(roundedDiscountsTotal)) {
    roundedDiscountsTotal = 0;
  }

  const totals = {
    isDealer,
    originalTotal: roundedTotalBeforeDiscounts,
    discountsTotal: roundedDiscountsTotal ?? 0,
    subtotalPrice,
    subTotal: subtotalPrice,
    shippingTotal,
    totalPrice: Number(totalPrice)
  };
  // console.log({get_cart_totals: totals});
  return totals;
};

