import dynamic from 'next/dynamic';
import {useSession} from 'next-auth/react';

const ChatButton = dynamic(
  () => import('@components/chat/andrew-work/chat-button').then((mod) => mod.ChatButton),
  {ssr: false}
);

export const ChatWrapper = () => {
  const {data: session, status} = useSession();

  if (!session) {
    return null;
  }

  return <ChatButton />;
};
