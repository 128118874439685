export function consoleLogGoogleAds(...vars) {
	console.log('%c[Google:Ads] ', 'background: #222; color: #00B337 ', ...vars)
}

export function consoleLogGA4(...vars) {
	console.log('%c[Google:GA4]', 'background: #222; color: #00B337', ...vars)
}

export function consoleLogSkarAudioAnalyticsEvent(...vars) {
  console.log('%c[SkarAudioAnalytics]', 'background: #222; color: #00E6FF', ...vars)
}

export function consoleLogGTM(...vars) {
	console.log('%c[GoogleTagManager]', 'background: #222; color: #00aa00', ...vars)
}

export function consoleLogBlackBgYellowText(...vars) {
	console.log('%c [LOG] ', 'background: #000000E5; color: #FFFF00', ...vars)
}

export function consoleLogSEORouter(...vars) {
  console.log('%c [SeoRouter] ', 'background: #000000E5; color: #FFFF00', ...vars)
}

export function consoleLogWindowType(...vars) {
	console.log('%c [.window] ', 'background: #385325; color: yellow', ...vars)
}

export function logClientOrServerWindowType() {
	if (typeof window !== 'undefined') {
		consoleLogWindowType('You are on the browser') // ✅   Can use window here
	} else {
		consoleLogWindowType('You are on the server') // ⛔️ Don't use window here
	}
}
