import cn from 'clsx';
import {useEffect, useState} from 'react';
import {FiThumbsUp} from '@assets/react-icons/fi';
import {useReviewedIds} from '@components/reviews/review-hooks';

export const ReviewThumbsUp = ({reviewId, thumbsUp}) => {
  const [thumbsUpCount, setThumbsUpCount] = useState(thumbsUp);
  const [active, setActive] = useState(false);
  const {reviewedIds, addReviewedId} = useReviewedIds();

  useEffect(() => {
    const alreadyReviewed = reviewedIds?.find((review) => {
      const firstKey = Object.keys(review)?.[0];
      return firstKey === reviewId?.toString();
    });
    if (!active && alreadyReviewed) {
      const newCount = alreadyReviewed[reviewId] < thumbsUp ? thumbsUp : alreadyReviewed[reviewId];
      setThumbsUpCount(newCount);
      setActive(true);
    } else {
      setThumbsUpCount(thumbsUp);
      setActive(false);
    }
  }, [reviewId, reviewedIds]);

  const handleThumbsUp = async () => {
    if (active) return;
    // Check if reviewId is already in local storage
    const newCount = thumbsUpCount + 1;
    setThumbsUpCount(newCount);
    setActive(true);

    addReviewedId(reviewId, newCount);

    await fetch('/api/reviews/thumbs-up', {
      method: 'POST',
      body: JSON.stringify({reviewId: reviewId}),
      headers: {
        'Content-Type': 'application/json'
      }
    });
  };

  const svgStyle = {
    fill: active ? '#444' : 'none',
    stroke: active ? '#444' : '#444',
    transition: 'all 0.2s ease-in-out'
  };

  return (
    <div className='pt-1 mb-2 inline-block transition-colors select-none'>
      <div
        className={cn('flex items-center gap-1 rounded-md bg-neutral-100 px-2 py-2', {
          'bg-neutral-500 text-white': active,
          'cursor-pointer': !active
        })}
        onClick={handleThumbsUp}>
        <FiThumbsUp size={14} style={svgStyle} />
        <div className='text-sm'>{thumbsUpCount}</div>
      </div>
    </div>
  );
};
