import cn from 'clsx';
import {Fragment} from 'react';
import {useDatabaseCartProviderV3} from '@components/cart/CartProvider.jsx';
import {
  getSubwooferCompatibilityChecksForEnclosures
} from '@helpers/subwoofer-compatibility/get-compatibility-mappings.mjs';
import {
  ListIncompatiblePairs
} from '@components/vehicle-specific/compatibility/list-incompatibilities';
import {ListSuggestions} from '@components/vehicle-specific/compatibility/list-suggestions';
import {useRouter} from 'next/router';

export function SubwooferCompatibilityCheck({handleClose}) {
  const router = useRouter();
  const {lineItems} = useDatabaseCartProviderV3();
  // console.log('[SubwooferCompatibilityCheck][lineItems]:', {lineItems});

  const incompatibleItems = getSubwooferCompatibilityChecksForEnclosures(lineItems);
  // console.log('[SubwooferCompatibilityCheck][incompatibleItems]:', {incompatibleItems});

  const categoryIsLoadedVsEnclosure = lineItems?.some((x) => x?.variant?.category === 'loaded-subwoofer-enclosures');
  const categoryIsEnclosures = lineItems?.some((x) => x?.variant?.category === 'empty-subwoofer-enclosures');

  let message = incompatibleItems?.message;
  let linkText = incompatibleItems?.linkText;
  let link = incompatibleItems?.link;

  const hasEnclosureButNoSubwoofersInCartMsg = !categoryIsLoadedVsEnclosure && categoryIsEnclosures
    && message === 'Would you like to add some subwoofers to the cart as well?';
  // console.log('[SubwooferCompatibilityCheck][hasEnclosureButNoSubwoofersInCartMsg]:', hasEnclosureButNoSubwoofersInCartMsg);

  if (!incompatibleItems) {
    return null;
  }

  if (!hasEnclosureButNoSubwoofersInCartMsg){
    return null;
  }

  const handleClick = () => {
    if (link) {
      router.push(link).then(() => {
        if (handleClose) {
          handleClose();
        }
      });
    }
  }

  let border, bgColor;
  if (hasEnclosureButNoSubwoofersInCartMsg) {
    border = hasEnclosureButNoSubwoofersInCartMsg ? 'border rounded-md border p-2' : 'border border-rose-400 rounded p-2';
    bgColor = hasEnclosureButNoSubwoofersInCartMsg ? 'rgb(236 254 255)' : '#f7dede';
    message = 'Check out our subwoofers as well';
  }

  const incompatibleItemsCompatChecks = incompatibleItems?.[0]?.compatibilityChecks

  return (
    <div
      className={border}
      style={{
        backgroundColor: bgColor,
        fontSize: '18px',
        paddingBottom: '1px'
      }}>
      {message}

      {incompatibleItemsCompatChecks?.map((skuCheck, index) => {
        return (
          <Fragment key={index}>
            <ListIncompatiblePairs set={skuCheck} lineItems={lineItems}/>
            <ListSuggestionSet set={skuCheck} lineItems={lineItems}/>
          </Fragment>
        );
      })}
      {incompatibleItems?.explanation && <p className='py-3'>{incompatibleItems?.explanation}</p>}
      {link && linkText && (
        <p className='my-3'>
          <button onClick={handleClick}
                  className={cn('py-2 px-6 text-sm uppercase font-medium text-white', 'rounded-md border bg-sky-500 hover:bg-sky-700')}>
            {linkText}
          </button>
        </p>
      )}
    </div>
  );
}

export function ListSuggestionSet({set = [], lineItems = []}) {
  if (!set || !Array.isArray(set.suggestions)) {
    return null
  }
  let suggestions = set?.suggestions ?? []
  suggestions = suggestions.map((category) => {
    let categoryProducts = category?.products?.filter((item) => {
      const alreadyAdded = !!lineItems.find((x) => item.properSkuList.includes(x.sku))
      return !alreadyAdded
    })
    return {
      ...category,
      products: categoryProducts,
    }
  })

  if (!suggestions?.length) {
    return null
  }
  return <div>
    <ListSuggestions vsItem={set.vsItem} suggestions={suggestions}/>
  </div>
}

export const getSubwooferHoleQtyText = (totalSubwooferHoles) => {
  let subwooferHoleQtyText = '';

  switch (totalSubwooferHoles) {
    case 1:
      subwooferHoleQtyText = 'Single ';
      break;
    case 2:
      subwooferHoleQtyText = 'Dual ';
      break;
    case 4:
      subwooferHoleQtyText = 'Quad ';
      break;
    default:
      subwooferHoleQtyText = 'Unknown ';
  }
  return subwooferHoleQtyText;
};
