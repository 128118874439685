const GA4_MEASUREMENT_ID = {
  PROD: 'G-MLJZD060VB', // ✔️
  DEV: 'G-SZ2654ZQMQ' // ✔️
}

const GA4_API_KEY = {
  PROD: '_6ij5mYVScCrbA2d6G1Ccg', // ✔️
  DEV: 'Gndv_QHkRD-mOblc5YvWMQ' // ✔️
}

export const GA4_CONFIG = (type) => {
  if (type.toUpperCase() === "PROD" || type.toUpperCase() === "DEV") {
    return {
      GA4_MEASUREMENT_ID: GA4_MEASUREMENT_ID[type.toUpperCase()],
      GA4_API_KEY: GA4_API_KEY[type.toUpperCase()]
    }
  } else {
    console.error("[MISSING GA4_CONFIG VALUE FOR TYPE: (PROD or DEV).");
  }
}


