import Image from 'next/image';
import GreenSuccessCheckMarkSVG from '@image/icons/svg-icons/GreenSuccessCheckMark.svg';

export const SuccessCheckMarkGreenSVG_xl = () => {
  return (
    <div>
      <Image
        src={GreenSuccessCheckMarkSVG}
        alt='Green Success Checkmark'
        width={300}
        height={180}
      />
    </div>
  );
};

export const SuccessCheckMarkGreenSVG_sm = () => {
  return (
    <Image src={GreenSuccessCheckMarkSVG} alt='Green Success Checkmark' width={60} height={60} />
  );
};

export const SuccessCheckMarkGreenSVG_xs = () => {
  return (
    <Image src={GreenSuccessCheckMarkSVG} alt='Green Success Checkmark' width={28} height={28} />
  );
};
