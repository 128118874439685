import React from 'react';
import {baseUrl} from '@components/seo/MetaDataDefaults';
import {DefaultPageSeo} from '@components/seo/DefaultPageSeo';
import {CollectionSeo} from '@components/seo/CollectionSeo';
import {ProductSeo} from '@components/seo/ProductSeo';
import {HomePageSeo} from '@components/seo/HomePageSeo';
import {DynamicPageSEO} from '@components/seo/DynamicPageSEO';
import {VehicleSpecificCollectionPageSEO} from '../../pages/collections/vehicle-specific-subwoofer-enclosures';
import {CustomCollectionSEO_JsonLd} from '@components/seo/CustomCollectionSEO_JsonLd';
import {CustomProductPageSEO} from '@components/seo/CustomProductSEO_JsonLd';
import {ContactUsSeo} from '@components/seo/ContactUsSeo';

const determinePagePathType = (pagePath) => {
  const path = String(pagePath);
  if (path === '/') return 'home_page';
  if (path === '/pages/contact-us') return 'contact_us';
  if (path === '/cart') return 'cart';
  if (path === '/checkout/v3') return 'checkout';
  if (path.includes('/collections')) {
    if (path.includes('/vehicle-specific-subwoofer-enclosures'))
      return 'collection_vehicle_specific_enclosures';
    if (path.includes('/marine-subwoofers')) return 'collection_marine_subwoofers';
    if (path.includes('/car-audio-batteries')) return 'collection_car_audio_batteries';
    return 'collection';
  }

  if (path.includes('/products')) {
    if (path.includes('/products/SK2MM')) return 'parent_product';
    if (path.includes('/products/SKM8')) return 'parent_product';
    if (path.includes('/products/SKM10')) return 'parent_product';
    return 'product';
  }

  if (path === '/pages/frequently-asked-questions') return 'faq';
  if (path === '/pages/privacy-policy') return 'privacy_policy';
  if (path === '/pages/return-policy') return 'return_policy';

  return 'default';
};

export default function SeoRouter({props, router}) {
  // console.dir({SeoRouter_props: props}, {depth: 3});
  const {asPath: pagePath} = router || {};
  const {href: url} = useUrl(pagePath);

  const pagePathType = determinePagePathType(pagePath);
  // console.log({pagePathType});

  switch (pagePathType) {
    case 'home_page':
      return <HomePageSeo />;
    case 'contact_us':
      return <ContactUsSeo />;
    case 'collection':
      return <CollectionSeo props={props} />;
    case 'collection_vehicle_specific_enclosures':
      return <VehicleSpecificCollectionPageSEO />;
    case 'collection_marine_subwoofers':
    case 'collection_car_audio_batteries':
      return <CustomCollectionSEO_JsonLd collectionPageInfo={props?.collectionPageInfo} />;
    case 'product':
      return <ProductSeo props={props} />;
    case 'parent_product':
      return <CustomProductPageSEO props={props} />;
    case 'cart':
      return (
        <DynamicPageSEO
          title='Shopping Cart | Skar Audio'
          description='Your shopping cart with Skar Audio'
          canonicalUrl={`${baseUrl}/cart`}
        />
      );
    case 'checkout':
      return (
        <DynamicPageSEO
          title='Checkout | Skar Audio'
          description='Skar Audio checkout page'
          canonicalUrl={`${baseUrl}/checkout/v3`}
        />
      );
    case 'privacy_policy':
      return (
        <DynamicPageSEO
          title='Privacy Policy | Skar Audio'
          description="Review Skar Audio's Privacy Policy to learn how we protect and handle your data as a visitor of our website."
          canonicalUrl={`${baseUrl}/pages/privacy-policy`}
        />
      );
    case 'faq':
      return (
        <DynamicPageSEO
          title='Frequently Asked Questions | Skar Audio'
          description='The Skar Audio F.A.Q center has detailed info on our products, answers to the most popular questions we get asked, and so much more.'
          canonicalUrl={`${baseUrl}/pages/frequently-asked-questions`}
        />
      );
    case 'return_policy':
      return (
        <DynamicPageSEO
          title='Return Policy | Skar Audio'
          description='Skar Audio has an industry best, hassle free, return policy that gives our customers more confidence than ever when shopping with us. Click to review our full return policy details.'
          canonicalUrl={`${baseUrl}/pages/return-policy`}
        />
      );
    default:
      return <DefaultPageSeo url={url} />;
  }
}

export const useUrl = (slug) => {
  const baseHref = 'https://www.skaraudio.com';
  const url = new URL(slug, baseHref);
  // console.log("[useUrl]: ", {url})
  return url;
};

// const seoRouterLogging = (pagePath, pagePathType) => {
//   seoLogging(' ')
//   seoLogging(chalk.bold('=').repeat(80))
//   seoLogging(chalk.bold('  > Current Page Path in [SeoRouter] ==> '), pagePath)
//   seoLogging(chalk.bold('  > Determined Page Path SEO Type in [SeoRouter.jsx] ==> '), pagePathType)
//   seoLogging(chalk.bold('=').repeat(80))
// }

// const seoLogging = (...vars) => {
//   if (!priceHelper?.logging?.seo) {
//   }
//   console.log(...vars)
// }
