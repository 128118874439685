import {SvgStar} from '@components/product/global/Reviews';

export const ReviewStarsFromDb = ({avgScore, count, size = 18, small = false}) => {
  const ratingValueRoundUp = Math.ceil(Number(avgScore));
  return <div>
    {ratingValueRoundUp ? (
      <div className="flex items-end">
        {Array(ratingValueRoundUp).fill(0).map((item, index) => {
          return <SvgStar key={index} size={size} color="#f5cc42" />;
        })}
        <span
          className={'ml-1 text-xs md:text-sm font-medium whitespace-nowrap'}
          style={{...(small && {fontSize: '12px', fontWeight: 400, lineHeight: 1})}}>
            {small && count}
          {!small && <span>{`${count} Reviews`}</span>}
          </span>
      </div>
    ) : null}
  </div>;
};
