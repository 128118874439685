import React, {createContext, useCallback, useContext, useMemo, useReducer} from 'react';
import {ThemeProvider} from 'next-themes';

const initialState = {
  displaySidebar: false,
  displayDropdown: false,
  displayModal: false,
  displayPushModal: false,
  displayReviewModal: false,
  displayIndividualReviewModal: false,
  displayIndividualReviewModalMobile: false,
  reviewModalId: null,
  initialReviewImageIndex: 0,
  reviewIndex: 0,
  displayMobileNav: false,
  focusOnMobileSearch: false,
  modalView: 'LOGIN_VIEW',
  sidebarView: 'CART_VIEW',
  userAvatar: ''
};

const UIContext = createContext(initialState);
UIContext.displayName = 'UIContext';

function uiReducer(state, action) {
  switch (action.type) {
    case 'OPEN_SIDEBAR':
      return {...state, displaySidebar: true};
    case 'CLOSE_SIDEBAR':
      return {...state, displaySidebar: false};
    case 'OPEN_DROPDOWN':
      return {...state, displayDropdown: true};
    case 'CLOSE_DROPDOWN':
      return {...state, displayDropdown: false};
    case 'OPEN_MODAL':
      return {...state, displayModal: true, displaySidebar: false};
    case 'CLOSE_MODAL':
      return {...state, displayModal: false};
    case 'OPEN_PUSH_MODAL':
      return {...state, displayPushModal: true};
    case 'CLOSE_PUSH_MODAL':
      return {...state, displayPushModal: false};
    case 'OPEN_MOBILE_NAV':
      return {...state, displayMobileNav: true, focusOnMobileSearch: !!action.focusSearch};
    case 'CLOSE_MOBILE_NAV':
      return {...state, displayMobileNav: false};
    case 'OPEN_REVIEW_MODAL':
      return {...state, displayReviewModal: true};
    case 'CLOSE_REVIEW_MODAL':
      return {...state, displayReviewModal: false};
    case 'OPEN_INDIVIDUAL_REVIEW_MODAL':
      return {
        ...state,
        displayIndividualReviewModal: true,
        reviewModalId: action.reviewModalId,
        initialReviewImageIndex: action.initialReviewImageIndex,
        reviewIndex: action.reviewIndex
      };
    case 'OPEN_INDIVIDUAL_REVIEW_MODAL_MOBILE':
      return {
        ...state,
        displayIndividualReviewModalMobile: true,
        reviewModalId: action.reviewModalId,
        initialReviewImageIndex: action.initialReviewImageIndex,
        reviewIndex: action.reviewIndex
      };
    case 'CLOSE_INDIVIDUAL_REVIEW_MODAL':
      return {
        ...state,
        displayIndividualReviewModal: false,
        displayIndividualReviewModalMobile: false,
        reviewIndex: 0
      };
    case 'RESET_REVIEW_MODAL':
      return {
        ...state,
        reviewModalId: null,
        initialReviewImageIndex: 0
      };
    case 'SET_MODAL_VIEW':
      return {...state, modalView: action.view};
    case 'SET_SIDEBAR_VIEW':
      return {...state, sidebarView: action.view};
    case 'SET_USER_AVATAR':
      return {...state, userAvatar: action.value};
    default:
      return state;
  }
}

export const UIProvider = ({children}) => {
  const [state, dispatch] = useReducer(uiReducer, initialState);

  const openSidebar = useCallback(() => dispatch({type: 'OPEN_SIDEBAR'}), [dispatch]);
  const closeSidebar = useCallback(() => dispatch({type: 'CLOSE_SIDEBAR'}), [dispatch]);
  const toggleSidebar = useCallback(
    () => (state.displaySidebar ? dispatch({type: 'CLOSE_SIDEBAR'}) : dispatch({type: 'OPEN_SIDEBAR'})),
    [dispatch, state.displaySidebar]
  );
  const closeSidebarIfPresent = useCallback(
    () => state.displaySidebar && dispatch({type: 'CLOSE_SIDEBAR'}),
    [dispatch, state.displaySidebar]
  );

  const openDropdown = useCallback(() => dispatch({type: 'OPEN_DROPDOWN'}), [dispatch]);
  const closeDropdown = useCallback(() => dispatch({type: 'CLOSE_DROPDOWN'}), [dispatch]);

  const openModal = useCallback(() => dispatch({type: 'OPEN_MODAL'}), [dispatch]);
  const closeModal = useCallback(() => dispatch({type: 'CLOSE_MODAL'}), [dispatch]);

  const openPushModal = useCallback(() => dispatch({type: 'OPEN_PUSH_MODAL'}), [dispatch]);
  const closePushModal = useCallback(() => dispatch({type: 'CLOSE_PUSH_MODAL'}), [dispatch]);

  const openReviewModal = useCallback((initialReviewImageIndex, reviewModalId) => {
      dispatch({type: 'OPEN_REVIEW_MODAL', initialReviewImageIndex, reviewModalId});
    },
    [dispatch]
  );

  const closeReviewModal = useCallback(() => {
    dispatch({type: 'CLOSE_REVIEW_MODAL'});
    setTimeout(() => {
      dispatch({type: 'RESET_REVIEW_MODAL'});
    }, 500);
  }, [dispatch]);

  const openIndividualReviewModal = useCallback(
    (initialReviewImageIndex, reviewModalId, reviewIndex, isMobile = false) => {
      const type = isMobile ? 'OPEN_INDIVIDUAL_REVIEW_MODAL_MOBILE' : 'OPEN_INDIVIDUAL_REVIEW_MODAL';
      dispatch({
        type,
        initialReviewImageIndex,
        reviewModalId,
        reviewIndex
      });
    },
    [dispatch]
  );

  const closeIndividualReviewModal = useCallback(() => {
    dispatch({type: 'CLOSE_INDIVIDUAL_REVIEW_MODAL'});
    setTimeout(() => {
      dispatch({type: 'RESET_REVIEW_MODAL'});
    }, 500);
  }, [dispatch]);

  const setUserAvatar = useCallback((value) => dispatch({
    type: 'SET_USER_AVATAR',
    value
  }), [dispatch]);
  const setModalView = useCallback((view) => dispatch({type: 'SET_MODAL_VIEW', view}), [dispatch]);
  const setSidebarView = useCallback((view) => dispatch({
    type: 'SET_SIDEBAR_VIEW',
    view
  }), [dispatch]);
  const openMobileNav = useCallback((focusSearch) => dispatch({
    type: 'OPEN_MOBILE_NAV',
    focusSearch
  }), [dispatch]);
  const closeMobileNav = useCallback(() => {
    dispatch({type: 'CLOSE_MOBILE_NAV'});
    document.body.classList.remove('no-scroll');
    document.documentElement.classList.remove('no-scroll');
  }, [dispatch]);

  const value = useMemo(
    () => ({
      ...state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      openDropdown,
      closeDropdown,
      openModal,
      closeModal,
      openPushModal,
      closePushModal,
      openMobileNav,
      closeMobileNav,
      openReviewModal,
      closeReviewModal,
      openIndividualReviewModal,
      closeIndividualReviewModal,
      setModalView,
      setSidebarView,
      setUserAvatar
    }),
    [state]
  );

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export const useUI = () => {
  const context = useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context;
};

export const ManagedUIContext = ({children}) => (
  <UIProvider>
    <ThemeProvider>{children}</ThemeProvider>
  </UIProvider>
);
