export const baseUrl = 'https://www.skaraudio.com';

export const defaultSeoTitle = 'Skar Audio';
export const defaultSeoDescription = 'Skar Audio is a leader in car audio amplifiers, subwoofers, speakers, and more. We focus on the innovation and development of new, superior, car audio products.'
export const defaultSeoImage = `${baseUrl}/image/logos/skar-audio-logo-black-large.png`
export const defaultSeoLogo = `${baseUrl}/image/logos/skar-audio-logo-black-large.png`


export const GenerateStaticPageMetaData = () => {
  return (<>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta property="author" name="author" content="Skar Audio"/>
      <meta property="robots" name="robots" content="index,follow"/>
      <meta property="googlebot" name="googlebot" content="index,follow"/>
      <meta property="bingbot" name="bingbot" content="index,follow"/>
      <meta key="HandheldFriendly" name="HandheldFriendly" content="True"/>
    </>);
};

export const GenerateOpenGraphPageMetaData = ({title, url, description, image, type}) => {
  type = type ?? 'website';
  return <>
    <meta property='og:type' content={type}/>
    <meta property='og:site_name' content='Skar Audio'/>
    <meta property='og:locale' content='en_US'/>
    <meta property='og:title' content={title}/>
    <meta property="og:description" content={description}/>
    <meta property="og:url" content={url}/>
    <meta property='og:image' content={image}/>
  </>;
};

export const GenerateTwitterMetaData = (title, url, description, image) => {
  return <>
    <meta name='twitter:site' content='@skaraudio_?lang=en'/>
    <meta name='twitter:title' content={title}/>
    <meta name='twitter:description' content={description}/>
    <meta name='twitter:card' content='summary_large_image'/>
    <meta property="twitter:domain" content={baseUrl}/>
    <meta property="twitter:url" content={url}/>
    <meta property='twitter:image' content={image}/>
  </>
}
