export const capitalizeAndSplit = (str) => {
  if (!str) {
    return null;
  }
  str = str.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  return str;
}

export function capitalizeFirstLetter(string) {
  return string?.[0]?.toUpperCase() + string?.slice(1);
}

export const cleanAndCapitalize = (str) => {
  if (!str) {
    return null;
  }
  str = str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[]\/]/gi, "");
  str = toTitleCase(str);
  return str;
}

export const capitalizeEveryWord = (string) => {
  if (!string) {
    return null;
  }
  string = string.toLowerCase().trim();
  return string?.split(/\s|_/)?.map((x) => x?.[0]?.toUpperCase() + x?.slice(1))?.join(" ");
}

export const toHandleFormat = (str) => {
  if (!str) {
    return null;
  }
  str = str?.toLowerCase()?.replace(/[^a-zA-Z0-9]+/g, "-");
  return str;

}

export function toTitleCase(string) {
  const text = String(string)?.toLowerCase().split(" ")?.filter(Boolean)?.map((s) => s?.charAt(0).toUpperCase() + s?.substring(1))?.join(" ");
  return text;
}

export function capitalizeTheFirstLetterOfEachWord(str) {
  if (!str) {
    return null;
  }
  let separateWord = str?.toLowerCase()?.split(" ");
  for (let i = 0; i < separateWord?.length; i++) {
    separateWord[i] = separateWord?.[i]?.charAt(0)?.toUpperCase() + separateWord?.[i]?.substring(1);
  }
  return separateWord?.join(" ");
}

export function pathJoin(...parts) {
  const isAbs = parts?.[0]?.startsWith("/");
  const allParts = parts?.map((x) => (x?.endsWith("/") ? x?.substring(0, -1) : x))?.map((x) => (x?.startsWith("/") ? x?.substring(1) : x));
  return (isAbs ? "/" : "") + allParts?.join("/");
}

export function includesAnyOf(str, includeList = []) {
  return includeList?.some((x) => str?.includes(x));
}

// 'VD-8 (D2/D4)' => 'VD-8 D2'
export function firstVariantInBrackets(sku) {
  return sku?.replaceAll(/\((.*?)\/(.*?)\)/g, "$1");
}

export function hideCreditCardNumberButLast4(card_no) {
  let cardNo = String(card_no ?? "");
  let [stars, last4] = [cardNo.slice(0, -4), cardNo.slice(-4)];
  stars = "*".repeat(stars.length);
  cardNo = stars + last4;
  let cardGroups = cardNo.match(/.{1,4}/g);
  cardNo = cardGroups?.join(" ");
  return cardNo;
}

export function capitalizeString(str) {
  if (!str) return str;
  return str
    ?.replaceAll("_", " ")
    ?.split(" ")
    ?.map((e) => capitalizeFirstLetter(e))
    ?.join(" ");
}

export function noBrackets(sku) {
  return sku?.replaceAll(/\(.*?\)/g, "");
}

export const singular = (word) => {
  if (word.endsWith('s')) {
    return word.substring(0, word.length-1);
  }
  return word;
}
