import Image from 'next/image';
import {defaultThemeImagePaths, priceHelper} from '@helpers/config-master.mjs';
import {ProductCardDesktop} from '@components/product/desktop/ProductCardDesktop';

export const RenderVehicleSpecificProductsDesktop = ({products, session}) => {
  // const desktopHeaderImage = useDesktopCollectionHeaderImage();
  const isDealer = session?.user?.isDealer ?? session?.is_dealer;
  const desktopHeaderImage = defaultThemeImagePaths?.desktopCollectionPageHeaderImage;
  const desktopProductImageSaleOverlay = defaultThemeImagePaths?.desktopProductImageSaleOverlay;
  const productText = `${products?.length} ${products?.length > 1 ? 'products' : 'product'}`;

  const hasProducts = products?.length > 0;

  return (
    <>
      <div id='product-content-wrapper' className='md:w-9/12 md:block'>
        {priceHelper.saleEnabled === true && (
          <div className='m-auto pb-2'>
            {desktopHeaderImage && (
              <Image
                src={desktopHeaderImage}
                alt='desktop banner'
                width='1200'
                height='185'
                loading='eager'
              />
            )}
          </div>
        )}

        {!hasProducts && (
          <div className={'text-center italic pt-4 pb-8'}>
            No items found, try removing a filter
          </div>
        )}

        {hasProducts && (
          <div className='pt-1'>
            <span className='text-gray-700 ml-1 mb-2'>{productText}</span>
            <ul className='grid grid-cols-3 gap-8 mb-16 pt-1'>
              {products?.map((product) => (
                <li key={product?.id}>
                  <ProductCardDesktop
                    product={product}
                    desktopProductImageSaleOverlay={desktopProductImageSaleOverlay}
                    isSourceCollectionPage={true}
                    isDealer={isDealer}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
