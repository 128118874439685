import path from 'path';
import {DeleteButtonForIncompatible} from '@components/checkout-v3/layout/right/checkout-sidebar';
import {HStack, NextLink} from '@components/widgets/Common';


export function ListIncompatiblePairs({set = []}) {
  if (!set || !Array.isArray(set?.productList)) {
    return null;
  }

  const vsItem = set?.enclosureSku;

  return (
    <div>
      <NextLink href={path?.join(`/products`, vsItem?.path)} className='hover:underline font-bold'
                style={{paddingTop: '3px'}}>
        {vsItem?.sku}
      </NextLink>
      <div>is not compatible with</div>
      <ul className='list-disc pl-4 py-3'>
        {set?.productList?.map((product, index) => {

          const item = lineItems.find((x) => x.sku === product?.sku);
          return (
            <li key={index}>
              <HStack gap={1} className='justify-start'>
                <DeleteButtonForIncompatible isCompatible={false} item={item}/>
                <div>{product?.sku}</div>
              </HStack>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
