import {roundMoneyFair} from './common/number.mjs';
// import {
//   CyberMondaySaleHeaderBanner
// } from "@components/black-friday/cyber-monday-sale-header-banner.jsx";
//

export let GLOBAL_CONFIG = {
  EnvType: 'production'
};

export const emailCheckApiPath = '/api/auth/xa';
export const checkoutValidateEmailApiPath = '/api/auth/2';
export const forgotPwApiPath = '/api/auth/3';

export const RETURN_ORDERS_REFUND_DAYS_LIMIT = 30;
// export const RETURN_ORDERS_REFUND_DAYS_LIMIT = 729; // @TODO <-- FOR TESTINGO ONLY
export const RETURN_ORDERS_WARRRANTY_DAYS_LIMIT = 729;

export const siteSettingsV2 = {
  shippingSettings: {
    freeShippingThreshold: 25.00,
    staticShippingCostUnderThreshold: 4.82
  },
  saleSettings: {
    attributes: {
      'BF2023': {
        saleIsActive: false,
        saleExpiresAt: '2023-11-30T05:00:00.000Z',
        discountPercentage: 25,
        salePercentageDailyDeal: 30,
        layoutSettings: {
          showGeoTransitBanner: true,
          showSaleBanner: true,
          showCountdownTimer: true,
          showEmailSubscriberPopup: false,
          showPushNotificationPopup: false,
          // saleBanner: CyberMondaySaleHeaderBanner,
          layout: {
            bannerLinks: {
              homepageBanner: '/collections/cyber-monday'
            },
            desktop: {
              categoryPageHeaderImage30: '/image/home/theme/sale/desktop/CyberMonday2023/CM2023-30PercentOff-CategoryBanner-Desktop.webp',
              mainBannerImage: '/image/home/theme/sale/desktop/CyberMonday2023/CM2023-MainImage-Desktop.webp',
              categoryPageHeaderImage: '/image/home/theme/sale/desktop/CyberMonday2023/CM2023-CategoryBanner-Desktop.webp',
              productImageSaleOverlay: '/image/home/theme/sale/desktop/CyberMonday2023/CM2023-Product-Overlay-Desktop.png',
              productImageSaleOverlay30: '/image/home/theme/sale/desktop/CyberMonday2023/CM2023-DailyDeal-Overlay-Desktop.png',
              productImagePreOrderOverlay: ''
            },
            mobile: {
              showHeaderTextOverlay: true,
              HeaderTextOverlayText: 'CM2023',
              categoryPageHeaderImage30: '/image/home/theme/sale/mobile/CyberMonday2023/CM2023-30PercentOff-CategoryBanner-Mobile.webp',
              mainBannerImage: '/image/home/theme/sale/mobile/CyberMonday2023/CM2023-MainImage-Mobile.webp',
              categoryPageHeaderImage: '/image/home/theme/sale/mobile/CyberMonday2023/CM2023-CategoryBanner-Mobile.webp',
              productImageSaleOverlay: '/image/home/theme/sale/mobile/CyberMonday2023/CM2023-Product-Overlay-Mobile.png',
              productImageSaleOverlay30: '/image/home/theme/sale/mobile/CyberMonday2023/CM2023-DailyDeal-Overlay-Mobile.png',
              productImagePreOrderOverlay: ''
            }
          }
        },
        specialOffers: []
      },
      'FallSale2024': {
        saleIsActive: true,
        saleExpiresAt: '2024-12-31T12:00:00.000Z',
        discountPercentage: 15,
        salePercentageDailyDeal: 15,
        layoutSettings: {
          showGeoTransitBanner: true,
          showSaleBanner: true,
          showCountdownTimer: true,
          showEmailSubscriberPopup: true,
          showPushNotificationPopup: true,
          // saleBanner: CyberMondaySaleHeaderBanner,
          layout: {
            bannerLinks: {
              homepageBanner: '/collections/rp-car-audio-amplifiers'
            },
            desktop: {
              // categoryPageHeaderImage30: '/image/home/theme/sale/desktop/',
              mainBannerImage: '/image/home/theme/sale/desktop/FallSale2024/FallSale2024-MainImage-Desktop.webp',
              categoryPageHeaderImage: '/image/home/theme/sale/desktop/FallSale2024/FallSale2024-CategoryBanner-Desktop.webp',
              productImageSaleOverlay: '/image/home/theme/sale/desktop/FallSale2024/FallSale2024-ProductOverlay.webp',
              // productImageSaleOverlay30: '/image/home/theme/sale/desktop/',
              productImagePreOrderOverlay: ''
            },
            mobile: {
              showHeaderTextOverlay: false,
              // HeaderTextOverlayText: 'NYS2024',
              // categoryPageHeaderImage30: '/image/home/theme/sale/mobile/',
              mainBannerImage: '/image/home/theme/sale/mobile/FallSale2024/FallSale2024-MainImage-Mobile.webp',
              categoryPageHeaderImage: '/image/home/theme/sale/mobile/FallSale2024/FallSale2024-CategoryBanner-Mobile.webp',
              productImageSaleOverlay: '/image/home/theme/sale/mobile/FallSale2024/FallSale2024-ProductOverlay.webp',
              // productImageSaleOverlay30: '/image/home/theme/sale/mobile/',
              productImagePreOrderOverlay: ''
            }
          }
        },
        specialOffers: []
      }
    }
  },
  getSaleSettings: function(saleName) {
    if (saleName) {
      const saleAttributes = this.saleSettings?.attributes?.[saleName];
      return saleAttributes ? {name: saleName, ...saleAttributes} : {};
    } else {
      for (let key in this.saleSettings.attributes) {
        if (this.saleSettings.attributes[key].saleIsActive) {
          return {name: key, ...this.saleSettings.attributes[key]};
        }
      }
      return {};
    }
  }
};

const getActiveSaleName = () => {
  const activeSale = siteSettingsV2.getSaleSettings();
  return activeSale.name || 'DefaultSaleName'; // Replace 'DefaultSaleName' with a default value if needed
};

const updateDefaultThemeImagePaths = () => {
  const activeSale = siteSettingsV2.getSaleSettings();
  const layout = activeSale.layoutSettings?.layout;

  if (layout) {
    return {
      desktopHomePageMainBannerImage: layout.desktop?.mainBannerImage,
      mobileHomePageMainBannerImage: layout.mobile?.mainBannerImage,
      desktopHomePageAmplifierImage: layout.desktop?.amplifierImage,
      desktopCollectionPageHeaderImage: layout.desktop?.categoryPageHeaderImage,
      mobileCollectionPageHeaderImage: layout.mobile?.categoryPageHeaderImage,
      desktopProductImageSaleOverlay: layout.desktop?.productImageSaleOverlay,
      mobileProductImageSaleOverlay: layout.mobile?.productImageSaleOverlay,
      desktopProductImagePreOrderOverlay: layout.desktop?.productImagePreOrderOverlay || '',
      mobileProductImagePreOrderOverlay: layout.mobile?.productImagePreOrderOverlay || '',
      desktopCollectionPageHeaderImageDailyDeal: layout.desktop?.desktopCollectionPageHeaderImageDailyDeal || layout.desktop?.categoryPageHeaderImage,
      mobileCollectionPageHeaderImageDailyDeal: layout.mobile?.mobileCollectionPageHeaderImageDailyDeal || layout.mobile?.categoryPageHeaderImage,
      desktopProductImageSaleOverlayDailyDeal: layout.desktop?.productImageSaleOverlay30 || layout.desktop?.productImageSaleOverlay,
      mobileProductImageSaleOverlayDailyDeal: layout.mobile?.productImageSaleOverlay30 || layout.mobile?.productImageSaleOverlay
    };
  } else {
    return {};
  }
};

export const homePageThemeFolderName = getActiveSaleName();
export const defaultThemeImagePaths = updateDefaultThemeImagePaths();


export const getActiveSaleDetails = () => {
  // Assuming you want to find the first active sale
  const activeSale = siteSettingsV2.getSaleSettings();
  if (Object?.keys(activeSale)?.length > 0) {
    return {
      saleEnabled: true,
      salePercentage: activeSale?.discountPercentage,
      salePercentageDailyDeal: activeSale?.salePercentageDailyDeal
    };
  } else {
    return {
      saleEnabled: false,
      salePercentage: 0,
      salePercentageDailyDeal: 0
    };
  }
};

export const dealerConfigOptions = {
  freeShippingThreshold: 1500,
  staticShippingCostUnderThreshold: 37.12,
  fallbackDealerDiscount: 0.2,
  allowCheckoutBelowThreshold: true
};

export const priceHelper = {
  saleEnabled: getActiveSaleDetails().saleEnabled,
  salePercentage: getActiveSaleDetails().salePercentage,
  salePercentageDailyDeal: getActiveSaleDetails().salePercentageDailyDeal,

  saleEnabledFor(product) {
    if (!this.saleEnabled) {
      return false;
    }
    if (this.saleEnabled) {
      return true;
    }
    if (!product) {
      return false;
    }
    const isInStock = (product?.tpa_wh + product?.lv_wh > 3) ?? product?.in_stock === true ?? product?.isInStock === true;
    const isEligibleForPreorder = product?.preorderInfo?.is_pre_order_eligible === 1 ??
      item?.pre_order_info?.is_pre_order_eligible === 1 ?? item?.preOrderInfo?.is_pre_order_eligible ??
      item?.variant?.preorderInfo?.is_pre_order_eligible === 1 ??
      item?.variant?.is_pre_order_eligible ?? false;
    const isSoldOut = !isInStock;
    return isInStock || isSoldOut || isEligibleForPreorder;
  },

  getFinalPrice(product) {
    const mapPrice = product?.price_map ?? product?.priceMap;
    const isDailyDeal = product?.sku?.startsWith('BF-') ?? product?.sku?.startsWith('CM-') ?? product?.isDailyDeal ?? false;
    if (this.saleEnabledFor(product)) {
      return this.calculateDiscountedAmount(mapPrice, isDailyDeal);
    } else {
      return this.roundMoneyFair(mapPrice);
    }
  },

  calculateFinalPriceIfOnSaleOrMAP(mapPrice, isDailyDeal = false) {
    if (this.saleEnabled) {
      return this.calculateDiscountedAmount(mapPrice, isDailyDeal);
    } else {
      return this.roundMoneyFair(mapPrice);
    }
  },

  calculateDiscountedAmount(mapPrice, isDailyDeal = false) {
    // Check if the product is a daily deal and use the daily deal percentage
    const percent = isDailyDeal && this.salePercentageDailyDeal != null
      ? this?.salePercentageDailyDeal / 100
      : this?.salePercentage / 100;
    const discountedAmount = mapPrice - (mapPrice * percent);
    return this.roundMoneyFair(discountedAmount);
  },

  roundMoneyFair(amount) {
    if (typeof amount === 'string') {
      amount = Number(amount);
    }
    amount = Math.round(amount * 100) / 100;
    return amount;
  },

  getOriginalSubtotal(line_items) {
    const subtotal_without_discounts = line_items
      ? line_items.reduce((acc, item) => {
        const isSale = this.saleEnabledFor(item?.variant);
        const itemVariantPriceIfSaleMap = item.price ?? item?.variant?.price;
        let price = isSale ? itemVariantPriceIfSaleMap : item?.variant?.price_map;
        const total = acc + (item?.quantity * price);
        return total;
      }, 0)
      : 0;

    return this.roundMoneyFair(subtotal_without_discounts);
  },

  getDiscountRate(isDailyDeal) {
    if (isDailyDeal && this.salePercentageDailyDeal != null) {
      return (100 - this.salePercentageDailyDeal) / 100;
    }
    return this.saleEnabled ? (100 - this.salePercentage) / 100 : 1;
  },

  discountAmount(product) {
    const mapPrice = product?.price_map;
    const msrpPrice = product?.price_msrp;
    const isDailyDeal = product?.sku?.startsWith('BF-') ?? product?.sku?.startsWith('CM-') ?? product?.isDailyDeal ?? false;
    if (this.saleEnabledFor(product)) {
      return (1 - this.getDiscountRate(isDailyDeal)) * mapPrice;
    } else {
      return msrpPrice - mapPrice;
    }
  },

  getDiscountedSavings(discounted_subtotal) {
    const inverted_discount = 100 - this.salePercentage;
    const inverted_percentage = inverted_discount / 100;
    const original_amount = discounted_subtotal / inverted_percentage;
    const discounted_savings = discounted_subtotal - original_amount;
    return discounted_savings;
  },
  placeholder: {
    desktop: {product: '/product-img-placeholder.svg'},
    mobile: {product: '/product-img-placeholder.svg'}
  },
  performance: {useServiceWorkerCache: false},
  logging: {seo: true, server: false, query: true, magic: true},
  cache: {enabled: false, redis: {port: 6380}}
};


export const finalizeProductPrices = (products) => {
  return products?.map(product => {
    return finalizePricingForProductAndVariants(product);
  });
};

export const finalizePricingForProductAndVariants = (product, options = {}) => {
  const returnPriceOnly = options?.returnPriceOnly ?? false;
  if (returnPriceOnly) {
    return priceHelper?.saleEnabledFor(product)
      ? priceHelper?.getFinalPrice(product)
      : product?.price_map ?? product?.priceMap;
  }
  let output = {
    ...product,
    price: priceHelper?.saleEnabledFor(product)
      ? priceHelper?.getFinalPrice(product)
      : product?.price_map ?? product?.priceMap
  };

  if (product?.variants?.length) {
    output.variants = product.variants.map((variant) => {
      const priceMap = variant?.price_map;
      const price = priceHelper?.saleEnabledFor(product) ? priceHelper?.getFinalPrice(variant) : priceMap;
      return {
        ...variant,
        price
      };
    });
  }
  return output;
};

export const finalizeCurrentPriceForSku = (sku, mapPrice) => {
  const saleIsEnabled = priceHelper?.saleEnabled === true;
  const isDailyDeal = false; // think this will never changed, we only call it from freq. bought together products
  const currentPrice = saleIsEnabled ? priceHelper?.calculateFinalPriceIfOnSaleOrMAP(mapPrice, isDailyDeal) : mapPrice;
  return currentPrice;
};

export const calculateFallbackDealerPrice = (priceMap) => {
  let dealerPrice = roundMoneyFair(priceMap * (1 - dealerConfigOptions?.fallbackDealerDiscount));
  dealerPrice = Math.floor(dealerPrice);
  return dealerPrice;
};

export const returnHighestAndLowestPriceValues = (product, props = {}) => {
  if (!product?.sku) {
    return [];
  }

  const isDealer = props?.isDealer;

  let justPrice = product?.price;
  let priceMap = product?.price_map ?? product?.priceMap;
  let priceMsrp = product?.price_msrp ?? product?.priceMsrp;
  let dealerPrice = product?.price_dealer ?? product?.priceDealer;

  dealerPrice = dealerPrice ?? calculateFallbackDealerPrice(priceMap);

  if (isDealer) {
    return [priceMap, dealerPrice];
  }

  let isSaleEnabled = priceHelper.saleEnabledFor(product) ?? false;
  let salePriceValue = priceHelper.getFinalPrice(product);
  let salePrice, normalPrice;

  if (isSaleEnabled) {
    salePrice = salePriceValue;
  }

  if (isSaleEnabled) {
    return [priceHelper.roundMoneyFair(priceMap ?? 0), priceHelper.roundMoneyFair(salePrice)];
  }
  return [priceHelper.roundMoneyFair(priceMsrp), priceHelper.roundMoneyFair(justPrice)];
};


export const lambdaBackEndURL_EAST_1_VIRGINIA = 'https://kn4sskbatk.execute-api.us-east-1.amazonaws.com';
export const lambdaBackEndURL_WEST_2_OREGON = 'https://q9e1idvlq3.execute-api.us-west-2.amazonaws.com';
export const liveAmazonBackendAPI = lambdaBackEndURL_EAST_1_VIRGINIA;

