export const niceMoney = (amount) => {
  amount = Number(amount);
  if (amount === -0) {
    amount = 0;
  }
  let sMoney = Number(amount).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  sMoney = sMoney?.replace('US', '')
  return sMoney;
}

export const roundMoney = (val) => {
  if (typeof val === 'string') {
    val = Number(val)
  }
  return Math.floor(val * 100) / 100
}

export const roundMoneyFair = (val) => {
  if (typeof val === 'string') {
    val = Number(val)
  }
  return Math.round(val * 100) / 100
}

export const numberOrZero = (numeric, defaultValue = 0) => {
  numeric = Number(numeric)
  return isNaN(numeric) ? defaultValue : numeric
}

export const parseNumber = (str) => {
  str = str.match(/(\d+)/)?.[0]
  return Number(str)
}

export const calculateRoundedUpAmount = (orderTotal) => {
  const roundedUpAmount = Math.ceil(orderTotal);
  return roundMoneyFair(roundedUpAmount - orderTotal);
}

export const genereateRoundedTimestamp = () => {
  const roundTimeStampValue = 15;
  const now = new Date();
  const roundedSeconds = Math.floor(now.getSeconds() / roundTimeStampValue) * roundTimeStampValue;
  now.setSeconds(roundedSeconds);
  now.setMilliseconds(0);
  return now.getTime();
};

export const convertUnixToEST = (unixTimestamp) => {
  const date = new Date(parseInt(unixTimestamp));
  const options = {timeZone: 'America/New_York', hour12: false};
  return date.toLocaleString('en-US', options);
};

export const formatNumberForDisplay = (number) => {
  return number.toLocaleString('en-US');
}