import {AiOutlineMinus, AiOutlinePlus} from '@assets/react-icons/ai';

export function SingleProductFocusProductQuantity({quantity, setQuantity}) {
  const allowedNumbers = Array(1000)
    ?.fill()
    ?.map((v, i) => i + 1);
  const setQtyProtected = (qty) => {
    if (allowedNumbers?.includes(qty)) {
      setQuantity(qty);
    }
  };

  const handleChange = (event) => {
    const value = Number(event.target.value || '');
    // console.log({handleQtyChange: value});
    setQtyProtected(value);
  };

  const handleArrowChange = (event) => {
    const value = Number(event.target.value || '');
    if (event.key === 'ArrowUp') {
      setQtyProtected(value + 1);
    } else if (event.key === 'ArrowDown') {
      setQtyProtected(value - 1);
    }
  };

  return (
    <>
      <div className='flex items-center border border-neutral-400 rounded'>
        <button
          aria-label='Decrease quantity by 1'
          className='flex items-center justify-center w-10 h-10 leading-10 text-black transition hover:opacity-75'
          onClick={(e) => setQtyProtected(quantity - 1)}>
          <AiOutlineMinus size={16} />
        </button>
        <label htmlFor='product-qty' className='visually-hidden'>
          Quantity
        </label>

        <input
          type='text'
          id='product-qty'
          name='product-qty'
          aria-label='Quantity'
          pattern='[0-9]*'
          onInput={(e) => handleChange(e)}
          onChange={(e) => handleChange(e)}
          value={quantity}
          onKeyDown={(e) => handleArrowChange(e)}
          className='h-10 w-16 border-transparent text-black text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none dark:bg-white dark:text-black no-darkmode'
        />

        <button
          aria-label='Increase quantity by 1'
          className='flex items-center justify-center w-10 h-10 leading-10 text-black transition hover:opacity-75'
          onClick={(e) => setQtyProtected(quantity + 1)}>
          <AiOutlinePlus size={16} />
        </button>
      </div>
    </>
  );
}
