import s from './Button.module.css';
import cn from 'clsx';
import {forwardRef, useCallback, useRef} from 'react';
import {LoadingDots} from '@components/widgets/LoadingDots/LoadingDots';

const mergeRefs = (refs) => (value) => {
  refs.forEach(ref => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(value);
      } else {
        ref.current = value;
      }
    }
  });
};

export const Button = forwardRef((props, buttonRef) => {
  const {
    className,
    variant = 'flat',
    children,
    active,
    width,
    loading = false,
    disabled = false,
    style = {},
    Component = 'button',
    ...rest
  } = props;

  const ref = useRef(null);

  const rootClassName = cn(
    s.root,
    {
      [s.ghost]: variant === 'ghost',
      [s.slim]: variant === 'slim',
      [s.naked]: variant === 'naked',
      [s.loading]: loading,
      [s.disabled]: disabled
    },
    className
  );

  const combinedRef = useCallback(mergeRefs([ref, buttonRef]), [ref, buttonRef]);

  return (
    <Component
      aria-pressed={active}
      data-variant={variant}
      ref={combinedRef}
      className={rootClassName}
      disabled={disabled}
      style={{
        width,
        ...style
      }}
      {...rest}
    >
      {children}
      {loading && (
        <i className='pl-2 m-0 flex'>
          <LoadingDots />
        </i>
      )}
    </Component>
  );
});

Button.displayName = 'Button';
