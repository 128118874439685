import {priceHelper} from '@helpers/config-master.mjs';
import {consoleLogGA4} from '@components/datalayer/loggers';
import {DeterminePageType} from '@components/datalayer/helpers/determine-page-type';

export const sendHitForGoogleAnalyticsEvents = (hitEvent, session) => {
  const path = hitEvent?.path;
  // console.log("[sendHitForGoogleAnalyticsEvents][hitEvent]: ", hitEvent);
  const excludedPaths = [/*'products'*/ 'cart', 'checkout', 'order'];

  // Return early if path is not supposed to be tracked
  if (path?.includes('/collections/vehicle-specific-subwoofer-enclosures')) {
    if (path?.includes('year')) {
      return;
    }
  }

  // Send the page view hit regardless of whether session is available
  if (excludedPaths.some((exclude) => path.includes(exclude)) === false) {
    const pageTitle = document?.title;
    const eventPayload = {
      page_path: path,
      page_title: pageTitle,
      page_location: `https://www.skaradio.com${path}`,
      page_type: DeterminePageType()
    };

    if (typeof window !== 'undefined') {
      if (window.gtag) {
        window.gtag('event', 'page_view', eventPayload);
        if (session) {
          window.gtag('event', 'conversion', {
            allow_enhanced_conversions: true,
            send_to: 'AW-976071580/PsQiCO-st8IDEJzXttED'
          });
        }
      }
    }
  }
};

export function ViewItem_GA4(product) {
  // console.log({ViewItem_GA4: product});
  const sku = product?.sku;
  const finalProductPrice = Number(priceHelper.getFinalPrice(product).toFixed(2));
  const eventPayload = {
    currency: 'USD',
    value: Number(finalProductPrice),
    sku: sku,
    items: [
      {
        item_id: sku,
        item_name: sku,
        affiliation: 'Skar Audio',
        discount: Number(priceHelper.discountAmount(product).toFixed(2)),
        index: 0,
        item_brand: 'Skar Audio',
        item_variant: sku,
        price: Number(finalProductPrice),
        quantity: 1
      }
    ]
  };
  // console.log('[GA4 View Item][eventPayload]:', {eventPayload});
  if (typeof window !== 'undefined') {
    window.gtag('event', 'view_item', eventPayload);
    consoleLogGA4('view_item ✅');
    window.dataLayer.push(eventPayload);
  }
}

export const AddToCart_GA4 = (addToCartPayload) => {
  // console.log("[GA4 Add to Cart][addToCartPayload]:", {addToCartPayload})
  const item = addToCartPayload?.item;
  const sku = item?.sku;
  const finalProductPrice = Number(item.price) ?? Number(item?.price_map);
  const discountAmount = Number(priceHelper.discountAmount(item).toFixed(2));

  const quantity = 1;

  let eventPayload = {
    currency: 'USD',
    value: finalProductPrice,
    sku: sku,
    items: [
      {
        item_id: sku,
        item_name: sku,
        affiliation: 'Skar Audio',
        item_brand: 'Skar Audio',
        currency: 'USD',
        discount: discountAmount,
        item_variant: sku,
        price: Number(finalProductPrice),
        quantity: Number(quantity)
      }
    ]
  };
  // console.log("[GA4 Add to Cart][eventPayload]:", {eventPayload})
  if (typeof window !== 'undefined') {
    window.gtag('event', 'add_to_cart', eventPayload);
    consoleLogGA4('add_to_cart ✅');
    window.dataLayer.push(eventPayload);
  }
};

export const AddToCartMultiple_GA4 = (addToCartPayload) => {
  const items = addToCartPayload.items.map((item) => {
    const finalProductPrice = Number(item.price) ?? Number(item?.price_map);
    const discountAmount = Number(priceHelper.discountAmount(item).toFixed(2));
    const quantity = Number(item.quantity);

    return {
      item_id: item.sku,
      item_name: item.sku,
      affiliation: 'Skar Audio',
      item_brand: 'Skar Audio',
      currency: 'USD',
      discount: discountAmount * quantity,
      item_variant: item.sku,
      price: finalProductPrice,
      quantity: quantity
    };
  });

  const eventPayload = {
    currency: 'USD',
    value: addToCartPayload.totalPrice,
    sku: items.map((item) => item.item_id).join(', '),
    items: items
  };

  // console.log('[GA4 Add to Cart Multiple][eventPayload]:', {eventPayload});
  if (typeof window !== 'undefined') {
    window.gtag('event', 'add_to_cart', eventPayload);
    consoleLogGA4('add_to_cart ✅');
    window.dataLayer.push(eventPayload);
  }
};

export function viewCollectionPageEvent(slug, title, products) {
  // console.log({viewCollectionPageEvent: slug, title, products})
  // const allVariants = products.flatMap((x) => x?.variants).flat(99)
  let eventPayload = {
    event: 'view_item_list',
    item_list_id: slug,
    item_list_name: title,
    items: products.map((product, index) => {
      const price = Number(product?.price?.toFixed(2));
      return {
        item_id: product?.sku,
        item_name: product?.sku,
        affiliation: 'Skar Audio',
        currency: 'USD',
        discount: Number(priceHelper.discountAmount(product).toFixed(2)),
        item_brand: "Skar Audio",
        index: Number(index),
        item_list_id: slug,
        item_list_name: title,
        item_variant: product?.sku,
        price: price,
        quantity: 1
      };
    })
  };
  // console.log("[GA4 viewCollectionPageEvent][eventPayload]:", {eventPayload})
  if (typeof window !== 'undefined') {
    window.dataLayer.push(eventPayload);
  }
}

export function viewCartEvent(data, lineItems) {
  // console.log('GA4 viewCartEvent => ', data, lineItems) // @TODO - THIS FUNCTION DOES NOT WORK NEEDS FIXING
  const totalPrice = data?.totalPrice;

  let eventPayload = {
    event: 'view_cart',
    currency: 'USD',
    value: Number(totalPrice),
    items: lineItems?.map((x, index) => {
      let priceValue = x?.variant?.price;
      let realPrice = priceHelper.calculateFinalPriceIfOnSaleOrMAP(priceValue);
      return {
        item_id: x?.variant?.sku,
        item_name: x?.variant?.sku,
        affiliation: 'Skar Audio',
        item_brand: 'Skar Audio',
        currency: 'USD',
        discount: Number(priceHelper.discountAmount(x).toFixed(2)),
        index: Number(index),
        item_variant: x?.variant?.sku,
        price: Number(realPrice.toFixed(2)),
        quantity: Number(x?.quantity)
      };
    })
  };
  if (typeof window !== 'undefined') {
    window.dataLayer.push(eventPayload);
  }
}

export function beginCheckoutEvent(e, cart) {
  let eventPayload = {
    event: 'begin_checkout',
    currency: 'USD',
    value: Number(cart?.totalPrice),
    items: cart?.lineItems?.map((x, index) => {
      let realPrice = priceHelper.getFinalPrice(x?.variant?.price);
      return {
        item_id: x?.variant?.sku,
        item_name: x?.variant?.sku,
        affiliation: 'Skar Audio',
        item_brand: 'Skar Audio',
        currency: 'USD',
        discount: Number(priceHelper.discountAmount(x).toFixed(2)),
        index: Number(index),
        item_variant: x?.variant?.sku,
        price: Number(realPrice.toFixed(2)),
        quantity: Number(x?.quantity)
        // item_category: 'Apparel',
        // item_category2: 'Adult',
        // item_category3: 'Shirts',
      };
    })
  };
  if (typeof window !== 'undefined') {
    window.dataLayer.push(eventPayload);
  }
}

export function pushCustomerGA4(customer) {
  let customerData = {
    event: 'visitor_type',
    visitorType: customer?.visitorType,
    visitorId: customer?.id,
    userId: customer?.id,
    // shopifyCustomerId: customer?.id,
    CustomerId: customer?.email,
    CustomerEmail: customer?.email,
    CustomerPhone: customer?.phone,
    CustomerFirstName: customer?.firstName,
    CustomerLastName: customer?.lastName,
    CustomerCity: customer?.defaultAddress?.city,
    CustomerState: customer?.defaultAddress?.provinceCode,
    CustomerZip: customer?.defaultAddress?.zip,
    CustomerCountryCode: customer?.defaultAddress?.countryCodeV2,
    CustomerOrdersCount: customer?.numberOfOrders,
    CustomerTotalSpent: customer?.amountSpent
  };
  window && window.dataLayer.push(customerData);
}

export const PurchaseComplete_GA4 = async (orderInfo) => {
  const order = orderInfo?.order;

  const eventPayload = {
    transaction_id: order.order_number,
    value: Number(order.final_total),
    tax: Number(order.tax_total),
    shipping: 0,
    currency: 'USD',
    items: orderInfo?.lineItems.map((item, index) => {
      return {
        item_id: item.sku,
        item_name: item.sku,
        affiliation: 'Skar Audio',
        index: Number(index + 1),
        item_brand: 'Skar Audio',
        price: Number(item.line_unit_net_price),
        quantity: Number(item.line_unit_qty)
      };
    })
  };
  // window &&
  // window.gtag("event", "purchase", eventPayload)
  // consoleLogGA4('purchase ✅'/*, {eventPayload}*/)
};
