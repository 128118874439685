export const jsonLdWebsite = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'Skar Audio',
  potentialAction: {
    '@type': 'SearchAction',
    target:
      'https://www.skaraudio.com/search/products?allProducts%3AtotalTurn30%3Adesc%5Bquery%5D={search_term_string}',
    'query-input': 'required name=search_term_string'
  },
  url: 'https://www.skaraudio.com'
};

export const jsonLdOrganization = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Skar Audio',
  brand: 'Skar Audio',
  url: 'https://www.skaraudio.com',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '9700 18th St N',
    addressLocality: 'St. Petersburg',
    addressRegion: 'FL',
    addressCountry: 'US',
    postalCode: '33716'
  },
  email: 'mailto:support@skaraudio.com',
  logo: 'https://www.skaraudio.com/image/logos/skar-audio-logo-black-large.png',
  sameAs: [
    'https://www.facebook.com/SkarAudio',
    'https://www.youtube.com/user/skaraudio',
    'https://www.instagram.com/skaraudioofficial'
  ],
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '(888) 501-7527',
    email: 'mailto:support@skaraudio.com',
    contactType: 'Customer Service',
    areaServed: 'US',
    availableLanguage: 'English',
    contactOption: 'TollFree',
    hoursAvailable: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        opens: '09:00',
        closes: '17:30'
      }
    ]
  },
  founder: {
    '@type': 'Person',
    name: 'Kevin Schlenker',
    email: 'mailto:kevin@skaraudio.com'
  }
};

export const GlobalJsonLD = () => {
  const jsonLdArray = [jsonLdWebsite, jsonLdOrganization];

  return (
    <>
      {jsonLdArray.map((json, index) => (
        <script
          key={index}
          type='application/ld+json'
          dangerouslySetInnerHTML={{__html: JSON.stringify(json)}}
        />
      ))}
    </>
  );
};
