import {useEffect, useState} from 'react';

export const useReviewBadge = (reviewBadge) => {
  const [review, setReview] = useState({
    ratingValue: null,
    bestRating: 5,
    total: 0
  });

  useEffect(() => {
    if (reviewBadge?.badge) {
      let tempDiv = document.createElement('div');
      tempDiv.innerHTML = reviewBadge?.badge;
      const data = tempDiv?.firstElementChild?.dataset;

      setReview({
        ratingValue: Number(data?.averageRating),
        bestRating: review?.bestRating,
        total: +data?.numberOfReviews
      });
    }
  }, [reviewBadge?.badge, review?.bestRating]);

  return review;
};

export function ReviewStars({reviewBadge}) {
  const review = useReviewBadge(reviewBadge);

  const reviewCount = review?.total;
  const ratingValueRoundUp = Math.ceil(review?.ratingValue);

  return (
    <div>
      {ratingValueRoundUp ? (
        <div className='m flex items-end'>
          {Array(ratingValueRoundUp)
            .fill(0)
            .map((item, index) => (
              <SvgStar key={index} />
            ))}
          <span className='ml-1 text-xs md:text-sm font-medium whitespace-nowrap'>
            {reviewCount} Reviews
          </span>
        </div>
      ) : null}
    </div>
  );
}

export function SvgStar({size = 18, color = '#FBAE17'}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m17.7 6.9-5-.7-2.1-4.5-.3-.3a.6.6 0 0 0-.9.3L7.2 6.2l-5 .7a.6.6 0 0 0-.3 1l3.6 3.6-.8 4.9a.6.6 0 0 0 .9.7l4.4-2.4 4.4 2.4h.4c.4 0 .6-.4.5-.7l-.8-5L18 8a.6.6 0 0 0-.4-1.1Z'
        fill={color}
      />
    </svg>
  );
}

export function SvgStarGray({size = 18}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m17.7 6.9-5-.7-2.1-4.5-.3-.3a.6.6 0 0 0-.9.3L7.2 6.2l-5 .7a.6.6 0 0 0-.3 1l3.6 3.6-.8 4.9a.6.6 0 0 0 .9.7l4.4-2.4 4.4 2.4h.4c.4 0 .6-.4.5-.7l-.8-5L18 8a.6.6 0 0 0-.4-1.1Z'
        fill='#9ca3af'
      />
    </svg>
  );
}
