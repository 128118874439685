import s from './ProductCard.module.css';
import ComingSoonIcon from '@image/icons/png-icons/ComingSoonIcon.png';
import cn from 'clsx';
import Link from 'next/link';
import Image from 'next/image';
import {priceHelper, returnHighestAndLowestPriceValues} from '@helpers/config-master.mjs';
import {useState} from 'react';
import {DesktopProductTag} from '@components/product/desktop/DesktopProductTag';
import {DesktopSaleProductImageOverlay} from '@components/product/global/ProductImageOverlays';

const placeholderImg = priceHelper.placeholder.desktop.product;

export const ProductCardDesktop = ({product, desktopProductImageSaleOverlay, isDealer}) => {
  // console.log('[ProductCardDesktop] product: ', product);
  let src = product?.imageUrl ?? product?.images?.[0]?.url ?? product?.image_1 ?? placeholderImg;
  src = src?.replaceAll('FORD-2009-UP', 'FORD-2015-UP');

  const [imageSrc, setImageSrc] = useState(src);

  const sku = product?.sku;
  const linkSku = product?.linkSku;
  const isParentSku = !!product?.isParentSku;

  const [highPrice, lowPrice] = returnHighestAndLowestPriceValues(product, {isDealer});

  const saleIsEnabled = priceHelper.saleEnabledFor(product) ?? false;

  const isInStock = product?.isInStock === true || product?.currentlyInStock === true;

  const preOrderInfo = product?.pre_order_info ?? product?.preorderInfo;
  const isPreorderEligible = preOrderInfo?.is_pre_order_eligible === true;
  const isPreOrderEligibleAndActive = !isInStock && isPreorderEligible;

  const prodName = product?.name ?? product?.site_name;
  const productSlug = product?.slug ?? product?.site_handle;
  let href = product?.slug?.includes('/products') ? productSlug : `/products/${productSlug}`;
  href = href ? href.replaceAll('//', '/') : null;

  if (linkSku) {
    href = `${href}?sku=${linkSku}`;
  }

  const reviewRating = product?.rating ?? product?.reviewRating;

  const variant = 'default';
  const rootClassName = cn(s.root, {
    [s.slim]: variant === 'slim',
    [s.simple]: variant === 'simple'
  });

  const imageAltText = `Skar Audio ${sku} Image Preview`;

  return (
    <>
      {imageSrc && (
        <Link href={href} aria-label={`Skar Audio ${sku} Product Page`}>
          <div className={s?.imageContainer}>
            <Image
              alt={imageAltText}
              className={s?.productImage}
              src={imageSrc}
              height={410}
              width={410}
              quality="85"
              loading="eager"
            />

            {imageSrc === placeholderImg && (
              <div className="absolute top-0 p-16">
                {ComingSoonIcon && <Image src={ComingSoonIcon} alt="Showing product coming soon" />}
              </div>
            )}

            <DesktopSaleProductImageOverlay
              saleIsEnabled={saleIsEnabled}
              desktopProductImageSaleOverlay={desktopProductImageSaleOverlay}
            />
          </div>
        </Link>
      )}

      <div className={rootClassName}>
        <DesktopProductTag
          isParentSku={isParentSku}
          product={product}
          name={prodName}
          productLink={href}
          price={lowPrice}
          compareAtPrice={highPrice}
          rating={reviewRating}
          isInStock={isInStock}
          setImageSrc={setImageSrc}
          isPreorder={isPreorderEligible}
        />
      </div>
    </>
  );
};
