import useSWR from 'swr';
import {fetcher} from '@helpers/common/fetch.mjs';

export const useAccountData = (serverAccountData) => {
  const {data, mutate, isLoading, isValidating} = useSWR(`/api/accounts/my-account`, fetcher, {
    fallbackData: serverAccountData,
    revalidateOnFocus: false,
    revalidateOnMount: false,
    keepPreviousData: true
  });

  return {
    data,
    mutate,
    isLoading,
    isValidating
  };
};
